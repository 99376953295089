import { Suspense, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "./App.css";
import { useGlobalContext } from "./context/context";
import scroll_up from "./assets/icons/ic_scroll_to_top .svg";
import Head from "./components/Head/Head";
import { setLoginData } from "./redux/features/user/getLoginDataSlice";
import { useAppDispatch, useAppSelector } from "./app/hooks";

import { RootState } from "./redux/store";
import AppRoutes from "./routes/AppRoutes";
import api from "./utils/api";
import { base_url, local_storage_web_key } from "./utils/api_urls";
import useLocationData from "./maps/GoogleLocation";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { SkeletonTheme } from "react-loading-skeleton";
import IntroScreen from "./components/IntroScreen";
import { loginDataTypes } from "./redux/features/types";
import useTourGuides from "./hooks/useTourGuide";
import NoDataAvailable from "./components/NoData/NoDataAvailable";

// map api key
export const MAP_API = "AIzaSyDidGs_FqgpjUv_X8IHM0LoOuYT45tJyWs";

export const loginData = localStorage.getItem(local_storage_web_key) ?? null;

function App() {
  const { changeNavColor, scrollToTop } = useGlobalContext();
  const [showServerDown, setShowServerDown] = useState(false);
  const dispatch = useAppDispatch();
  // const [isConnected, setIsConnected] = useState(socket.connected);
  const { loading, error: appDataError } = useAppSelector(
    (state: RootState) => state.appData
  );
  const { pathname } = useLocation();
  const { loading: userLocationLoading, error: locationError } =
    useLocationData();
  const { tourLoading, getTourGuidesData } = useTourGuides();
  const navigate = useNavigate();

  // getting local storage auth data
  const tokenData = localStorage.getItem(local_storage_web_key);
  // getting local storage auth data
  const parseLoginData = (JSON.parse(loginData!) as loginDataTypes) || "";
  console.log({ pathname });

  useEffect(() => {
    const regex = /\bregister\b/;
    let register = regex.test(pathname);
    if (pathname === "/login" || register || pathname === "/") {
      if (loginData) {
        return navigate("/explore-nearby");
      }
    }
    if (
      !loginData &&
      pathname !== "/" &&
      pathname !== "/find-home" &&
      pathname !== "/find-professional" &&
      pathname !== "/resources" &&
      pathname !== "/terms" &&
      pathname !== "/privacy" &&
      pathname !== "/contact-us"
    ) {
      return navigate("/login");
    }
  }, []);

  useEffect(() => {
    dispatch(setLoginData(JSON.parse(tokenData!)));
  }, []);

  useEffect(() => {
    const loadUserFromLocalStorage = async () => {
      try {
        await api.get(`${base_url}newsfeed/getstories?pageNo=0`);
      } catch (error) {
        // Handle any errors that occur during the API request
        console.log(error);
      }
    };
    loadUserFromLocalStorage();
  }, []);

  useEffect(() => {
    // const socket = io("https://houseupapi.com:8443", {
    //   path: "ennvision_apis/ennvisions-websocket-without-sockjs",
    //   // transports: ["websocket"],
    // });
    // socket.on("connect", () => {
    //   console.log("Socket connected:", socket.connected);
    // });
    // socket.on("/topic/newsfeed/comments", () => {
    //   console.log('alert("connect to socket")');
    // });
    // socket.emit(
    //   "/topic/newsfeed/comments",
    //   console.log("emit comments comments")
    // );
    // socket.on("userConnected", (data) => console.log(data, "user connected"));
    // socket.on("userDisconnected", (data) => setUsers(data));
    // socket.on("addUser", (data) => setUsers(data));
    // socket.on('newMessageRecived', (data) => setMessages([]))
  }, []);

  console.log({ parseLoginData });
  useEffect(() => {
    // if (parseLoginData) {
    //   getTourGuidesData(parseLoginData);
    // }
  }, []);

  // scroll to top when we navigate to new route
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    ReactGA.send({
      hitType: "pageview",
      page: pathname,
      title: "Custom Title",
    });
  }, [pathname]);

  // retry request when server is down
  let retryRequest: (() => void) | null = null;

  const handleRetryRequest = () => {
    setShowServerDown(false);
    if (retryRequest) {
      retryRequest();
    }
  };
  console.log({ showServerDown, loading, userLocationLoading, locationError, appDataError });

  return (
    <SkeletonTheme
      baseColor="#F3F3F3"
      highlightColor="#F3F3F3"
    // borderRadius="0.5rem"
    >
      <ToastContainer />
      <Head title="HouseUp-home" />
      {loading  || userLocationLoading|| tourLoading  ? (
        <IntroScreen />
      ) : appDataError ? <NoDataAvailable title={appDataError} /> : (
        <Suspense fallback={<h6>Loading...</h6>}>
          <span
            className={`arrow-box ${changeNavColor ? "d-block" : "d-none"}`}
          >
            <img onClick={scrollToTop} src={scroll_up} alt="scroll-up" />
          </span>
          <AppRoutes />
          {/* {locationError ? (
           <p>Location Not Found</p>
         ) : Object.keys(data).length !== 0 && !data.countryName.results ? (
           <NoDataAvailable title={data.countryName.responseMessage} />
         ) : (
          
         )} */}
        </Suspense>
      )}
    </SkeletonTheme>
    // <>
    //   <ToastContainer />
    //   <Head title="Nestify-home" />
    //   <Skeleton
    //     loading={loading || userLocationLoading}
    //     active
    //     avatar
    //     className="p-3"
    //   >
    //     <Suspense fallback={<h6>Loading...</h6>}>
    //       {showServerDown ? (
    //         // Show server down component when the server is down
    //         <ServerDownComponent retryRequest={handleRetryRequest} />
    //       ) : locationError ? (
    //         <p>Location Not Found</p>
    //       ) : Object.keys(data).length !== 0 && !data.countryName.results ? (
    //         <NoDataFound title={data.countryName.responseMessage} />
    //       ) : (
    //         <>
    //           <span
    //             className={`arrow-box ${changeNavColor ? "d-block" : "d-none"}`}
    //           >
    //             <img onClick={scrollToTop} src={scroll_up} alt="scroll-up" />
    //           </span>
    //           <AppRoutes />
    //         </>
    //       )}
    //     </Suspense>
    //   </Skeleton>
    // </>
  );
}

export default App;
