import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { authorizationToken, user_properties_url } from "../../utils/api_urls";
import Loader from "../../components/Loader/Loader";
import NoDataAvailable from "../../components/NoData/NoDataAvailable";
import { useAppSelector } from "../../app/hooks";
import { UserPropertiesStyle } from "./style";
import { Col, Container, Row } from "react-bootstrap";
import HeadingViewMore from "../../components/HeadingViewMore/HeadingViewMore";
import Property from "../../components/Property/Property";
import {
  TertiraryHeadingMini,
  primaryColor,
  whiteColor,
} from "../../components/GlobalStyle";
import CustomButton from "../../components/CustomButton/CustomButton";

const UserProperties = () => {
  const [loading, setloading] = useState(true);
  const [userPropertiesData, setUserPropertiesData] = useState(
    {} as userPropertiesTypes
  );
  const [error, setError] = useState("");
  const loginData = useAppSelector((state) => state.loginData.data);
  const [page, setPage] = useState(0);

  // user properties promise
  const handleUserPropertiesPromise = async () => {
    try {
      setloading(true);
      const {
        data: { results },
      } = await axios.post(
        user_properties_url + `?pageNo=${page}`,
        {
          userId: loginData?.userDetails.id,
          statusId: null,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      toast("got properties successfully", {
        type: "success",
        autoClose: 1000,
      });
      if (page > 0) {
        setUserPropertiesData({
          ...userPropertiesData,
          propertyList: [
            ...userPropertiesData.propertyList,
            ...results.propertyList,
          ],
        });
      } else {
        setUserPropertiesData(results);
      }
      setloading(false);
    } catch (error: any) {
      console.log({ error });
      setloading(false);
      toast(error.response.data.responseMessage, {
        type: "error",
        autoClose: 1000,
      });
      setError(error.response.data.responseMessage);
    }
  };
  useEffect(() => {
    handleUserPropertiesPromise();
  }, [page]);

  if (loading && page < 1) return <Loader />;
  if (error) return <NoDataAvailable title={error} />;

  return (
    <UserPropertiesStyle>
      <div className="py-3">
        <Container>
          <HeadingViewMore bottomLines={false} title="Property List" />
          {userPropertiesData.propertyList.length !== 0 ? (
            <>
              <Row className="mt-3">
                {userPropertiesData.propertyList.map((propertyData: any) => (
                  <Col
                    key={propertyData.propertyId}
                    md={4}
                    lg={3}
                    className="mt-3"
                  >
                    <Property {...propertyData} />
                  </Col>
                ))}
              </Row>
              {page + 1 !== userPropertiesData?.totalPages && (
                <div className="d-flex justify-content-center my-3">
                  <CustomButton
                    bgcolor={primaryColor}
                    color={whiteColor}
                    padding="6px"
                    width="150px"
                    type="button"
                    loading={loading}
                    title="Load More"
                    margin="auto"
                    clicked={() => setPage(page + 1)}
                  />
                </div>
              )}
            </>
          ) : (
            <TertiraryHeadingMini className="text-center my-2">
              No Properties Available
            </TertiraryHeadingMini>
          )}
        </Container>
      </div>
    </UserPropertiesStyle>
  );
};

export default UserProperties;

// user properties types

export interface userPropertiesTypes {
  totalItems: number;
  propertyList: PropertyList[];
  totalPages: number;
  currentPage: number;
}

export interface PropertyList {
  propertyId: number;
  newsFeedId?: number;
  propertyTypeId: number;
  purpose: string;
  propertySubTypeId: number;
  title: string;
  location: string;
  propertySize: PropertySize;
  price: string;
  fullBedrooms: string;
  fullBathrooms: string;
  propertyStatusId: number;
  imageURLS: string[];
  videoULRS: string;
  longitude: number;
  latitude: number;
  contactRequestPermission: boolean;
  boostPermission: boolean;
  hideLikesAndViewsCounts: boolean;
  turnOffCommenting: boolean;
  readComments: boolean;
  sharingEnabled: boolean;
  saveFavourite: boolean;
  isFavourite: boolean;
  lastLikeAndComment: LastLikeAndComment;
  ownerData: OwnerData;
  createdDateTime: string;
  updatedDateTime: string;
  isEdited: boolean;
}

export interface PropertySize {
  keyName: string;
  size: string;
}

export interface LastLikeAndComment {
  isLiked: boolean;
  totalLikes: number;
  // lastLikedUserId?: number;
  // lastLikedUserFirstName?: string;
  // lastLikedUserLastName?: string;
  totalComments: number;
  lastCommentUserId: any;
  lastCommentUserFirstName: any;
  lastCommentUserLastName: any;
  lastCommentText: any;
  lastCommentUserProfilePicture: any;
}

export interface OwnerData {
  firstName: string;
  lastName: string;
  userName: string;
  profilePicture: string;
  emailAddress: string;
  phoneNumber: string;
  countryCode: string;
  isFollower: boolean;
  isFollowing: boolean;
  id: number;
}

// user posts types

export interface UserPostsTypes {
  totalItems: number;
  postList: PostList[];
  totalPages: number;
  currentPage: number;
}

export interface PostList {
  postId: number;
  newsFeedId: number;
  description: string;
  postLayout: number;
  imagesURLS: string[];
  videosURLS: string;
  longitude: number;
  latitude: number;
  address: string;
  city: string;
  state: string;
  country: string;
  contactRequestPermission: boolean;
  boostPermission: boolean;
  hideLikesAndViewsCounts: boolean;
  turnOffCommenting: boolean;
  readComments: boolean;
  sharingEnabled: boolean;
  saveFavourite: boolean;
  isFavourite: boolean;
  postStatusId: number;
  ownerData: OwnerData;
  lastLikeAndComment: LastLikeAndComment;
  createdDateTime: string;
  updatedDateTime: string;
  isEdited: boolean;
}

export interface OwnerData {
  firstName: string;
  lastName: string;
  userName: string;
  profilePicture: string;
  emailAddress: string;
  phoneNumber: string;
  countryCode: string;
  isFollower: boolean;
  isFollowing: boolean;
  id: number;
}

export interface LastLikeAndComment {
  isLiked: boolean;
  totalLikes: number;
  lastLikedUserId: any;
  lastLikedUserFirstName: any;
  lastLikedUserLastName: any;
  totalComments: number;
  lastCommentUserId: any;
  lastCommentUserFirstName: any;
  lastCommentUserLastName: any;
  lastCommentText: any;
  lastCommentUserProfilePicture: any;
}
