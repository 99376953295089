
 export const adminsData = [
    {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    },
    {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    }, {
      postLayout: 'Classic Layout',
      postType: 'test - picture - video',
      location: 'mississauga, ontario, canada',
      tags: 'yes',
      boost: 'no',
      disappear: '20 mins',
      createdAt: '13-August-2020 12:34 AM'
    },
  
  ]