import { useState } from "react";
import { useNewsfeedContext } from "../../context/NewsfeedContext";
import { primaryRed } from "../GlobalStyle";
import Wrapper from "./style";
import axios from "axios";
import { authorizationToken, hide_post_url } from "../../utils/api_urls";
import { useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomModal from "../Modal/CustomModal";
import EnnvisionModal from "../CustomModals/EnnvisionModal";

const OthersStoryOptions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const loginData = useAppSelector((state) => state.loginData.data);
  const navigate = useNavigate();
  const {
    setShowStoryOptions,
    setIsShowStories,
    selectedStory,
    selectedStories: {
      ownerData: { firstName, lastName, id },
    },
  } = useNewsfeedContext();
console.log("NAdaa",selectedStory.newsFeedId);

  // block user promise
  const blockUserPromise = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        hide_post_url,
        {
          useCase: "USER",
          id,
          contactRequestPermission: true,
          boostPermission: true,
          hideLikesAndViewsCounts: false,
          turnOffCommenting: false,
          readComments: true,
          sharingEnabled: false,
          saveFavourite: false,
          hideToId:loginData?.userDetails?.id,
          newsFeedId:Number(selectedStory.newsFeedId),
          hideType:"STORY"
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setIsLoading(false);
      setIsModalVisible(true);
      setShowStoryOptions(false);
      setIsShowStories(false);

      setTimeout(() => {
        setIsModalVisible(false);
        navigate("/explore-nearby");
      }, 2000);
      toast(data.responseMessage, {
        type: "success",
      });
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
      toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };

  const StoryOptions = [
    {
      title: isLoading
        ? `Muting... ${firstName} ${lastName} Story`
        : `Mute ${firstName} ${lastName} Story`,
      doTask: () => {
        blockUserPromise();
      },
    },
  ];
  return (
    <Wrapper>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Block User"
          description="User Blocked successfully."
          doTask={() => navigate("/explore-nearby")}
        />
      </CustomModal>
      <ul>
        <li
          onClick={() => {
            navigate("/story-report");
            setShowStoryOptions(false);
            setIsShowStories(false);
          }}
          className="title"
          style={{ color: primaryRed, fontWeight: "500" }}
        >
          Report Story
        </li>
        {StoryOptions.map(({ title, doTask }, index) => {
          return (
            <li onClick={doTask} key={index}>
              {title}
            </li>
          );
        })}
      </ul>
    </Wrapper>
  );
};

export default OthersStoryOptions;
