import { Col, Container, Row } from "react-bootstrap";
import PropertyCheckoutStyle from "./style";
import visa from "../../assets/icons/visa_card_icon.svg";
import master from "../../assets/icons/master_card_icon.svg";
import unselectIcon from "../../assets/icons/ic_select_gry.svg";
import selectIcon from "../../assets/icons/ic_select_red.svg";
import plusIcon from "../../assets/icons/ic_add_property_add_card.svg";
import CustomButton from "../../components/CustomButton/CustomButton";
import { primaryColor, whiteColor } from "../../components/GlobalStyle";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
  authorizationToken,
  credit_cards__list_url,
  delete_credit_card_url,
  mark_credit_card_default_url,
  mpac_card_charging_url,
} from "../../utils/api_urls";
import { useAppSelector } from "../../app/hooks";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { DeleteOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import Head from "../../components/Head/Head";
import { RootState } from "../../redux/store";
import { useGlobalContext } from "../../context/context";
import CustomModal from "../../components/Modal/CustomModal";
import EnnvisionModal from "../../components/CustomModals/EnnvisionModal";
import { usePropertyContext } from "../../context/PropertyContext";
import { objectNotEmpty } from "../../utils/utilities";
import { creditCardTypes } from "../PaymentAndWallet/PaymentAndWallet";

const PropertyCheckout = () => {
  const navigate = useNavigate();
  const [cardsData, setCardsData] = useState([] as creditCardTypes[]);
  const [error, setError] = useState(null as any);
  const [loading, setLoading] = useState(false);
  const [chargeOnCardLoading, setChargeOnCardLoading] = useState(false);
  const deleteCardToastId = useRef<any>(null);
  const cardDefaultToastId = useRef<any>(null);
  const {
    createPropertyData,
    mpacCreatePropertyData,
    createPropertyPlan,
    createPropertyReport,
    setMpacAdditionalDetailsData,
  } = usePropertyContext();
  const [isShowModal, setIsShowModal] = useState(false);

  console.log({ createPropertyPlan, createPropertyReport });

  const cardBrands: any = {
    VisaCard: visa,
    Mastercard: master,
  };

  const { data: loginData } = useAppSelector(
    (state: RootState) => state.loginData
  );

  const togglePayment = (index: number) => {
    // const data = [...payments];
    // const unSelectData = data.map((paymentData) => {
    //   return paymentData.select === true
    //     ? { ...paymentData, select: false }
    //     : paymentData;
    // });
    // unSelectData[index].select = !data[index].select;
    // setPayments(unSelectData);
  };

  // handle credit cards list
  const creditCardListHandler = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        credit_cards__list_url,
        {
          userId: loginData?.userDetails.id,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
            "Content-Type": "application/json",
          },
        }
      );
      setCardsData(data.results);
      setLoading(false);
      console.log(data);
    } catch (error: any) {
      const { data } = error.response;
      if (data.responseCode === "400") {
        setCardsData([]);
      }
      setError(data);
      console.log({ error });
      setLoading(false);
    }
  };
  useEffect(() => {
    creditCardListHandler();
  }, []);

  if (loading) {
    return <Loader />;
  }

  // delete credit card
  type deleteCardTypes = {
    cardId: number;
    stripeCardId: string;
    userId: number;
  };
  const deleteCreditCard = async ({
    cardId,
    stripeCardId,
    userId,
  }: deleteCardTypes) => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        delete_credit_card_url,
        {
          cardId,
          stripeCardId,
          userId,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
            "Content-Type": "application/json",
          },
        }
      );
      setCardsData(data.results);
      setLoading(false);
      deleteCardToastId.current = toast("card deleted successfully...", {
        type: "success",
      });
      console.log(data);
      creditCardListHandler();
    } catch (error: any) {
      const { data } = error.response;
      deleteCardToastId.current = toast(error.responseMessage, {
        type: "success",
      });
      setError(data);
      console.log({ error });
      setLoading(false);
    }
  };

  // make card default promise
  const makeCardDefaultPromise = async (cardId: number, isDefault: boolean) => {
    // if card is already default then do nothing
    if (isDefault) return;
    try {
      const { data } = await axios.post(
        mark_credit_card_default_url,
        {
          cardId,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      const defaultCardsData = cardsData.map((cardData) => ({
        ...cardData,
        isDefault: false,
      }));
      const updatedCardsData = defaultCardsData.map((cardData) =>
        cardData.id === cardId ? { ...cardData, isDefault: true } : cardData
      );
      setCardsData(updatedCardsData);
      cardDefaultToastId.current = toast("card default successfully...", {
        type: "success",
      });

      console.log({ data });
    } catch (error) {
      console.log({ error });
      cardDefaultToastId.current = toast("something went wrong ", {
        type: "error",
      });
    }
  };
  // charge on card promise
  const propertyCheckoutPromise = async () => {
    // find default card
    const defaultCard = cardsData.find(({ isDefault }) => isDefault === true);
    // if card is already default then do nothing
    try {
      setChargeOnCardLoading(true);
      if (cardsData.length === 0) {
        navigate("/add-credit-card");
        setChargeOnCardLoading(false);
        return;
      }
      const { data } = await axios.post(
        mpac_card_charging_url,
        {
          userId: loginData?.userDetails.id,
          cardId: defaultCard?.id,
          listingPlanId: createPropertyPlan?.planId,
          reportingPlanIds: createPropertyReport?.map(({ planId }) => planId),
          address: {
            streetType: mpacCreatePropertyData.results[0].address.streetType,
            streetDirection:
              mpacCreatePropertyData.results[0].address.streetDirection,
            unitDesignator:
              mpacCreatePropertyData.results[0].address.unitDesignator,
            unitNumber: mpacCreatePropertyData.results[0].address.unitNumber,
            municipality:
              mpacCreatePropertyData.results[0].address.municipality,
            province: mpacCreatePropertyData.results[0].address.province,
            streetName: mpacCreatePropertyData.results[0].address.streetName,
            postalCode: mpacCreatePropertyData.results[0].address.postalCode,
            streetNumber:
              mpacCreatePropertyData.results[0].address.streetNumber,
          },
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setMpacAdditionalDetailsData(data.results);

      setIsShowModal(true);
      setTimeout(() => {
        setIsShowModal(false);
        navigate("/property-info");
      }, 2000);
      setChargeOnCardLoading(false);
      console.log({ data });
    } catch (error: any) {
      console.log({ error });
      cardDefaultToastId.current = toast(error.response.data.responseMessage, {
        type: "error",
      });
      setChargeOnCardLoading(false);
    }
  };

  console.log({ createPropertyData, mpacCreatePropertyData });
  // checking property plan is selected or not then navigate
  if (
    !objectNotEmpty(createPropertyData) &&
    !objectNotEmpty(mpacCreatePropertyData)
  )
    return <Navigate to="/create-property" />;
  // checking property plan is selected or not then navigate
  if (
    !objectNotEmpty(createPropertyPlan) &&
    !objectNotEmpty(createPropertyReport)
  )
    return <Navigate to="/create-property-plans" />;
  // checking discount amount or not

  const propertyReportDiscount = createPropertyReport?.filter(
    ({ discountAmount }) => parseInt(discountAmount) === 0
  );

  console.log(
    createPropertyReport?.reduce(
      (total, { discountAmount }) => total + parseInt(discountAmount),
      0
    ),
    "createPropertyReport"
  );

  // when checkout successfully move to property list
  const doTask = () => {
    navigate("/property-info");
  };

  console.log({ createPropertyPlan, createPropertyReport });
  return (
    <>
      <Head title="property-checkout" />
      <CustomModal
        isModalVisible={isShowModal}
        setIsModalVisible={setIsShowModal}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Checkout successfully"
          description="Your plan Subscription Successfully."
          doTask={doTask}
        />
      </CustomModal>
      <PropertyCheckoutStyle>
        <div>
          <Container>
            <Row>
              <Col md={22} className="w-50 mx-auto">
                <div className="bill-info-side">
                  <div className="head-bar">
                    <h6 className="head-bar-title">Billing Info</h6>
                  </div>
                  <div className="features pb-3">
                    {
                      <div className="feature d-flex justify-content-between">
                        <p className="feature-title mb-0">
                          {createPropertyPlan?.displayName}
                        </p>
                        <p className="feature-price mb-0">
                          {createPropertyPlan?.currencySymbol}
                          {createPropertyPlan?.subscriptionAmount}
                        </p>
                      </div>
                    }
                    {createPropertyReport?.map(
                      ({
                        displayName,
                        currencySymbol,
                        subscriptionAmount,
                        planId,
                      }) => (
                        <div
                          key={planId}
                          className="feature d-flex justify-content-between"
                        >
                          <p className="feature-title mb-0">{displayName}</p>
                          <p className="feature-price mb-0">
                            {currencySymbol}
                            {subscriptionAmount}
                          </p>
                        </div>
                      )
                    )}

                    <div className="feature d-flex justify-content-between">
                      <p className="feature-title mb-0">Subtotal</p>
                      <p className="feature-price mb-0">
                        $
                        {parseFloat(createPropertyPlan.subscriptionAmount) +
                          createPropertyReport?.reduce(
                            (total, { subscriptionAmount }) =>
                              total + parseFloat(subscriptionAmount),
                            0
                          )}
                      </p>
                    </div>
                    <div className="feature d-flex justify-content-between">
                      <p className="feature-title mb-0">Discount</p>
                      <p className="feature-price mb-0">
                        $
                        {parseFloat(createPropertyPlan.discountAmount) +
                          createPropertyReport?.reduce(
                            (total, { discountAmount }) =>
                              total + parseFloat(discountAmount),
                            0
                          )}
                      </p>
                    </div>
                    <div className="feature d-flex justify-content-between">
                      <p className="feature-title mb-0">Gst</p>
                      <p className="feature-price mb-0">
                        $
                        {parseFloat(createPropertyPlan.gst) +
                          createPropertyReport?.reduce(
                            (total, { gst }) => total + parseFloat(gst),
                            0
                          )}
                      </p>
                    </div>
                    <div className="feature d-flex justify-content-between">
                      <p className="feature-title mb-0">Service Fees</p>
                      <p className="feature-price mb-0">
                        $
                        {(
                          parseFloat(createPropertyPlan.totalServiceFee) +
                          createPropertyReport?.reduce(
                            (total, { totalServiceFee }) =>
                              total + parseFloat(totalServiceFee),
                            0
                          )
                        ).toFixed(2)}
                      </p>
                    </div>
                    <div className="feature d-flex justify-content-between">
                      <p className="feature-title mb-0">Grand Total</p>
                      <p className="feature-price mb-0">
                        ${" "}
                        {(
                          parseFloat(createPropertyPlan.grandTotal) +
                          createPropertyReport?.reduce(
                            (total, { grandTotal }) =>
                              total + parseFloat(grandTotal),
                            0
                          )
                        ).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="checkout-btn d-flex justify-content-center mt-3">
                  <CustomButton
                    bgcolor={primaryColor}
                    color={whiteColor}
                    padding="6px"
                    width="94%"
                    loading={chargeOnCardLoading}
                    type="submit"
                    title="Check Out"
                    margin="auto"
                    fontFamily="EnnVisionsMedium"
                    clicked={propertyCheckoutPromise}
                    fontSize="16px"
                  />
                </div>
              </Col>
              {/* <Col md={6} className="p-0 p-md-0 mt-2 mt-md-0">
                <div className="credit-card-side mt-4 mt-md-0">
                  <div className="head-bar bg-light d-flex justify-content-between">
                    <h6 className="head-bar-title">Credit Card Info</h6>
                    <Link to="/add-credit-card">
                      <img src={plusIcon} alt="plus" />
                    </Link>
                  </div>
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <h5 className="text-center mt-2 fw-bold">No Cards Found</h5>
                  ) : (
                    <div className="payments">
                      <div className="cards-section">
                        {cardsData?.map(
                          ({
                            id,
                            brand,
                            cardHolderName,
                            cardNumber,
                            isDefault,
                            stripeCardId,
                          }) => (
                            <div
                              onClick={() => togglePayment(id)}
                              key={id}
                              className="payments-type d-flex align-items-center"
                            >
                              <img
                                src={cardBrands[brand]}
                                className="cursor-pointer"
                                alt="plus"
                              />
                              <div className="ms-3 d-flex justify-content-between w-100 align-items-center cursor-pointer cards-section">
                                <div>
                                  <h6 className="step-title mb-2">
                                    {cardHolderName}
                                  </h6>
                                  <p className="step-subtitle mb-0">
                                    {cardNumber.padStart(16, "*")}
                                  </p>
                                </div>
                                <img
                                  onClick={() =>
                                    makeCardDefaultPromise(id, isDefault)
                                  }
                                  src={isDefault ? selectIcon : unselectIcon}
                                  alt={`${
                                    isDefault ? "select-icon" : "unselect-icon"
                                  }`}
                                  className="select me-3"
                                />
                              </div>
                              <DeleteOutlined
                                className="fs-4 ms-auto mt-2 mb-3"
                                onClick={() =>
                                  deleteCreditCard({
                                    cardId: id,
                                    stripeCardId,
                                    userId: loginData?.userDetails.id!,
                                  })
                                }
                              />
                            </div>
                          )
                        )}
                      </div>
                      <div className="checkout-btn d-flex justify-content-center mt-3">
                        <CustomButton
                          bgcolor={primaryColor}
                          color={whiteColor}
                          padding="6px"
                          width="94%"
                          loading={chargeOnCardLoading}
                          type="submit"
                          title="Check Out"
                          margin="auto"
                          fontFamily="EnnVisionsMedium"
                          clicked={propertyCheckoutPromise}
                          fontSize="16px"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
      </PropertyCheckoutStyle>
    </>
  );
};

export default PropertyCheckout;
