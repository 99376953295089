import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import bedroomIcon from "../../assets/icons/ic_property_bed.svg";
import bathIcon from "../../assets/icons/ic_property_bath.svg";
import areaIcon from "../../assets/icons/ic_property_area.svg";
import {
  BlackDot,
  MiniHeading,
  TertiraryHeading,
  UnderlineRed,
} from "../GlobalStyle";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PropertiesStyle } from "./style";
import { getMyPropertiesTypes } from "../../utils/types";
import HeadingViewMoreStyle from "../HeadingViewMore/style";
import ViewMore from "../ViewMoreProperties/ViewMore";
import view_more from "../../assets/icons/ic_newsfeed_more.svg";
import { Dispatch, SetStateAction, useState } from "react";
import { useGlobalContext } from "../../context/context";
import EnnvisionWarningModal from "../CustomModals/WarningModal";
import CustomModal from "../Modal/CustomModal";
import axios from "axios";
import { useAppSelector } from "../../app/hooks";
import { authorizationToken, property_delete_url } from "../../utils/api_urls";
import EnnvisionModal from "../CustomModals/EnnvisionModal";
import NoDataFound from "../NoData/NoDataFound";
import { numberToLocalString } from "../../utils/utilities";

type propertiesProps = {
  propertiesData: getMyPropertiesTypes;
  getMyListing: Dispatch<SetStateAction<boolean>>;
};

const Properties: React.FC<propertiesProps> = ({
  propertiesData,
  getMyListing,
}) => {
  const navigate = useNavigate();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { data: loginData } = useAppSelector((state) => state.loginData);
  const [error, setError] = useState("");
  const { property } = useAppSelector((state) => state.appData.data.statusList);

  const viewAllPropertiesNavigation = (id: number, title: string) => {
    const propertyStatus = property.find(
      ({ name }) => name.toLocaleLowerCase() === title
    )?.id;
    console.log({ title, propertyStatus });
    navigate(`/properties-by-status/${propertyStatus}`, {
      state: { propertyTitle: title },
    });
  };

  const [showOptions, setShowOptions] = useState(
    Object.entries(propertiesData).map(([_, properties]) =>
      new Array(properties.length).fill(false)
    )
  );
  // show property options
  const {
    showPropertyOptions,
    setShowPropertyOptions,
    setSelectedPropertyId,
    selectedPropertyId,
  } = useGlobalContext();

  // delete property promise
  const deletePropertyPromise = async () => {
    try {
      setIsDeleting(true);
      const { data } = await axios.delete(property_delete_url, {
        data: {
          propertyId: selectedPropertyId,
        },
        headers: {
          ...authorizationToken(loginData!),
        },
      });
      setIsDeleting(false);
      setIsDeleteModal(false);
      setIsModalVisible(true);
      setTimeout(() => {
        getMyListing((prev) => !prev);
        setIsModalVisible(false);
      }, 2000);
    } catch (error: any) {
      console.log(error);
      setError(error.response.data.responseMessage);
      setIsDeleting(false);
      setIsDeleteModal(true);
      alert(error.response.data.responseMessage);
    }
  };

  console.log(
    Object.entries(propertiesData).map(([propertyTitle, properties]) => {
      return properties;
    }),
    "properties in data"
  );
  console.table(showOptions);

  type toggleDropdownTypes = {
    propertiesIndex: number;
    index: number;
    open: boolean;
  };
  const toggleDropdown = ({
    propertiesIndex,
    index,
    open,
  }: toggleDropdownTypes) => {
    const newShowOptions = [...showOptions];
    console.log(
      newShowOptions[propertiesIndex][index],
      open,
      "index of newshowoptions"
    );
    newShowOptions[propertiesIndex][index] = open;
    setShowOptions(newShowOptions);
  };

  const propertySizeTypes = useAppSelector(
    (state) => state.appData.data.propertyAppData[0].propertySizeDTOS
  );

  // do task when professional deletes
  const doTask = () => {
    getMyListing((prev) => !prev);
    setIsModalVisible(false);
  };

  console.log({ propertiesData, showOptions });
  // filtering empty properties type
  const myListingProperties = Object.entries(propertiesData).filter(
    ([_, properties]) => properties.length > 0
  );

  console.log({ myListingProperties });

  return (
    <PropertiesStyle marginTop="0px">
      <CustomModal
        isModalVisible={isDeleteModal}
        setIsModalVisible={setIsDeleteModal}
        showCloseBtn={false}
      >
        <EnnvisionWarningModal
          setIsModalVisible={setIsDeleteModal}
          isLoading={isDeleting}
          title="Delete Property"
          description="Are You Sure You Want To Delete Your Property? This Action Is Irreversible And All Your Data And Information Associated With The Property Will Be Permanently Deleted."
          doTask={deletePropertyPromise}
        />
      </CustomModal>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Property Deleted"
          description="Property Delete Successfully"
          doTask={doTask}
        />
      </CustomModal>
      <div className="properties pb-2">
        {myListingProperties.length > 0 ? (
          myListingProperties.map(
            ([propertyTitle, properties], propertiesIndex) => (
              <div key={propertiesIndex}>
                <div className="my-2">
                  <HeadingViewMoreStyle textTransform="capitalize">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                      <div className="d-flex flex-column justify-content-center">
                        <TertiraryHeading>{propertyTitle}</TertiraryHeading>
                        {/* <div>
                          <UnderlineRed />
                          <BlackDot />
                        </div> */}
                      </div>
                      <div
                        onClick={() =>
                          viewAllPropertiesNavigation(
                            propertiesIndex,
                            propertyTitle
                          )
                        }
                      >
                        <ViewMore listing="View All" />
                      </div>
                    </div>
                  </HeadingViewMoreStyle>
                </div>
                <Row>
                  {properties.map((propertyData, index) => {
                    const {
                      title,
                      propertyId,
                      purpose,
                      price,
                      fullBedrooms,
                      fullBathrooms,
                      propertySize: { size, keyName },
                      location,
                    } = propertyData;
                    return (
                      <Col
                        onClick={() =>
                          navigate(`/property-details/${propertyId}`)
                        }
                        key={propertyId}
                        sm={6}
                        lg={4}
                        xxl={3}
                      >
                        <Card>
                          <Card.Body>
                            <div className="d-flex justify-content-between align-items-center gap-3">
                              <MiniHeading>{title}</MiniHeading>
                              <div className="px-2 cursor-pointer"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setSelectedPropertyId(propertyId);
                                  setShowPropertyOptions(true);
                                }}
                              >
                              <img
                                style={{ height: "6px" }}
                                src={view_more}
                                className="align-self-start"
                                alt="view-more"
                              />
                              </div>
                            </div>
                            <Card.Text className="sub-title mt-2">
                              {location}
                            </Card.Text>
                            <div className="d-flex justify-content-between align-items-center property-description">
                              <Button variant="primary">{purpose}</Button>
                              <p className="price m-0">
                                $ {price && numberToLocalString(+price)}
                              </p>
                            </div>
                            <div className="property-details d-flex justify-content-between align-items-center">
                              <div className="beds d-flex justify-content-between align-items-center">
                                <span>
                                  <img src={bedroomIcon} alt="bed" />
                                </span>
                                <p className="text">{fullBedrooms}</p>
                              </div>

                              <div className="bathrooms d-flex justify-content-between align-items-center">
                                <span>
                                  <img src={bathIcon} alt="bath" />
                                </span>
                                <p className="text">{fullBathrooms}</p>
                              </div>
                              <div className="length d-flex justify-content-between align-items-center">
                                <span>
                                  <img src={areaIcon} alt="area" />
                                </span>
                                <p className="text">
                                  {size || ""}
                                  {
                                    propertySizeTypes.find(
                                      (propertySize) =>
                                        propertySize.keyName === keyName
                                    )?.displayName
                                  }
                                </p>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            )
          )
        ) : (
          <NoDataFound />
        )}
      </div>
    </PropertiesStyle>
  );
};

export default Properties;
