import React from "react";
import { fontFamilyMedium } from "../GlobalStyle";
import styled from "styled-components";
import { NewsFeedRecord } from "../../utils/types";
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
  EmailShareButton,
  TwitterShareButton,
} from "react-share";
import { live_base_url } from "../../utils/api_urls";
import { truncateString } from "../../utils/utilities";

type socialOptionsProps = {
  postData: NewsFeedRecord;
};
const SocialOptions: React.FC<socialOptionsProps> = ({ postData }) => {
  const socialBtnStyle = {
    padding: "7px 7px",
    color: "rgba(0, 0, 0, 0.7)",
    boxShadow: "0px 0.2px 0.2px rgba(0, 0, 0, 0.3)",
    fontWeight: 500,
    cursor: "pointer",
    transition: "all 0.1s",
    fontSize: 18,
    fontFamily: fontFamilyMedium,
    textDecoration: "none",
  };

  // share post url
  const postUrl = postData.propertyId
    ? live_base_url + `property-details/${postData.propertyId}`
    : live_base_url + `post/${postData.postId}`;

  // share post title
  const postTitle = postData.propertyId
    ? truncateString(postData.propertyTitle!, 120)
    : truncateString(postData.description || "", 120);

  // share post description
  const postDescription = truncateString(postData.description || "", 120);

  const handleShareClick = () => {
    window.open(postUrl, "_blank");
  };

  return (
    <Wrapper>
      <ul>
        <li
          className="title"
          style={{ color: "rgb(231, 9, 9)", fontWeight: "500" }}
        >
          Social Share
        </li>
        <div className="d-flex flex-column align-items-start">
          <FacebookShareButton
            url={postUrl}
            style={socialBtnStyle}
            id="social-btn"
            title={postTitle}
            quote={postDescription}
            onClick={handleShareClick}
          >
            Share on Facebook
          </FacebookShareButton>

          {/* <FacebookMessengerShareButton
            style={socialBtnStyle}
            id="social-btn"
            url={postUrl}
            appId=""
          >
            Share on Messenger
          </FacebookMessengerShareButton> */}
          <LinkedinShareButton
            style={socialBtnStyle}
            id="social-btn"
            url={postUrl}
            title={postTitle}
            summary={postDescription}
            source={postUrl}
          >
            Share on LinkedIn
          </LinkedinShareButton>
          <WhatsappShareButton
            style={socialBtnStyle}
            id="social-btn"
            url={postUrl}
            title={postTitle}
          >
            Share on WhatsApp
          </WhatsappShareButton>
          <EmailShareButton
            style={socialBtnStyle}
            id="social-btn"
            url={postUrl}
            title={postTitle}
          >
            Share on Email
          </EmailShareButton>
          <TwitterShareButton
            style={socialBtnStyle}
            id="social-btn"
            url={postUrl}
            title={postTitle}
          >
            Share on Twitter
          </TwitterShareButton>
        </div>
      </ul>
    </Wrapper>
  );
};

export default SocialOptions;

// styling

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 2px 5px 4px rgba(0, 0, 0, 0.3);
  font-weight: 500;
  transition: all 0.4s;
  &:focus {
    width: 100%;
  }
  li,
  #social-btn {
    padding: 7px 7px;
    color: rgba(0, 0, 0, 0.7);
    box-shadow: 0px 0.2px 0.2px rgba(0, 0, 0, 0.3);
    font-weight: 500;
    transition: all 0.1s;
    font-size: 18px;
    font-family: ${fontFamilyMedium};
  }
  #social-btn:hover {
    box-shadow: 0.2px 0.1px 0.1px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
    background-color: rgba(0, 0, 0, 0.3) !important;
    color: #fff !important;
    cursor: pointer;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    text-decoration: none;
  }
`;
