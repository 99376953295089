import styled from "styled-components";

type CustomButtonProps = {
  textTransform: string;
  color: string;
  padding: string;
  border: string;
  margin: string;
  fontFamily: string;
  bgcolor: string;
  width: string;
  fontSize: string;
};
const CustomButtonStyle = styled.div<CustomButtonProps>`
  width: ${(props) => props.width};

  .ant-btn {
    outline: none;
    background-color: ${(props) => props.bgcolor};
    color: ${(props) => props.color};
    padding: ${(props) => props.padding};
    font-size: ${(props) => props.fontSize};
    border-radius: 4px;
    text-transform: ${(props) => props.textTransform};
    width: -moz-available;
    width: -webkit-fill-available;
    height: auto;
    margin: ${(props) => props.margin};
    border: ${(props) => props.border};
    font-family: ${(props) => props.fontFamily};
    cursor: pointer;

    &:link,
    &:visited {
      text-transform: uppercase;
      text-decoration: none;
      padding: 15px 40px;
      display: inline-block;
      border-radius: 100px;
      transition: all 0.2s;
      position: absolute;
    }

    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }

    &:active {
      transform: translateY(-1px);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    }

    &::after {
      content: "";
      display: inline-block;
      height: 100%;
      width: 100%;
      border-radius: 100px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: all 0.4s;
    }

    &-animated {
      animation: moveInBottom 5s ease-out;
    }

    @keyframes moveInBottom {
      0% {
        opacity: 0;
        transform: translateY(30px);
      }

      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }
`;

export default CustomButtonStyle;
