import { useEffect, useRef, useState } from "react";
import { CreateStoryImagesStyle } from "./style";
import { Formik } from "formik";
import * as Yup from "yup";
import Head from "../../components/Head/Head";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import AliceCarousel from "react-alice-carousel";
import { toast } from "react-toastify";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Form } from "antd";
import CustomModal from "../../components/Modal/CustomModal";
import EnnvisionModal from "../../components/CustomModals/EnnvisionModal";
import { useStoryContext } from "../../context/StoryContext";
import createIcon from "../../assets/icons/ic_upload_camera_story.svg";
import LoadingOverlay from "../../components/Modal/LoadingOverlay";
import { authorizationToken, create_story_url } from "../../utils/api_urls";
import NextBtn from "../../components/Custom/NextBtn";
import PrevBtn from "../../components/Custom/PrevBtn";

type createPostInitialTypes = {
  storyText: string;
  contactRequestPermission: boolean;
  boostPermission: boolean;
  saveFavourite: boolean;
  sharingEnabled: boolean;
  turnOffCommenting: boolean;
  readComments: boolean;
  hideLikesAndViewsCounts: boolean;
};

const CreateStoryImages = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCreatingPost, setIsCreatingPost] = useState(false);
  const { data: loginData } = useAppSelector((state) => state.loginData);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { createStoryImages } = useStoryContext();
  console.log({ createStoryImages });

  const carousel = useRef<AliceCarousel>(null);
  const navigate = useNavigate();

  const { result: locationData } = useAppSelector(
    (state) => state.userLocation
  );

  let initialValues: createPostInitialTypes = {
    storyText: "",
    contactRequestPermission: true,
    boostPermission: true,
    saveFavourite: true,
    sharingEnabled: true,
    turnOffCommenting: false,
    readComments: true,
    hideLikesAndViewsCounts: false,
  };

  const validationSchema = Yup.object({
    storyText: Yup.string().optional(),
    contactRequestPermission: Yup.boolean(),
    boostPermission: Yup.boolean(),
    saveFavourite: Yup.boolean(),
    sharingEnabled: Yup.boolean(),
    turnOffCommenting: Yup.boolean(),
    readComments: Yup.boolean(),
    hideLikesAndViewsCounts: Yup.boolean(),
  });

  useEffect(() => {
    if (createStoryImages.length < 1) {
      navigate("/create-story-type");
    }
  }, []);

  const handleSubmit = async (values: createPostInitialTypes) => {
    const formData = new FormData();

    createStoryImages.forEach((file: any) => {
      formData.append("images", file);
    });

    formData.append(
      "data",
      new Blob(
        [
          JSON.stringify({
            ...values,
            userId: loginData?.userDetails.id,
            postLayout: 1,
            ...locationData,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );

    try {
      setIsCreatingPost(true);
      const { data } = await axios.post(create_story_url, formData, {
        headers: {
          ...authorizationToken(loginData!),
        },
      });
      setIsModalVisible(true);
      setTimeout(() => {
        setIsModalVisible(false);
        navigate("/explore-nearby");
      }, 2000);
      setIsCreatingPost(false);
      console.log({ data });
    } catch (error: any) {
      console.log({ error });
      setIsCreatingPost(false);
      toast.error(error.response.data.responseMessage);
    }
  };

  const responsive = {
    0: { items: 1 },
  };

  const imageItems =
    createStoryImages.length !== 0
      ? createStoryImages.map((item: File, index) => (
          <div className="slider-item">
            <img
              onClick={() => navigate("/story-images-grid")}
              className="media-post-item"
              src={URL.createObjectURL(item)}
              alt={"image-" + index}
              key={index}
            />
          </div>
        ))
      : [];

  console.log({ createStoryImages });

  // do task
  const doTask = () => {
    navigate("/explore-nearby");
  };
  console.log({ currentIndex });
  return (
    <CreateStoryImagesStyle>
      <Head title="create-story" />
      {isCreatingPost && <LoadingOverlay message="creating..." />}
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Story Created"
          description="Story Created Successfully"
          doTask={doTask}
        />
      </CustomModal>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          console.log(formik.values, "formik values", formik.errors);
          return (
            <Form
              name="basic"
              onFinish={formik.handleSubmit}
              autoComplete="off"
            >
              <div className="inner-section">
                <button type="submit" className="create-story-btn">
                  <img src={createIcon} alt="creat_icon" />
                </button>
                {/* render if images are available */}
                <div className="position-relative">
                  <div>
                    <AliceCarousel
                      mouseTracking
                      items={imageItems}
                      disableButtonsControls
                      disableDotsControls
                      responsive={responsive}
                      activeIndex={currentIndex}
                    />

                    {imageItems.length > 1 && (
                      <>
                        {currentIndex < imageItems.length - 1 && (
                          <NextBtn
                            clicked={() => setCurrentIndex((prev) => prev + 1)}
                          />
                        )}
                        {currentIndex > 0 && (
                          <PrevBtn
                            clicked={() => setCurrentIndex((prev) => prev - 1)}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </CreateStoryImagesStyle>
  );
};

export default CreateStoryImages;
