import styled from "styled-components";
import {
  fontFamilyMedium,
  lightGrey2,
  primaryColor,
  whiteColor,
} from "../../components/GlobalStyle";
import { Container } from "react-bootstrap";
import placeholder from "../../assets/icons/ic_logo.svg";
import CustomButton from "../../components/CustomButton/CustomButton";

const CreditCardInfo = () => {
  return (
    <Container>
      <Wrapper>
        <div className="inner-container">
          <div className="d-flex flex-column justify-content-between card-info-container">
            <h5 className="heading">Credit Card Info</h5>
            <div className="credit-cards">
              <div className="upper-section"></div>
              <CustomButton
                bgcolor={primaryColor}
                color="white"
                padding="8px 8px"
                width="100%"
                type="submit"
                title="Add New Card"
                margin="auto"
                fontFamily="EnnVisionsMedium"
                fontSize="16px"
              />
            </div>
          </div>
          <div className="bank-info-container">
            <h5 className="heading">Bank Account Info</h5>
            <div className="bank-accounts">
              <img className="logo-icon" src={placeholder} alt="placeholder" />
              <h4>Coming Soon</h4>
            </div>
          </div>
        </div>
      </Wrapper>
    </Container>
  );
};

export const Wrapper = styled.div`
  padding-top: 40px;

  .inner-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    border-radius: 5px;
    div {
      box-shadow: 0px 3px 5px rgba(255, 255, 255, 0.3);
      height: 400px;
      border-radius: 5px;
    }
    .heading {
      font-size: 20px;
      font-family: ${fontFamilyMedium};
      text-decoration: underline;
    }
    .card-info-container {
      .upper-section {
        border: 1px solid ${lightGrey2};
      }
      .credit-cards {
      }
    }
    .bank-info-container {
      .bank-accounts {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid ${lightGrey2};
        .logo-icon {
          height: 80px;
          width: 120px;
        }
      }
      h4 {
        font-size: 18px;
        color: grey;
        font-family: ${fontFamilyMedium};
        margin-top: 10px;
      }
    }
    .card-btn {
      height: 35px;
      border-radius: 5px;
      padding: 5px;
      background-color: red;
      color: ${whiteColor};
    }
  }
`;
export default CreditCardInfo;
