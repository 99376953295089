import { Button, Col, Row } from "react-bootstrap";
import search_icon from "../../assets/icons/ic_search-1.svg";
import { Formik } from "formik";
import ProfessionFilters from "../../components/Filters/ProfessionsFilter";
import { useGlobalContext } from "../../context/context";
import { Dropdown, Form } from "antd";
import { ProfessionalFiltersStyle } from "./style";
import { useSearchContext } from "../../context/SearchContext";
import { useAppSelector } from "../../app/hooks";
import { FindHomeFilter } from "../FindHome/style";
import dropdown_icon from "../../assets/icons/ic_drop_down.svg";
import axios from "axios";
import { authorizationToken } from "../../utils/api_urls";

// initial values
type initialValuesTypes = {
  keyWord: string;
};

const FindProfessionalFilters = () => {
  const {
    showProfessions,
    setShowProfessions,
    professionalAddress,
    setProfessionalAddress,
    setProfessionalFilterCriteria,
    selectedProfessions,
  } = useSearchContext();
  const { professionType } = useGlobalContext();
  const initialValues: initialValuesTypes = {
    keyWord: "",
  };
  const { data: loginData } = useAppSelector((state) => state.loginData);

  // search professional promise
  const handleSubmit = async (values: initialValuesTypes) => {
    try {
      if (!values.keyWord || Object.keys(professionType).length === 0) {
        return;
      }
      const { data} = await axios.post(
        "https://fistastore.com:444/ennvision_apis/user/professionals/search",
        {
          keyWord:values.keyWord,
          professionalTypeId:selectedProfessions?.map(id=>{return id})
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );      console.log("Search Results:", data);
    } catch (error) {
      console.error("Error occurred during search:", error);
    }
  };
  
  // select all professions for filter if not profession is selected

  const {
    data: { professions },
  } = useAppSelector((state) => state.appData);

  console.log({ professionalAddress });

  console.log({ professionType });
  return (
    <ProfessionalFiltersStyle>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formik) => {
          console.log(formik.values, "formik values");
          return (
            <Form
              name="basic"
              autoComplete="off"
              onFinish={formik.handleSubmit}
            >
              <div className="inner-container d-flex align-items-center flex-column justify-content-center">
                <div className="position-relative w-100 mx-2 ">
                  <Row className="location-select-section mx-auto py-3 align-items-center">
                    <Col sm={6} className="location text-sm-start">
                      <h5 className="title">Name</h5>
                      <input
                        type="text"
                        className="w-100"
                        style={{
                          border: "1px solid #EFEFF4",
                          padding: "8px",
                          borderRadius: "7px",
                        }}
                        placeholder="Name of Professional etc."
                        value={professionalAddress}
                        onChange={(event) =>
                          setProfessionalAddress(event.target.value)
                        }
                      />
                    </Col>
                    <Col
                      sm={6}
                      className="property-type d-flex justify-content-sm-between justify-content-center align-items-center mt-2 mt-sm-0"
                    >
                      <div className="left-section ps-md-4 w-100">
                        <h5 className="title">Profession</h5>
                        <Dropdown
                          open={showProfessions}
                          onOpenChange={(open) => setShowProfessions(open)}
                          dropdownRender={() => <ProfessionFilters />}
                          trigger={["click"]}
                        >
                          <FindHomeFilter>
                            {selectedProfessions.length} Profesions Selected
                            <span>
                              <img src={dropdown_icon} alt="dropdown" />
                            </span>
                          </FindHomeFilter>
                        </Dropdown>
                      </div>
                      <Button
                        type="button"
                        className="search-box ms-2 d-flex justify-content-center align-items-center"
                        onClick={() => {
                          if (professionalAddress) {
                            // setting address
                            setProfessionalFilterCriteria(
                              "address",
                              professionalAddress
                            );
                          }
                          // add selected profession id's
                          const selectedIds = selectedProfessions?.map(({ id }) => id)
                            .join(",");
                          // add all id's if no profession is selected
                          const zeroSelectedIds = professions?.map(({ id }) => id)
                            .join(",");
                          // setting subtypes search filter
                          setProfessionalFilterCriteria(
                            "profession",
                            selectedProfessions.length < 1
                              ? zeroSelectedIds
                              : selectedIds
                          );
                          setShowProfessions(false);
                        }}
                      >
                        <img src={search_icon} alt="search" onClick={()=>{handleSubmit(formik.values)}} />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </ProfessionalFiltersStyle>
  );
};

export default FindProfessionalFilters;
