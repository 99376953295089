import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { professions_dropdown_url } from "../../../utils/api_urls";
import axios from "axios";

type dropdownType = {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
};
type initialStateType = {
  loading: boolean;
  data: dropdownType[];
  error: any;
};
const initialState: initialStateType = {
  loading: false,
  data: [] as dropdownType[],
  error: "",
};

// Generates pending, fulfilled and rejected action types
export const getProfessionalDropdown = createAsyncThunk(
  "professional/dropdown",
  async (_, thunkAPI) => {
    const state: any = thunkAPI.getState();
    try {
      const { data } = await axios.post(
        professions_dropdown_url,
        {},
        {
          headers: {
            Authorization: `Bearer ${
              state.loginData && state.loginData.data.jwtDetails.token
            }`,
          },
        }
      );
      return data.results;
    } catch (error) {
      return thunkAPI.rejectWithValue("url is wrong");
    }
  }
);

const professionalDropdownSlice = createSlice({
  name: "professional_dropdown",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfessionalDropdown.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getProfessionalDropdown.fulfilled,
      (state, action: PayloadAction<dropdownType[]>) => {
        state.loading = false;
        state.data = action.payload;
        state.error = "";
      }
    );
    builder.addCase(getProfessionalDropdown.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload || "Something went wrong";
    });
  },
});

export default professionalDropdownSlice.reducer;
