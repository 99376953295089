import { Col, Container, Row } from "react-bootstrap";
import {
  SecondaryHeading,
  TertiraryHeadingMini,
} from "../../components/GlobalStyle";
import { WhoWeAreStyled } from "./style";
import whoWeAreImg from "../../assets/images/ic_who_we_are.png";
import RedBlackUnderline from "../../components/RedBlackUnderline/RedBlackUnderline";

const WhoWeAre = () => {
  return (
    <WhoWeAreStyled>
      <SecondaryHeading className="text-center">Who We Are</SecondaryHeading>
      {/* <TertiraryHeadingMini className="text-center">
        Know More About Us
      </TertiraryHeadingMini> */}
      <RedBlackUnderline />
      <Container>
        <Row className="inner-container">
          <Col md={6}>
            <img src={whoWeAreImg} alt="who-we-are" />
          </Col>
          <Col md={6} className="mt-3 mt-md-0">
            <p>
              Are you torn between the idea of sprucing up your current home or
              embarking on a quest to find your dream house? If you're curious
              about how desirable your house is or if you're contemplating a
              potential relocation, House'up offers the perfect solution. By
              registering your house on the app, you can not only get insights
              into the level of interest in your property but also in your
              street or neighborhood.
            </p>
            <p>
              You'll have the opportunity to receive likes and engage in direct
              communication with enthusiastic house hunters. The best part?
              House Up eliminates the need for professionals, providing you with
              a hassle-free experience and complete freedom to explore your
              options. Whether you're simply curious or actively considering a
              move, House'up is your go-to platform for discovering the
              popularity of your house and connecting with potential buyers, all
              without any obligations.
            </p>
          </Col>
        </Row>
      </Container>
    </WhoWeAreStyled>
  );
};

export default WhoWeAre;
