import { useNewsfeedContext } from "../../context/NewsfeedContext";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { primaryRed } from "../GlobalStyle";
import Wrapper from "./style";

const OtherPropertyOptions = () => {
  const { setShowSocialOptions, selectedPost, setShowPropertyOptions } =
    useNewsfeedContext();
  const copyToClipboard = useCopyToClipboard();

  const propertyOptions = [
    {
      title: "Hide property to timeline",
      doTask: () => {
        setShowPropertyOptions(false);
      },
    },
    {
      title: "Copy link to Share this Property",
      doTask: () => {
        copyToClipboard(`property-details/${selectedPost.propertyId}`);
        setShowPropertyOptions(false);
      },
    },
    {
      title: "Share on other media",
      doTask: () => {
        setShowSocialOptions(true);
        setShowPropertyOptions(false);
      },
    },
  ];
  return (
    <Wrapper>
      <ul>
        <li className="title" style={{ color: primaryRed, fontWeight: "500" }}>
          Report Property
        </li>
        {propertyOptions.map(({ title, doTask }, index) => {
          return (
            <li key={index} onClick={doTask}>
              {title}
            </li>
          );
        })}
      </ul>
    </Wrapper>
  );
};

export default OtherPropertyOptions;
