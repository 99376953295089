import { ErrorMessage, Formik } from "formik";
import logo from "../../assets/icons/ic_logo.svg";
import CustomButton from "../../components/CustomButton/CustomButton";
import { ImgContainer, primaryColor } from "../../components/GlobalStyle";
import VerifyOtpStyle from "./style";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context/context";
import { useAppSelector } from "../../app/hooks";
import { useEffect, useRef, useState } from "react";
import { Form } from "antd";
import { errorTypes } from "../../utils/types";
import axios from "axios";
import { useCaseRegisteration, verify_otp_url } from "../../utils/api_urls";
import { toast } from "react-toastify";
import Head from "../../components/Head/Head";
import ReactInputMask from "react-input-mask";
import Errormsg from "../../components/ErrorMessage";
import useGenerateOtp from "../../hooks/useGenerateOtp";

type verifyOtpTypes = {
  otp: string;
};
const VerifyOtp = () => {
  const navigate = useNavigate();
  const { userPhoneNumber } = useGlobalContext();
  // get Validations from redux appData
  const { validations } = useAppSelector((state) => state.appData.data);
  const { countryName } = useAppSelector((state) => state.appData.data);
  const [count, setCount] = useState(59);
  const otpToastId = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const {
    loading: regenrateOtpLoader,
    handleSubmit: regenerateOtp,
    error: regenerateError,
  } = useGenerateOtp();

  // counter for otp
  useEffect(() => {
    let interval: any;
    if (count > 0 && userPhoneNumber) {
      interval = setInterval(() => {
        setCount((count) => count - 1);
      }, 1000);
    } else {
      return;
    }
    return () => {
      clearInterval(interval);
    };
  }, [count]);

  // user validations
  const otpValidation = validations[21];
  // user regExpressions
  const otpRegex = new RegExp(otpValidation.pattern);

  const initialValues: verifyOtpTypes = {
    otp: "",
  };

  const validationSchema = Yup.object({
    otp: Yup.string()
      .required(otpValidation.notBlankMsg)
      .matches(otpRegex, otpValidation.patternMsg),
  });

  console.log({ userPhoneNumber });

  // register phone handler
  const handleSubmit = async (values: verifyOtpTypes) => {
    const phoneData = {
      phoneNumber: userPhoneNumber,
      otp: values.otp.toString(),
      useCase: useCaseRegisteration,
    };
    try {
      setLoading(true);
      setError("");
      const { data } = await axios.post(verify_otp_url, phoneData);
      if (data.responseCode === "500") {
        otpToastId.current = toast(data.responseMessage, {
          type: "error",
          autoClose: 1000,
        });
        setLoading(false);
        return;
      }
      setLoading(false);
      otpToastId.current = toast(data.responseMessage, {
        type: "success",
        autoClose: 1000,
      });
      setLoading(false);
      navigate("/register");
      console.log({ data });
    } catch (error: any) {
      console.log({ error });
      otpToastId.current = toast(error.response.data.responseMessage, {
        type: "error",
        autoClose: 1000,
      });
      setTimeout(() => {
        setError("");
      }, 2000);
      setLoading(false);
      setError(error.response.data.responseMessage);
    }
  };
  return (
    <>
      <Head title="verify-otp" />
      <VerifyOtpStyle>
        <div className="inner-container">
          <ImgContainer src={logo} alt="logo" height="33px" width="164px" />
          <h6 className="title mb-0">Veriﬁcation codes OTP</h6>
          <p className="message">
            A verification code has been sent to your mobile number. Please
            enter the OTP below to confirm your identity.
            <p className="number d-flex justify-content-center align-items-center mb-0">
              +
              {userPhoneNumber
                ? +`${
                    countryName.results.countryCode.toString() + userPhoneNumber
                  }`
                : "please first register phone"}
              {/* <span>
                <CustomButton
                  bgcolor="transparent"
                  color={primaryColor}
                  padding="4px 8px"
                  width="100%"
                  type="button"
                  title="Edit"
                  margin="auto"
                  fontSize="14px"
                  fontFamily="EnnVisionsMedium"
                  clicked={navigateToVerifyOtp}
                  border="none"
                />
              </span> */}
            </p>
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              return (
                <Form
                  name="basic"
                  onFinish={formik.handleSubmit}
                  autoComplete="off"
                >
                  <div className="mt-2">
                    <ReactInputMask
                      type="tel"
                      mask="9999"
                      maskChar=""
                      placeholder="____"
                      name="otp"
                      onChange={(event) =>
                        formik.setFieldValue("otp", event.target.value)
                      }
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                        border: "1px solid #ced4da",
                        borderRadius: "0.25rem",
                        padding: "0.375rem 0.75rem",
                        fontSize: "1.5rem",
                        letterSpacing: "0.8rem",
                        textAlign: "center",
                      }}
                      maskPlaceholder={null}
                      inputMode="numeric"
                    />

                    <ErrorMessage name="otp" component={Errormsg} />

                    <p className="resend-box-time d-flex justify-content-between align-items-center">
                      {count}s
                      {count < 1 && (
                        <span>
                          <CustomButton
                            bgcolor="transparent"
                            color={primaryColor}
                            padding="4px 8px"
                            width="100%"
                            type="button"
                            title="RESEND"
                            margin="auto"
                            fontSize="14px"
                            fontFamily="EnnVisionsMedium"
                            border="none"
                            loading={regenrateOtpLoader}
                            clicked={() =>
                              regenerateOtp({
                                phoneNumber: userPhoneNumber.toString(),
                              }).then(() => setCount(59))
                            }
                          />
                        </span>
                      )}
                    </p>

                    {error && <p className="text-danger mb-0">{error}</p>}
                    <div className="mt-3">
                      <CustomButton
                        bgcolor={primaryColor}
                        color="white"
                        padding="8px 8px"
                        width="100%"
                        type="submit"
                        title="NEXT"
                        margin="auto"
                        fontSize="16px"
                        loading={loading}
                      />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </VerifyOtpStyle>
    </>
  );
};

export default VerifyOtp;
