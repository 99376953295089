import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  BlackDot,
  lightGrey4,
  primaryColor,
  pureDark,
  TertiraryHeading,
  UnderlineRed,
  whiteColor,
} from "../../components/GlobalStyle";
import { PropertyInfoStyle } from "./style";
import Gallery from "./Gallery";
import FeatureDetails from "./FeatureDetails";
import CustomButton from "../../components/CustomButton/CustomButton";
import { Steps } from "antd";
import SellOrRent from "./SaleOrRent";
import MpacAdditionalDetails from "./MpacAdditionalDetails";
import Head from "../../components/Head/Head";
import { useGeneratePropetyContext } from "./CreateProperty";
import { usePropertyContext } from "../../context/PropertyContext";
import { useFormikContext } from "formik";
import { objectNotEmpty } from "../../utils/utilities";
import CustomModal from "../../components/Modal/CustomModal";
import EnnvisionModal from "../../components/CustomModals/EnnvisionModal";
import Rooms from "./Rooms";
import { UserOutlined } from "@ant-design/icons";
import CircleNumber from "./CircleNumber";

const PropertyInfo = () => {
  // const [isStatusSelected, setIsStatusSelected] = useState(false);
  const { isLoading } = useGeneratePropetyContext();
  const { errors, handleSubmit } = useFormikContext();
  const [IsError, setIsErrors] = useState(false);
  // mpac additional details
  const {
    mpacAdditionalDetailsData,
    setPropertyStatusId,
    propertyStatusId,
    isPropertyTypeSelected,
    setIsPropertyTypeSelected,
    generatePropertyNumber,
  } = usePropertyContext();
  // current step of property generate
  const [current, setCurrent] = useState(0);

  // generate property steps
  const steps =
    mpacAdditionalDetailsData &&
    Object.keys(mpacAdditionalDetailsData!).length !== 0
      ? [
          {
            title: "",
            content: 1,
          },
          {
            title: "",
            content: 2,
          },
          {
            title: "",
            content: 3,
          },
          {
            title: "",
            content: 4,
          },
          {
            title: "",
            content: 5,
          },
        ]
      : generatePropertyNumber === 1
      ? [
          {
            title: "",
            content: 1,
          },
          {
            title: "",
            content: 3,
          },
          {
            title: "",
            content: 4,
          },
        ]
      : [
          {
            title: "",
            content: 1,
          },
          {
            title: "",
            content: 2,
          },
          {
            title: "",
            content: 3,
          },
          {
            title: "",
            content: 4,
          },
        ];

  // next step handler
  const next = () => {
    setCurrent(current + 1);
  };

  // previous step handler
  const prev = () => {
    setCurrent(current - 1);
  };

  const handleCustomSubmit = () => {
    if (objectNotEmpty(errors)) {
      setIsErrors(true);
      setTimeout(() => {
        setIsErrors(false);
        setCurrent(0);
      }, 5000);
    } else {
      handleSubmit();
      // setIsStatusSelected(false);
    }
  };
  // mapping steps
  const items = steps.map((item, index) => ({
    key: item.title,
    title: item.title,
    icon: (
      <CircleNumber
        step={index + 1}
        isCurrent={index === current}
        color={index === current ? "white" : "#0D0E10"}
        border={index === current ? primaryColor : "#0D0E10"}
        bgColor={
          index === current
            ? primaryColor
            : index < current
            ? "#ececec"
            : "white"
        }
      />
    ),
  }));

  const componentSwitcher = (value: number) => {
    if (
      mpacAdditionalDetailsData &&
      Object.keys(mpacAdditionalDetailsData!).length !== 0
    ) {
      switch (value) {
        case 1:
          return <SellOrRent />;
        case 2:
          return <MpacAdditionalDetails />;
        case 3:
          return <Rooms />;
        case 4:
          return <FeatureDetails />;
        case 5:
          return <Gallery />;
        default:
          return <SellOrRent />;
      }
    } else if (generatePropertyNumber === 1) {
      switch (value) {
        case 1:
          return <SellOrRent />;
        case 3:
          return <FeatureDetails />;
        case 4:
          return <Gallery />;
        default:
          return <SellOrRent />;
      }
    } else {
      switch (value) {
        case 1:
          return <SellOrRent />;
        case 2:
          return <Rooms />;
        case 3:
          return <FeatureDetails />;
        case 4:
          return <Gallery />;
        default:
          return <SellOrRent />;
      }
    }
  };

  // on change for steps
  const onChange = (value: number) => {
    setCurrent(value);
  };

  // do task
  const doTask = () => {
    setIsErrors(false);
    setCurrent(0);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [current]);

  console.log(errors, "formik errors");

  return (
    <PropertyInfoStyle>
      <Head title="property-info" />
      <CustomModal
        isModalVisible={IsError}
        setIsModalVisible={setIsErrors}
        showCloseBtn={false}
        onCancel={() => {}}
      >
        <EnnvisionModal
          title="Oops! Something went wrong."
          description="Please enter a valid value in all the required fields before proceeding. Please see the fields marks red and fill information.  Once you have filled in all the details, click the ‘Next’ button to continue."
          doTask={doTask}
        />
      </CustomModal>
      <Container>
        <TertiraryHeading className="ms-1">
          Fill Property Information
        </TertiraryHeading>
        <div className="ms-1">
          <UnderlineRed />
          <BlackDot />
        </div>
        <div className="my-3 steps">
          <Steps onChange={onChange} current={current} items={items} />
        </div>
        <div className="mt-3">
          {componentSwitcher(steps[current].content)}
          <Row className="justify-content-end mt-3">
            {current > 0 && current < steps.length - 1 ? (
              <Col className="d-flex justify-content-end" md={4} xl={4}>
                <CustomButton
                  bgcolor={lightGrey4}
                  color={whiteColor}
                  padding="8px 8px"
                  width="100%"
                  type="button"
                  title="Skip"
                  margin="auto"
                  border="none"
                  fontSize="18px"
                  clicked={() => next()}
                />
              </Col>
            ) : (
              <Col md={4} xl={6} />
            )}
            <Col
              className="d-flex flex-column flex-sm-row justify-content-end mt-2 mt-md-0 gap-2"
              md={current < steps.length ? 4 : 8}
            >
              {/* {isPropertyTypeSelected && current < steps.length - 1 && ( */}
              {current < steps.length - 1 && (
                <CustomButton
                  bgcolor={primaryColor}
                  color={whiteColor}
                  padding="8px 8px"
                  width="100%"
                  type="button"
                  title="Next"
                  margin="auto"
                  border="none"
                  fontSize="18px"
                  clicked={() => next()}
                />
              )}

              {/* {current === steps.length - 1 && isStatusSelected && (
                <CustomButton
                  bgcolor={lightGrey4}
                  color={whiteColor}
                  padding="10px"
                  width="100%"
                  type="button"
                  title={
                    propertyStatusId === 2
                      ? "Save as Draft"
                      : "Submit for Review"
                  }
                  margin="auto"
                  border="none"
                  fontSize="16px"
                  clicked={handleCustomSubmit}
                />
              )} */}
              {current === steps.length - 1 && (
                <>
                  <CustomButton
                    bgcolor={lightGrey4}
                    color={pureDark}
                    padding="10px"
                    width="100%"
                    type="submit"
                    title="Save as Draft "
                    margin="auto"
                    border="none"
                    fontSize="16px"
                    clicked={() => {
                      if (!isPropertyTypeSelected) {
                       // handleCustomSubmit();
                        setPropertyStatusId(2);
                        setIsPropertyTypeSelected(true);
                        // setIsStatusSelected(true);
                        // next();
                      }
                    }}
                  />

                  <CustomButton
                    bgcolor={primaryColor}
                    color={whiteColor}
                    padding="10px"
                    width="100%"
                    type="button"
                    loading={isLoading}
                    title="Save & Review"
                    margin="auto"
                    fontSize="16px"
                    clicked={() => {
                      handleCustomSubmit();
                      setPropertyStatusId(3);
                      setIsPropertyTypeSelected(true);
                      // setIsStatusSelected(true);
                      // next();
                    }}
                  />
                </>
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </PropertyInfoStyle>
  );
};

export default PropertyInfo;
