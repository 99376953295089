import ExploreNearbyStyle from "./style";
import Suggestions from "./Suggestion";
import { Card, Col, Container, Row } from "react-bootstrap";
import Post from "./Post";
import Head from "../../components/Head/Head";
import { SetStateAction, useEffect, useRef, useState } from "react";
import {
  media_base_url,
  newsfeed_url,
  stories_url,
} from "../../utils/api_urls";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";
import { NewsFeedRecord } from "../../utils/types";
import ProperyPost from "./ProperyPost";
import { addUserStories } from "../../redux/features/newsfeed/storiesSlice";

// Modals
import SuggestionsModal from "../../components/Modals/SuggestionsModal";
import ShareModal from "../../components/Modals/ShareModal";
import CustomModal from "../../components/Modal/CustomModal";
import NewsfeedModal from "../../components/Modals/NewsFeedModal";
import { Story } from "../Pages";
import { BackgroundImage } from "../../components/GlobalStyle";
import NoDataFound from "../../components/NoData/NoDataFound";
import { StoriesRecord, storyDataTypes } from "./types";
import { useGlobalContext } from "../../context/context";
import MyPostOptions from "../../components/PostOptions/MyPost";
import OtherPostOptions from "../../components/PostOptions/OtherPost";
import { useNewsfeedContext } from "../../context/NewsfeedContext";
import CommentsModal from "../../components/Modals/Comments/CommentsModal";
import LikesModal from "../../components/Modals/LikesModal";
import SocialOptions from "../../components/PostOptions/Social";
import OthersStoryOptions from "../../components/PostOptions/OtherStory";
import MyStoryOptions from "../../components/PostOptions/MyStory";
import MyPropertyOptions from "../../components/PostOptions/MyProperty";
import OtherPropertyOptions from "../../components/PostOptions/OtherProperty";
import StorySkeleton from "../../components/Skeletons/Stories";
import NewsfeedSkeleton from "../../components/Skeletons/NewsfeedPosts";
import profile_placeholder from "../../assets/icons/ic_pro_placeholder.svg";
import useMediaArea from "../../hooks/useMediaArea";

const ExploreNearby = () => {
  const newFeedToastId = useRef<any>(null);
  const storiesToastId = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [storiesLoading, setStoriesLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [storiesPage, setStoriesPage] = useState(0);
  const [error, setError] = useState("");
  const [storiesError, setStoriesError] = useState("");
  const [storiesData, setStoriesData] = useState({} as storyDataTypes);
  const dispatch = useAppDispatch();
  const [isShowSuggestion, setIsShowSugesstions] = useState(false);
  const [isShowShare, setIsShowShare] = useState(false);
  const { width } = useMediaArea();
  
  // login data
  const { data } = useAppSelector((state: RootState) => state.loginData);

  const { counter } = useGlobalContext();
  const {
    newsfeedData,
    setNewsfeedData,
    isShowComments,
    setIsShowComments,
    isShowLikes,
    setIsShowLikes,
    selectedPost,
    setSelectedPost,
    isShowStories,
    setIsShowStories,
    showSocialOptions,
    setShowSocialOptions,
    showPostOptions,
    setShowPostOptions,
    showStoryOptions,
    setShowStoryOptions,
    selectedStories,
    showPropertyOptions,
    setShowPropertyOptions,
  } = useNewsfeedContext();
  
      const { setUserStoriesData, setTotalUserStoriesLength, setSlideIndex } =
    useGlobalContext();
  const loginData = useAppSelector((state) => state.loginData.data);

  // get stories promise
  const getStoriesPromise = async () => {
    try {
      setStoriesLoading(true);
      const { data: storiesData } = await axios(stories_url + +storiesPage, {
        headers: {
          Authorization: `Bearer ${data?.jwtDetails.token}`,
        },
      });
      setStoriesData(storiesData.results);
      dispatch(addUserStories(storiesData.results));
      setStoriesLoading(false);

      // storiesToastId.current = toast("got stories successfully", {
      //   type: "success",
      // });
    } catch (error: any) {
      setStoriesError(error.response.data.responseMessage);
      setStoriesLoading(false);
      storiesToastId.current = toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };

  // newsfeed promise
  const getNewsFeedPromise = async () => {
    try {
      setLoading(true);
      const { data: newsfeedData } = await axios(newsfeed_url + +page, {
        data: {
          userId: data?.userDetails?.id,
        },
        headers: {
          Authorization: `Bearer ${data?.jwtDetails.token}`,
        },
      });
      setNewsfeedData(newsfeedData.results);
      setLoading(false);

      // newFeedToastId.current = toast("got newfeed successfully", {
      //   type: "success",
      // });
    } catch (error: any) {
      setError(error.response.data.responseMessage);
      setLoading(false);
      newFeedToastId.current = toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };

  console.log({ newsfeedData });
  // navigate to stories
  const navigateToStoriesModal = (storyData: StoriesRecord) => {
    setUserStoriesData(storyData);
    setSlideIndex(0);
    setIsShowStories(true);
  };

  // handle stories & newsfeed handler
  useEffect(() => {
    getStoriesPromise();
    getNewsFeedPromise();
  }, [counter]);

  // show suggestion handler
  const showSuggestionsHandler = () => {
    setIsShowSugesstions(true);
  };

  // useEffect(() => {
  //   if (loading) {
  //     window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }
  // }, [loading]);
  // selected post handler
  const selectedPostHandler = (postData: NewsFeedRecord, post_type: string) => {
    console.log({ post_type });
    setSelectedPost(postData);
    if (post_type === "comment") {
      setIsShowComments(true);
    } else if (post_type === "like") {
      setIsShowLikes(true);
    } else if (post_type === "post_options") {
      setShowPostOptions(true);
    } else if (post_type === "property_options") {
      setShowPropertyOptions(true);
    } else if (post_type === "social") {
      setShowSocialOptions(true);
    }
  };
  console.log({ selectedStories, selectedPost });
  // make overflow hidden when slider shows up
  // useEffect(() => {
  //   if (
  //     isShowStories ||
  //     isShowComments ||
  //     isShowLikes ||
  //     showPropertyOptions ||
  //     showStoryOptions
  //   ) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }
  // }, [
  //   isShowStories,
  //   isShowComments,
  //   isShowLikes,
  //   showPropertyOptions,
  //   showStoryOptions,
  // ]);

  console.log({ newsfeedData }, "newsfeed ids");
  const onCancel = () => {};
    return (
    <ExploreNearbyStyle>
      <Head title="nearby-places" />
      {/* Modals */}
      <CustomModal
        isModalVisible={isShowStories}
        setIsModalVisible={setIsShowStories}
        onCancel={onCancel}
        width="100%"
      >
        <div className="modalWrap">
          <Story setIsShowStories={setIsShowStories} />
        </div>
      </CustomModal>
      {isShowComments && (
        <CommentsModal
          isModalVisible={isShowComments}
          setIsModalVisible={setIsShowComments}
          readComments={selectedPost.readComments}
          turnOffCommenting={selectedPost.turnOffCommenting}
          newsFeedId={selectedPost.newsFeedId}
          title="Post Comments"
        />
      )}
      {isShowLikes && (
        <LikesModal
          isShowLikeModal={isShowLikes}
          setIsShowLikeModal={setIsShowLikes}
          newsfeedId={selectedPost.newsFeedId}
        />
      )}
      <CustomModal
        isModalVisible={isShowSuggestion}
        setIsModalVisible={setIsShowSugesstions}
      >
        <NewsfeedModal
          title="Suggestions For You"
          closeHandler={() => setIsShowSugesstions(false)}
        >
          <SuggestionsModal setIsShowSugesstions={setIsShowSugesstions} />
        </NewsfeedModal>
      </CustomModal>
      <CustomModal
        isModalVisible={isShowShare}
        setIsModalVisible={setIsShowShare}
      >
        <ShareModal />
      </CustomModal>
      {/* property options */}
      {showPropertyOptions && (
        <CustomModal
          isModalVisible={showPropertyOptions}
          setIsModalVisible={setShowPropertyOptions}
          showCloseBtn={false}
        >
          {selectedPost?.ownerData?.id === data?.userDetails?.id ? (
            <MyPropertyOptions />
          ) : (
            <OtherPropertyOptions />
          )}
        </CustomModal>
      )}

      {/* post options */}
      {showPostOptions && (
        <CustomModal
          isModalVisible={showPostOptions}
          setIsModalVisible={setShowPostOptions}
          showCloseBtn={false}
        >
          {selectedPost?.ownerData?.id === data?.userDetails?.id ? (
            <MyPostOptions />
          ) : (
            <OtherPostOptions />
          )}
        </CustomModal>
      )}
      {showSocialOptions && (
        <CustomModal
          isModalVisible={showSocialOptions}
          setIsModalVisible={setShowSocialOptions}
          showCloseBtn={false}
        >
          <SocialOptions postData={selectedPost} />
        </CustomModal>
      )}
      {/* show story options */}
      {showStoryOptions && (
        <CustomModal
          isModalVisible={showStoryOptions}
          setIsModalVisible={setShowStoryOptions}
          showCloseBtn={false}
        >
          {selectedStories?.ownerData?.id === loginData?.userDetails?.id ? (
            <MyStoryOptions />
          ) : (
            <OthersStoryOptions />
          )}
        </CustomModal>
      )}
      <Container>
        <Row>
          <Col md={0} lg={0} xl={1} />
          <Col md={12} lg={7} xl={6} className="mt-3 order-1 order-md-0">
            {error && storiesError ? (
              <NoDataFound />
            ) : storiesLoading ? (
              <StorySkeleton />
            ) : (
              Object.keys(storiesData).length !== 0 && (
                <div className="d-flex stories-list">
                  {storiesData.storiesRecords.map((storyData, index) => {
                    const {
                      ownerData: { profilePicture, firstName, lastName },
                      storyDTOList,
                    } = storyData;
                    return (
                      <div
                        onClick={() => navigateToStoriesModal(storyData)}
                        key={index}
                      >
                        <div id="overlay">
                          <Card className="story-card">
                            <BackgroundImage
                              key={index}
                              className="card-img-top"
                              repeat="no-repeat"
                              url={
                                profilePicture
                                  ? media_base_url + profilePicture
                                  : profile_placeholder
                              }
                              size="cover"
                              postion="center"
                              height={width > 1024 ? "144px" : "130px"}
                              width={width > 1024 ? "100px" : "90px"}
                            />
                            <Card.Body className="position-absolute bottom-0 p-2">
                              <Card.Title className="mb-0">
                                {firstName} {lastName}
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )
            )}

            <>
              {loading ? (
                <NewsfeedSkeleton />
              ) : (
                !error && (
                  <>
                    {newsfeedData?.newsFeedRecords?.map((postData, index) =>
                      postData.postType === "PROPERTY" ? (
                        <ProperyPost
                          key={index}
                          postData={postData}
                          selectedPostHandler={selectedPostHandler}
                                                  />
                      ) : (
                        <Post
                          key={index}
                          postData={postData}
                          selectedPostHandler={selectedPostHandler}
                                                  />
                      )
                    )}
                  </>
                )
              )}
            </>
          </Col>
          <Col md={0} lg={1} xl={1} />
          <Col
            md={0}
            lg={4}
            xl={3}
            className="mt-3 order-0 order-md-1 d-none d-lg-block"
          >
            <Suggestions />
          </Col>
          <Col md={0} lg={0} xl={1} />
        </Row>
      </Container>
          </ExploreNearbyStyle>
  );
};

export default ExploreNearby;
