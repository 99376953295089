import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { pureDark, whiteColor } from "../GlobalStyle";

const ProfessionalsSkeleton = () => {
  return (
    <Wrapper>
      <Row>
        {Array.from({ length: 4 }).map((_, index) => (
          <Col sm={6} md={4} lg={3} key={index} className="professional">
            <Skeleton height={240} />
            <div className="d-flex justify-content-center">
              <div className="detail-text">
                <h6 className="name">
                  <Skeleton width="86%" className="mt-2" />
                </h6>
                {/* <p className="profession">{emailAddress}</p> */}
                <p className="profession">
                  <Skeleton width="60%" />
                </p>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Wrapper>
  );
};

export default ProfessionalsSkeleton;

const Wrapper = styled.div`
  .professional {
    margin-top: 30px;
    .detail-text {
      margin-top: -30px;
      background: ${whiteColor};
      box-shadow: 0px 0px 15px #0000001c;
      border-radius: 6px;
      width: 94%;
      text-align: center;
      padding: 9px 0;
      z-index: 10;
      .name {
        font-size: 20px;
        color: ${pureDark};
        font-family: "EnnVisionsMedium";
      }
      .profession {
        font-size: 15px;
        color: ${pureDark};
        margin: 0;
      }
    }
  }
`;
