import {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";

type authContext = {
  isLoggedIn: boolean;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
  isUser: boolean;
  setIsUser: Dispatch<SetStateAction<boolean>>;
  resetPasswordToken: string;
  setResetPasswordToken: Dispatch<SetStateAction<string>>;
};

const AuthContext = createContext({} as authContext);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [resetPasswordToken, setResetPasswordToken] = useState("");
  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        isUser,
        setIsUser,
        resetPasswordToken,
        setResetPasswordToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// make sure use
const useAuthContext = () => {
  return useContext(AuthContext);
};

export { useAuthContext, AuthProvider };
