import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { BlackDot, TertiraryHeadingMini } from "../../components/GlobalStyle";
import RedUnderline from "../../components/RedBlackUnderline/RedUnderline";
import ResourcesList from "../professional/ProfessionalProfile/ResourcesList";
import RecentSources from "./RecentSources";
import ResourcesCategory from "./ResourcesCategory";
import ShowCase from "./ShowCase";
import ResourcesTags from "./Tags";
import TextWidget from "./TextWidget";
import HeroSection from "./HeroSection";

const Resources = () => {
  return (
    <div className="pb-3">
      <Helmet>
        <title>Resources</title>
      </Helmet>
      <HeroSection />
      <Container>
        <Row className="mt-4">
          <Col md={12}>
            {/* <ShowCase /> */}
            <ResourcesList sm={12} md={6} lg={4} />
          </Col>
          {/* <Col md={5}>
            <Heading title="Recent Resources" />
            <RecentSources />
            <Heading title="Text Widget" />
            <TextWidget />
            <Heading title="Resources Tags" />
            <ResourcesTags />
            <Heading title="Resources Category" />
            <ResourcesCategory />
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default Resources;

const Heading = ({ title }: any) => {
  return (
    <div className="mt-3">
      <TertiraryHeadingMini>{title}</TertiraryHeadingMini>
      <div className="d-flex">
        <RedUnderline />
        <BlackDot />
      </div>
    </div>
  );
};
