import { Link } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import { ChooseUsItemStyle, WhyChooseUsStyled } from "./style";
import whyChooseBanner from "../../assets/images/ic_services_img.png";
import serviceIcon1 from "../../assets/icons/ic_services_1.svg";
import serviceIcon2 from "../../assets/icons/ic_services_2.svg";
import serviceIcon3 from "../../assets/icons/ic_services_3.svg";
import serviceIcon4 from "../../assets/icons/ic_services_4.svg";
import { BlackDot, UnderlineRed } from "../../components/GlobalStyle";
import { Container } from "react-bootstrap";
import { useState } from "react";
import CustomModal from "../../components/Modal/CustomModal";

const whyChooseUsData = [
  {
    title: "  List Your Property",
    sub_title: "Sell or rent your property",
    sub_title1: "without paying realtor fees",
    img: serviceIcon2,
  },
  {
    title: " Find a Home",
    sub_title: " A smarter, easier way to",
    sub_title1: "search homes and rentals",
    img: serviceIcon3,
  },
  {
    title: "Get Help From The Pros",
    sub_title: "Find a professional to help",
    sub_title1: "you sell your home",
    img: serviceIcon4,
  },
  {
    title: "Engage The Audience",
    sub_title: "Search thousands of house",
    sub_title1: "and apartments in your area",
    img: serviceIcon1,
  },
];

const whyChooseUsDetails = [
  {
    title: "Create your listing with our easy to use features",
    sub_title:
      "Creating a listing for the sale or rental of your property has never been easier with our user-friendly platform. Our advanced tools help you determine the market value of your home, ensuring you get the best price. Add high-quality images and videos to showcase your property, attracting potential buyers or renters. With HouseUp, you have complete control over your listing, making the process efficient and cost-effective. Save money and own the way you sell or rent your home with HouseUp.ca",
    img: serviceIcon2,
  },
  {
    title:
      " Update to:Discover your dream home with ease on HouseUp.ca. Browse listings, contact sellers, book viewings, and save on fees by finalizing offers with your own lawyer.",
    sub_title:
      " Finding a home to buy or rent is simple and cost-effective with HouseUp.ca. Browse through our extensive listings to find your perfect match, contact sellers directly, and easily book viewings. Work with your own lawyer to finalize the offer, eliminating large fees and saving you money. HouseUp.ca makes the home-finding process seamless and affordable.",
    img: serviceIcon3,
  },
  {
    title:
      "Browse our network of local professionals that offer a wide variety of services and solutions to all our members.",
    sub_title:
      "First impressions matter. By taking the time to prepare your home for sale, you can increase its appeal and improve your chances of a successful sale ",
    img: serviceIcon4,
  },
  {
    title:
      "Join our growing community of professionals on HouseUp to connect with the people who could use your services most.",
    sub_title:
      "Join HouseUp.ca and post and promote your Listing, Post, Special Offers and reach all our users.Whether you provide services for those looking to sell or recently moved to a new home, or you've listed your property on HouseUp.ca and what to share it with its users.HouseUp and reach thousands of potential clients. It’s an easy way to reach a qualified audience and help build your business by helping our users sell their homes. ",
    img: serviceIcon3,
  },
];

const WhyChooseUs = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedChooseIndex, setSelectedChooseIndex] = useState(0);

  const responsive = {
    0: { items: 1.5 },
    568: { items: 2.5 },
    768: { items: 3 },
    1024: { items: 4 },
  };
  const items = [
    ...whyChooseUsData.map(
      ({ title, sub_title, sub_title1, img }: any, index: number) => (
        <div key={index} className="pxp-services-container rounded-lg p-0">
          <div
            onClick={() => {
              setSelectedChooseIndex(index);
              setShowModal(true);
            }}
            className="pxp-services-item"
          >
            <div className="pxp-services-item-fig">
              <img src={img} alt="properties" />
            </div>
            <div className="pxp-services-item-text text-center">
              <div className="pxp-services-item-text-title">{title}</div>
              <div className="pxp-services-item-text-sub">
                {sub_title}
                <br />
                {sub_title1}
              </div>
            </div>
          </div>
        </div>
      )
    ),
  ];

  return (
    <WhyChooseUsStyled>
      <CustomModal
        isModalVisible={showModal}
        setIsModalVisible={setShowModal}
        showCloseBtn={false}
      >
        <ChooseUsItemStyle>
          <h6>{whyChooseUsDetails[selectedChooseIndex].title}</h6>
          <p>{whyChooseUsDetails[selectedChooseIndex].sub_title}</p>
        </ChooseUsItemStyle>
      </CustomModal>
      <div
        className="pxp-services pxp-cover mt-100 pt-100 mb-200"
        style={{ backgroundImage: `url(${whyChooseBanner})` }}
      >
        <h2 className="text-center pxp-section-h2">Why Choose Us</h2>
        {/* <p className="pxp-text-light text-center">
          Take Control Of The Most Important Decision Of Your Life
        </p> */}
        <div className="d-flex justify-content-center">
          <UnderlineRed />
          <BlackDot />
        </div>
        <Container>
          <div className="cards mt-4 mt-md-5">
            <AliceCarousel
              mouseTracking
              items={items}
              responsive={responsive}
              controlsStrategy="alternate"
              disableDotsControls
              disableButtonsControls
            />
          </div>
        </Container>
      </div>
    </WhyChooseUsStyled>
  );
};

export default WhyChooseUs;
