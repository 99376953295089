import { Col, Row } from "react-bootstrap";
import CustomButton from "../../components/CustomButton/CustomButton";
import { lightGrey, primaryColor } from "../../components/GlobalStyle";
import { BedroomFilterStyle } from "./style";
import { useSearchContext } from "../../context/SearchContext";
import { useState } from "react";

export const bedroomFilterItems = [
  { isSelect: true, beds: "Any" },
  { isSelect: false, beds: "1" },
  { isSelect: false, beds: "2" },
  { isSelect: false, beds: "3" },
  { isSelect: false, beds: "4" },
  { isSelect: false, beds: "5" },
  { isSelect: false, beds: "6" },
  { isSelect: false, beds: "7" },
  { isSelect: false, beds: "8" },
  { isSelect: false, beds: "9" },
  { isSelect: false, beds: "10" },
];
const BedroomFilters = () => {
  const {
    setShowBedrooms,
    setFilterCriteria,
    setSearchCriteria,
    searchCriteria,
    bedroomItems,
    setBedroomItems,
  } = useSearchContext();

  const borderToggler = (isSelect: boolean) => {
    if (isSelect) {
      return `2px solid ${primaryColor}`;
    }
    return `2px solid ${lightGrey}`;
  };

  const bedroomsSelectionToggler = (index: number) => {
    const oldItems = [...bedroomItems];

    oldItems.map((itemData) => ({
      ...itemData,
      isSelect: false,
    }));

    const newData = oldItems.map((item) => ({
      ...item,
      isSelect: false,
    }));
    newData[index].isSelect = true;
    setBedroomItems(newData);
  };
  console.log({ bedroomItems });
  return (
    <BedroomFilterStyle>
      <h6>Bedrooms</h6>
      <Row className="d-flex align-items-center">
        {bedroomItems.map(({ beds, isSelect }, index) => (
          <Col md={3} key={index}>
            <button
              style={{
                border: borderToggler(isSelect),
              }}
              key={index}
              className="w-100 mt-2"
              onClick={() => bedroomsSelectionToggler(index)}
            >
              {beds}
            </button>
          </Col>
        ))}
      </Row>
      <div className="d-flex gap-2 mt-3">
        <CustomButton
          bgcolor={lightGrey}
          color="white"
          padding="8px"
          width="50%"
          type="submit"
          title="Cancel"
          margin="auto"
          fontFamily="EnnVisionsMedium"
          fontSize="16px"
          clicked={() => {
            const oldSearchCreteria = searchCriteria.filter(
              ({ filterKey }) => filterKey !== "bedRoom"
            );
            setSearchCriteria(oldSearchCreteria);
            setBedroomItems(
              bedroomItems.map((item) => ({
                ...item,
                isSelect: false,
              }))
            );
            setShowBedrooms(false);
          }}
        />
        <CustomButton
          bgcolor={primaryColor}
          color="white"
          padding="8px"
          width="50%"
          type="submit"
          title="Apply"
          margin="auto"
          fontFamily="EnnVisionsMedium"
          fontSize="16px"
          clicked={() => {
            const selectedBathroom = bedroomItems.find(
              ({ isSelect }) => isSelect === true
            );
            if (selectedBathroom?.beds === "Any") {
              setBedroomItems(
                bedroomItems.map((item) => ({
                  ...item,
                  isSelect: false,
                }))
              );
              const newSearchCreteria = searchCriteria.filter(
                ({ filterKey }) => filterKey !== "bedRoom"
              );
              setSearchCriteria(newSearchCreteria);
            } else if (selectedBathroom) {
              setFilterCriteria("bedRoom", +selectedBathroom.beds);
            }
            setShowBedrooms(false);
          }}
        />
      </div>
    </BedroomFilterStyle>
  );
};

export default BedroomFilters;
