import { Col, Row } from "react-bootstrap";
import Headbar from "../../components/Headbar/Headbar";
import { GeneralInfoStyle } from "./style";
import FormControl from "../../components/FormControl";
import { FormikErrors, useFormikContext } from "formik";
import plusIcon from "../../assets/icons/ic_maximum.svg";
import minusIcon from "../../assets/icons/ic_minimum.svg";
import InputField from "../../components/InputField/InputField";
import { borderErrorToggler } from "./MpacAdditionalDetails";
import { useEditPropetyContext } from "./EditProperty";
import { lightGrey2, primaryColor } from "../../components/GlobalStyle";
import moment from "moment";

// Define the type for the errors object
type PropertyInfoErrors = FormikErrors<any>;

const GeneralInfo = () => {
  const { setFieldValue, errors, touched, values }: any =
    useFormikContext<PropertyInfoErrors>();
  const { propertyGeneralInfo, setPropertyGeneralInfo } =
    useEditPropetyContext();
  // for add or remove value in general info of property

  const additionalInputsHandler = (action: string, index: number) => {
    const oldPropertyGeneralInfo = [...propertyGeneralInfo];
    const selectedPropertyInfo = oldPropertyGeneralInfo.find(
      ({ id }) => id === index
    );
    if (!selectedPropertyInfo) {
      return;
    }
    if (action == "add") {
      selectedPropertyInfo.value = selectedPropertyInfo.value + 1;
    } else {
      if (selectedPropertyInfo.value == 0) {
        return 0;
      }
      selectedPropertyInfo.value = +selectedPropertyInfo.value - 1;
    }
    setPropertyGeneralInfo(oldPropertyGeneralInfo);
  };

  const currentYear = moment().year(); // Get the current year

  // Disable years greater than the current year

  const disabledDateYear = (date: moment.Moment | null) => {
    if (!date) return false; // Allow null date selection

    const selectedYear = date.year();
    return selectedYear > currentYear; // Disable years greater than the current year
  };

  return (
    <GeneralInfoStyle>
      <Headbar title="Property General Info" />
      <Row>
        <Col className="input-section" sm={6} md={4}>
          <FormControl
            control="year"
            type="text"
            padding="8px"
            name="yearBuilt"
            disabledDateYear={disabledDateYear}
            border={borderErrorToggler(
              errors?.propertyGeneralInfo?.actualYearBuilt
            )}
            placeholder="Year Built"
            labelFamily="EnnVisionsMedium"
            marginBottom="0px"
            showErroMessage={false}
            value={values.propertyGeneralInfo.actualYearBuilt}
            onChange={(value: string) =>
              setFieldValue("propertyGeneralInfo.actualYearBuilt", value)
            }
            className={
              errors.actualYearBuilt && touched.actualYearBuilt
                ? "is-invalid"
                : "customInput"
            }
          />
        </Col>
        <Col className="input-section" sm={6} md={4}>
          <FormControl
            control="year"
            type="text"
            padding="8px"
            showErroMessage={false}
            name="yearFurnished"
            disabledDateYear={disabledDateYear}
            marginBottom="0px"
            value={values.propertyGeneralInfo.furnishedYear!}
            border={borderErrorToggler(
              errors?.propertyGeneralInfo?.furnishedYear
            )}
            placeholder="Year Furnished"
            onChange={(value: string) =>
              setFieldValue("propertyGeneralInfo.furnishedYear", value)
            }
            labelFamily="EnnVisionsMedium"
            className={
              errors.furnishedYear && touched.furnishedYear
                ? "is-invalid"
                : "customInput"
            }
          />
        </Col>

        {propertyGeneralInfo.length !== 0 &&
          propertyGeneralInfo
            .slice(2)
            .map(({ keyName, displayName, maxRange, value, id }) => (
              <Col
                key={id}
                className="input-section d-flex align-items-center mt-2"
                sm={6}
                md={4}
              >
                <img
                  src={minusIcon}
                  className="arithmetic-icon"
                  alt="minus-icon"
                  onClick={() => {
                    if (
                      values["propertyGeneralInfo"]["others"][keyName] &&
                      values["propertyGeneralInfo"]["others"][keyName] > 0
                    ) {
                      setFieldValue(
                        `propertyGeneralInfo.others.${keyName}`,
                        values["propertyGeneralInfo"]["others"][keyName] - 1
                      );
                    }
                  }}
                />
                <InputField
                  maxRange={maxRange}
                  border={
                    errors?.propertyGeneralInfo?.others?.[keyName]
                      ? `1px solid ${primaryColor}`
                      : `1px solid ${lightGrey2}`
                  }
                  title={displayName}
                  value={
                    values["propertyGeneralInfo"]["others"][keyName]
                      ? values["propertyGeneralInfo"]["others"][keyName]
                      : 0
                  }
                  name={keyName}
                  onChange={() => {
                    // setFieldValue(keyName, event?.target.value);
                  }}
                />
                <img
                  src={plusIcon}
                  className="arithmetic-icon"
                  alt="plus"
                  onClick={() => {
                    setFieldValue(
                      `propertyGeneralInfo.others.${keyName}`,
                      values["propertyGeneralInfo"]["others"][keyName]
                        ? values["propertyGeneralInfo"]["others"][keyName] + 1
                        : 0 + 1
                    );
                  }}
                />
              </Col>
            ))}
      </Row>
    </GeneralInfoStyle>
  );
};

export default GeneralInfo;
