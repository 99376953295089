import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";
// import Slider from '@material-ui/core/Slider'
// import Button from '@material-ui/core/Button'
// import Typography from '@material-ui/core/Typography'
// import ImgDialog from './ImgDialog'
// import { getCroppedImg } from './canvasUtils'
import { Point, Area } from "react-easy-crop/types";
import getCroppedImg from "./getCroppedImg";
import { Dropdown, Slider } from "antd";
import ReactImagesCropStyle, { ZoomStyle } from "./style";
import { AiOutlineZoomIn } from "react-icons/ai";
import { HiChevronUpDown } from "react-icons/hi2";
import AsceptRatio from "./AsceptRatio";
import PrevBtn from "../components/Custom/PrevBtn";
import NextBtn from "../components/Custom/NextBtn";
import CustomButton from "../components/CustomButton/CustomButton";
import { primaryColor } from "../components/GlobalStyle";
import { usePostContext } from "../context/PostContext";
import { useNavigate } from "react-router-dom";
import { objectNotEmpty } from "../utils/utilities";

export const ReactImagesCrop: React.FC = () => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [rotation, setRotation] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);

  const [asceptRatio, setAsceptRatio] = useState(4 / 3);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { croppedImages, setCroppedImages, postImages, setPostImages } =
    usePostContext();
  const navigate = useNavigate();

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  console.log({ croppedAreaPixels, postImages });

  useEffect(() => {
    const showSelectedImage = async () => {
      if (postImages.length > 0) {
        try {
          const croppedImage = await getCroppedImg(
            postImages[currentIndex],
            croppedAreaPixels,
            rotation
          );
          console.log("donee", { croppedImage });
          setCroppedImages((prevItems) => {
            const updatedItems = [...prevItems];
            if (croppedImage) {
              updatedItems[currentIndex] = croppedImage;
            }
            return updatedItems;
          });
        } catch (e) {
          console.error(e);
        }
      }
    };
    console.log("effect running");

    showSelectedImage();
  }, [croppedAreaPixels, postImages]);

  console.log({ croppedImages });

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileList = Array.from(e.target.files);
      console.log({ fileList });
      const imageUrls = await Promise.all(
        fileList.map((file) => readFile(file))
      );
      console.log({ imageUrls });
      setPostImages(imageUrls);
    }
  };

  const ZoomHandler = () => {
    return (
      <ZoomStyle>
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(value) => setZoom(value)}
        />
      </ZoomStyle>
    );
  };

  // ascept ratio toggler
  const ratioToggler = (ratio: number) => {
    setAsceptRatio(ratio);
  };

  useEffect(() => {
    if (postImages.length < 1) {
      navigate("/create-post-type");
    }
  }, []);

  return (
    <ReactImagesCropStyle>
      {postImages.length > 0 ? (
        <div className="App">
          <div className="crop-container">
            <Cropper
              image={postImages[currentIndex]}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={asceptRatio}
              onCropChange={setCrop}
              style={{
                containerStyle: {
                  background: "transparent",
                  overflow: "unset",
                },
              }}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="controls">
            <div className="d-flex gap-2 align-items-center">
              <Dropdown
                dropdownRender={() => <ZoomHandler />}
                trigger={["click"]}
                placement="topCenter"
              >
                <AiOutlineZoomIn className="fs-1 cursor-pointer bg-dark text-light rounded-circle p-1" />
              </Dropdown>
              <Dropdown
                placement="topCenter"
                dropdownRender={() => (
                  <AsceptRatio ratioToggler={ratioToggler} />
                )}
                trigger={["click"]}
              >
                <HiChevronUpDown className="fs-1 cursor-pointer bg-dark text-light rounded-circle p-1" />
              </Dropdown>
              <div className="next-btn-section">
                {currentIndex < postImages.length - 1 ? (
                  <CustomButton
                    bgcolor={primaryColor}
                    color="white"
                    padding="8px"
                    width="160px"
                    type="button"
                    title="Next"
                    margin="auto"
                    textTransform="Captilize"
                    fontFamily="EnnVisionsMedium"
                    fontSize="16px"
                    clicked={() => setCurrentIndex(currentIndex + 1)}
                  />
                ) : (
                  <CustomButton
                    bgcolor={primaryColor}
                    color="white"
                    padding="8px"
                    width="160px"
                    type="button"
                    title="Submit"
                    margin="auto"
                    textTransform="Captilize"
                    fontFamily="EnnVisionsMedium"
                    fontSize="16px"
                    clicked={() => navigate("/create-post-images")}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="upload-images-section">
          <div className="d-flex justify-content-center align-items-center">
            <label htmlFor="upload-photos">Select from computer</label>
            <input
              type="file"
              name="photos"
              id="upload-photos"
              multiple
              onChange={onFileChange}
              // accept="image/*"
            />
          </div>
        </div>
      )}
    </ReactImagesCropStyle>
  );
};

function readFile(file: File): Promise<string> {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => resolve(reader.result as string),
      false
    );
    reader.readAsDataURL(file);
  });
}
