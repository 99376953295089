import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import placeholder from "../../assets/icons/ic_use_placeholder.svg";
import { Dropdown } from "antd";
import { useAppSelector } from "../../app/hooks";
import dummyUser from "../../assets/icons/user.png"
import store,{ RootState } from "../../redux/store";
import { useEffect, useState } from "react";
import { media_base_url } from "../../utils/api_urls";
import createIcon from "../../assets/icons/ic_create.svg";
import CreateOptions from "../PostOptions/CreateOptions";
import UpgradeAccountMenu from "../DropDownMenu/UpgradeAccountMenu";
import BecomeProfesionalMenu from "../DropDownMenu/BecomeProfessionalMenu";
import { mediaDeviceMax, primaryColor } from "../GlobalStyle";
import { getUseDetails } from "../../redux/features/user/userDetailsSlice";
import CustomButton from "../CustomButton/CustomButton";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useGlobalContext } from "../../context/context";
import ViewProfileTourGuide from "../TourGuide/ViewProfile";
import SidebarTourGuide from "../TourGuide/Sidebar";

import ViewProfessionalTourGuide from "../TourGuide/Professional";
import { FaUser } from "react-icons/fa";

type NavMenuProps = {
  changeNavColor: boolean;
};
const NavMenu: React.FC<NavMenuProps> = ({ changeNavColor }) => {
  const navigate = useNavigate();
  const {
    setHomeUnAuthAddProperty,
    selectedTourGuide,
    userTourGuideData,
    professionalTourGuideData,
  } = useGlobalContext();
  const loginUser = useAppSelector((state: RootState) => state.loginData.data);
  const isProfessional = loginUser?.ProfessionalDetails;
  const [isShowProOptions, setIsShowProOptions] = useState(false);
  const [isShowUpgradeOptions, setIsShowUpgradeOptions] = useState(false);
  const [showCreateOptions, setShowCreateOptions] = useState(false);
  
  useEffect(() => {
    loginUser && store.dispatch(getUseDetails(loginUser.userDetails.id));
  }, []);
  const userDetails = useAppSelector(
    (state: RootState) => state.userDetails.result.profilePicture
  );


  return (
    // show menu profile if user is logged in or not
    <NavMenuStyle>
      {
        loginUser ? (loginUser?.ProfessionalDetails ? (
          <CustomButton
            bgcolor={primaryColor}
            color="white"
            padding="4px 4px"
            width="100%"
            type="button"
            title="Upgrade Account"
            margin="auto"
            fontFamily="EnnVisionsMedium"
            fontSize="16px"
            clicked={() => {
              navigate('/professional-plans');
            }}
          />
        ) : (
          <CustomButton
            bgcolor={primaryColor}
            color="white"
            padding="3px 1px"
            width="100%"
            type="button"
            title="Become a Prossfessional"
            margin="auto"
            fontFamily="EnnVisionsMedium"
            fontSize="16px"
            clicked={() => {
              navigate('/become-professional');
            }}
          />
        )) : null
      }
      {loginUser ? (
        <div className="d-flex align-items-center position-relative">
          {loginUser.ProfessionalDetails ? (
            <Dropdown
              open={
                // isProfessional &&
                // professionalTourGuideData[selectedTourGuide].isOpen &&
                // selectedTourGuide > 0
                //   ? true
                //   :
                showCreateOptions
              }
              className="me-2"
              onOpenChange={(open) => setShowCreateOptions(open)}
              dropdownRender={() => (
                <CreateOptions setShowCreateOptions={setShowCreateOptions} />
              )}
              trigger={["click"]}
            >
              <div className="mb-4 me-5">
                <img
                  src={createIcon}
                  className="position-absolute cursor-pointer mb-4 me-5 plus-icon"
                  alt="plus"
                />
              </div>
            </Dropdown>
          ) : (
            <div className="me-4 position-relative">
              <CustomButton
                bgcolor={primaryColor}
                color="white"
                padding="4px 12px"
                border="none"
                width="100%"
                textTransform="Capitalize"
                type="submit"
                title="Add Property"
                margin="auto"
                fontFamily="EnnVisionsMedium"
                fontSize="14px"
                clicked={() => {
                  navigate("/create-property");
                }}
              />
              <div className="position-absolute">
                {!isProfessional && selectedTourGuide === 2 && (
                  <ViewProfileTourGuide
                    title={userTourGuideData[selectedTourGuide].title}
                    sub_title={userTourGuideData[selectedTourGuide].description}
                  />
                )}
              </div>
            </div>
          )}
          <Link
            to="/user-profile"
            className="text-decoration-none d-none d-sm-block"
          >
            <h6
              className={`cursor-pointer me-2 mb-0 user-name ${changeNavColor ? "text-dark" : "text-light"
                }`}
            >
              {loginUser?.userDetails?.username}
            </h6>
          </Link>
          <div className="position-relative">
            <Dropdown
              open={
                // loginUser?.ProfessionalDetails
                //   ? selectedTourGuide < 1
                //     ? true
                //     : isShowUpgradeOptions
                //   : !isProfessional && selectedTourGuide < 1
                //   ? true
                //   :
                loginUser?.ProfessionalDetails
                  ? isShowUpgradeOptions
                  : isShowProOptions
              }
              onOpenChange={(open) => {
                // loginUser.ProfessionalDetails
                //   ? setIsShowUpgradeOptions(open)
                //   :
                loginUser.ProfessionalDetails
                  ? setIsShowUpgradeOptions(open)
                  : setIsShowProOptions(open);
              }}
              dropdownRender={() =>
                loginUser?.ProfessionalDetails ? (
                  <UpgradeAccountMenu
                    setIsShowUpgradeOptions={setIsShowUpgradeOptions}
                  />
                ) : (
                  <BecomeProfesionalMenu
                    setIsShowProOptions={setIsShowProOptions}
                  />
                )
              }
              trigger={["click"]}
              className="position-relative"
            >
              {loginUser.userDetails.profileImageURL || userDetails? (
                <div>
                  <img
                    style={{
                      width: '40px',
                      height: '40px',
                      maxWidth: 'none'
                    }}
                    className="cursor-pointer profile-icon rounded-circle"
                    
              
                    src={
                      userDetails
                        ? media_base_url + userDetails
                        :  media_base_url + loginUser.userDetails.profileImageURL
                    }
                    alt="profile"
                  />
                </div>
              ) : (
                <img
                  className="cursor-pointer profile-icon"
                  src={dummyUser}
                  style={{
                    width: '145px',
                    height: '100%'
                  }}
                  alt="profile"
                />
              )}
            </Dropdown>
          </div>
        </div>
      ) : (
        <div className="d-flex gap-2 align-items-center">
          <CustomButton
            bgcolor={primaryColor}
            color="white"
            padding="4px"
            border="none"
            width="100%"
            textTransform="Capitalize"
            type="submit"
            title="Add Property"
            margin="auto"
            fontFamily="EnnVisionsMedium"
            fontSize="14px"
            clicked={() => {
              setHomeUnAuthAddProperty(true);
              navigate("/login");
            }}
          />
          <Link to="/login" className="text-decoration-none">
            <p
              className={`auth-text ${changeNavColor ? "text-dark" : "text-dark"
                }`}
            >
              Login/Signup
            </p>
            {/* <img className="profile-icon" src={placeholder} alt="profile" /> */}
          </Link>
        </div>
      )}
    </NavMenuStyle>
  );
};

export default NavMenu;

const NavMenuStyle = styled.div`
display:flex;
gap:10px;
align-items:center;
  @media ${mediaDeviceMax.mobileL} {
    .plus-icon {
      height: 30px;
    }
  }
`;
