import { Select, Slider } from "antd";
import CustomButton from "../../components/CustomButton/CustomButton";
import { lightGrey, primaryColor } from "../../components/GlobalStyle";
import { AreaFilterStyle } from "./style";
import { useSearchContext } from "../../context/SearchContext";
import { useState } from "react";
import { useAppSelector } from "../../app/hooks";

const AreaFilter = () => {
  const {
    setShowAreas,
    propertyTypeNumber,
    searchCriteria,
    setSearchCriteria,
    areaMinRange,
    setAreaMaxRange,
    setAreaMinRange,
    areaMaxRange,
  } = useSearchContext();
  const { setFilterCriteria } = useSearchContext();

  const onChange = (value: [number, number]) => {
    setAreaMinRange(value[0]);
    setAreaMaxRange(value[1]);
  };
  const propertyData = useAppSelector(
    (state) => state.appData.data.propertyAppData[propertyTypeNumber]
  );
  const propertySizes = propertyData.propertySizeDTOS;
  const [area, setArea] = useState(propertySizes[0].displayName);

  const onAfterChange = (value: number | [number, number]) => {
    console.log("onAfterChange: ", value);
  };
  console.log({ areaMinRange, areaMaxRange });
  const totalAreaFilter = 5000;
  return (
    <AreaFilterStyle>
      <div className="d-flex justify-content-between mb-3">
        <h6>Area</h6>
        <Select
          style={{ width: 120 }}
          onChange={(area) => setArea(area)}
          value={area}
          options={propertySizes.map(({ displayName }) => ({
            value: displayName,
            label: displayName,
          }))}
        />
      </div>
      <div className="d-flex align-items-center">
        <input
          type="number"
          placeholder="0"
          value={areaMinRange}
          onChange={(e) => setAreaMinRange(e.target.value)}
        />
        <p className="mx-2 mb-0 to-text">To</p>
        <input
          type="number"
          placeholder={totalAreaFilter.toString()}
          value={areaMaxRange}
          onChange={(e) => setAreaMaxRange(e.target.value)}
        />
      </div>
      <Slider
        range
        defaultValue={[20, totalAreaFilter]}
        max={totalAreaFilter}
        onChange={onChange}
        onAfterChange={onAfterChange}
      />
      <div className="d-flex gap-2">
        <CustomButton
          bgcolor={lightGrey}
          color="white"
          padding="8px"
          width="50%"
          type="submit"
          title="Cancel"
          margin="auto"
          fontFamily="EnnVisionsMedium"
          fontSize="16px"
          clicked={() => {
            const oldSearchCreteria = searchCriteria.filter(
              ({ filterKey }) =>
                filterKey !== "propertySizeStartRange" &&
                filterKey !== "propertySizeEndRange"
            );
            setSearchCriteria(oldSearchCreteria);
            setAreaMinRange(0);
            setAreaMaxRange(0);
            setShowAreas(false);
          }}
        />
        <CustomButton
          bgcolor={primaryColor}
          color="white"
          padding="8px"
          width="50%"
          type="submit"
          title="Apply"
          margin="auto"
          fontFamily="EnnVisionsMedium"
          fontSize="16px"
          clicked={() => {
            if (+areaMinRange < 0 && areaMinRange > areaMaxRange) {
              return;
            }
            setFilterCriteria(
              "propertySizeType",
              propertySizes.find(({ displayName }) => displayName === area)
                ?.keyName
            );
            setFilterCriteria("propertySizeStartRange", +areaMinRange);
            setFilterCriteria("propertySizeEndRange", +areaMaxRange);
            setShowAreas(false);
          }}
        />
      </div>
    </AreaFilterStyle>
  );
};

export default AreaFilter;
