import { Col, Row } from "react-bootstrap";
import { ExplorePropertyStyled } from "./style";
import ic_image_2 from "../../assets/images/ic_image_2.png";
import {
  BlackDot,
  primaryColor,
  SecondaryHeading,
  TertiaryHeading,
  TertiraryHeadingMini,
  UnderlineRed,
} from "../../components/GlobalStyle";
import CustomButton from "../../components/CustomButton/CustomButton";
const ExploreProperty = () => {
  return (
    <ExplorePropertyStyled>
      <SecondaryHeading className="text-center">
       About Us
      </SecondaryHeading>
      {/* <TertiraryHeadingMini className="text-center">
        Browse Listings Curated By Neighborhoods
      </TertiraryHeadingMini> */}
      <div className="d-flex justify-content-center">
        <UnderlineRed />
        <BlackDot />
      </div>
      <Row className="position-relative">
        <Col sm={6} md={6} className="p-0 pe-sm-2">
          <div className="img-section">
            <img src={ic_image_2} alt="property" />
          </div>
        </Col>
        <Col sm={6} md={6} className="mt-4 mt-md-none pb-5">
          <TertiaryHeading>Welcome To HouseUp</TertiaryHeading>
          <p className="sub-title">
          Canada’s first social real estate platform. 
Connecting buyers and sellers. We also have all the professionals you need to assist you with the sale of your home. 
“Own the way you sell your home”.
<br/>
<br/>
Revolutionizing the real estate experience, we empower homeowners with control and transparency throughout the selling process. From listing to closing, our platform seamlessly integrates expert advice and cutting-edge technology. Say goodbye to traditional constraints and hello to a new era of home selling. Join us in reshaping the real estate landscape, one satisfied homeowner at a time. Discover the freedom to own your home selling journey today.

          </p>
          {/* <TertiaryHeading>Get MPAC Reports</TertiaryHeading>
          <p className="sub-title">
            <span>
              HouseUp is a convenient platform that grants users access to MPAC
              reports, offering comprehensive and current property assessment
              information for any given address. By simplifying the process and
              providing reliable data, HouseUp saves users time and effort,
              enabling them to make well-informed decisions in the real estate
              realm. With its user-friendly interface, HouseUp effortlessly
              facilitates the retrieval of MPAC reports, ensuring a hassle-free
              experience for property assessment research
            </span>
          </p>  */}
          {/* <div className="explore-btn position-absolute w-100 d-flex justify-content-end me-3 mt-3">
            <CustomButton
              bgcolor={primaryColor}
              color="white"
              padding="6px 8px"
              width="160px"
              type="submit"
              title="EXPLORE"
              fontSize="16px"
            />
          </div> */}
        </Col>
      </Row>
    </ExplorePropertyStyled>
  );
};

export default ExploreProperty;
