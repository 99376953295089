import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "antd";
import FormControl from "../../components/FormControl";
import CustomButton from "../../components/CustomButton/CustomButton";
import { primaryColor } from "../../components/GlobalStyle";
import ic_logo from "../../assets/icons/ic_logo.svg";
import LoginStyle from "./style";
import { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { toast } from "react-toastify";
import { setLoginData } from "../../redux/features/user/getLoginDataSlice";
import axios from "axios";
import { local_storage_web_key, login_url } from "../../utils/api_urls";
import Head from "../../components/Head/Head";
import EnnvisionModal from "../../components/CustomModals/EnnvisionModal";
import CustomModal from "../../components/Modal/CustomModal";
import Footer from "../../components/Footer/Footer";
import { useGlobalContext } from "../../context/context";
import { loginDataTypes } from "../../redux/features/types";
import useTourGuides from "../../hooks/useTourGuide";
import { loginData } from "../../App";
import TermsAndConditions from "../Register/TermsAndConditions";

// initial values types
type loginInitialTypes = {
  username: string;
  password: string;
};

const Login = () => {
  const navigate = useNavigate();
  const { homeUnAuthAddProperty } = useGlobalContext();
  const loginToastId = useRef<any>(null);
  const [loading, setloading] = useState(false);
  const dispatch = useAppDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [terms, setTerms] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);
  const scrollViewRef = useRef<any>();
  const { getTourGuidesData } = useTourGuides();
  const [error, setError] = useState("");
  // get Validations from redux appData
  const { validations } = useAppSelector((state) => state.appData.data);

  // get user location

  // validations from redux appData
  const userName = validations.find(({ key }) => key === "USER_NAME");
  const password = validations[28];

  // regExpressions from redux appData
  const userNameReg = new RegExp(userName!.pattern);
  const passwordReg = new RegExp(password.pattern);

  // initialValues
  const initialValues: loginInitialTypes = {
    username: "",
    password: "",
  };

  // validation schema
  const validationSchema = Yup.object({
    username: Yup.string()
    .required(userName!.notBlankMsg),
    password: Yup.string()
      .required(password.notBlankMsg)
  });

  // login handle submit
  const handleSubmit = async (values: any) => {
    if (!terms) {
      setShowTermsError(true);
      setTimeout(() => {
        setShowTermsError(false);
      }, 2000);
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollToEnd({ animated: true });
      }
      return;
    }
    try {
      setloading(true);
      setError("");
      const response = await axios.post(login_url, values);
      const { results } = response.data;
      // const {
      //   data: { results },
      // }: {
      //   data: {
      //     results: loginDataTypes;
      //   };
      // } = await axios.post(login_url, values);
      localStorage.setItem(local_storage_web_key, JSON.stringify(results));
      // await getTourGuidesData(results);
      dispatch(setLoginData(results));
      console.log({ results }, "data of logged in successfully");
      loginToastId.current = toast(response.data.responseMessage, {
        type: "success",
        autoClose: 1000,
      });
      setloading(false);
      navigate(homeUnAuthAddProperty ? "/create-property" : "/explore-nearby");
    } catch (error: any) {
      console.log({ error });
      setloading(false);
      loginToastId.current = toast(error.response.data.responseMessage, {
        type: "error",
        autoClose: 1000,
      });
      setError(error.response.data.responseMessage);
      setIsModalVisible(true);
    }
  };

  // do task when login failed
  const doTask = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Head title="login" />
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal title={error} description="" doTask={doTask} />
      </CustomModal>
      <LoginStyle>
        <div className="login-container overflow-auto">
          <div className="login-container-card">
            <div className="login-container-card-logo">
              <img src={ic_logo} alt="ic_logo" className="logo" />
            </div>
            <p className="text-center my-3">
              Welcome back! Login to access your account and continue your
              journey with us
            </p>
            <div className="login-container-card-form">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {(formik) => {
                  console.log(formik.values);
                  return (
                    <Form
                      name="basic"
                      onFinish={formik.handleSubmit}
                      // onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      // validateMessages={validationSchema}
                    >
                      <div className="login-input-fields">
                        <FormControl
                          control="input"
                          type="text"
                          name="username"
                          label="User Name"
                          labelFamily="EnnVisionsMedium"
                          placeholder="User Name"
                          fontFamily="EnnVisionsMedium"
                          labelMarginBottom="8px"
                          className={
                            formik.errors.username && formik.touched.username
                              ? "is-invalid"
                              : "customInput"
                          }
                        />
                        {!loading && (
                          <p
                            className="forget_password mb-0 text-end cursor-pointer"
                            onClick={() =>
                              navigate("/forget-password", {
                                state: {
                                  useCase: "FORGET_USERNAME",
                                },
                              })
                            }
                          >
                            Forgot your Username?
                          </p>
                        )}
                        <div className="login-input-fields-field">
                          <FormControl
                            control="password"
                            type="text"
                            name="password"
                            label="Password"
                            placeholder="Password"
                            fontFamily="EnnVisionsMedium"
                            labelMarginBottom="8px"
                            className={
                              formik.errors.password && formik.touched.password
                                ? "is-invalid"
                                : "customPasswordInput"
                            }
                          />
                        </div>
                        {!loading && (
                          <p
                            className="forget_password mb-0 text-end cursor-pointer"
                            onClick={() =>
                              navigate("/forget-password", {
                                state: {
                                  useCase: "FORGETPASSWORD",
                                },
                              })
                            }
                          >
                            Forgot your password?
                          </p>
                        )}
                        <div className="mt-2">
                          <CustomButton
                            bgcolor={primaryColor}
                            color="white"
                            padding="8px"
                            width="100%"
                            type="submit"
                            title="LOG IN"
                            fontSize="14px"
                            fontFamily="EnnVisions"
                            loading={loading}
                          />
                        </div>
                        <TermsAndConditions
                          terms={terms}
                          setTerms={setTerms}
                          showTermsError={showTermsError}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>

            {!loading && (
              <div className="signup-text mt-3">
                <p> Don't have an account ?</p>
                <h6 className="ms-1">
                  <Link to="/generate-otp">Sign Up</Link>
                </h6>
              </div>
            )}
          </div>
        </div>
      </LoginStyle>
    </>
  );
};

export default Login;
