import { useState } from "react";
import { AuthSidebarStyle } from "./style";
import { NavLink } from "react-router-dom";
import CustomDrawer from "../SideMenu/SideMenu";
import { useGlobalContext } from "../../context/context";
import CustomButton from "../CustomButton/CustomButton";
import { primaryColor } from "../GlobalStyle";
import useLogout from "../../hooks/useLogout";
import detailCollapse from "../../assets/icons/ic_blog_detail_arrow.svg";
import detailOpen from "../../assets/icons/ic_add_property_dropdown.svg";
import SidebarTourGuide from "../TourGuide/Sidebar";
import { useAppSelector } from "../../app/hooks";

const AuthSidebar = () => {
  const { showAuthSidebar, setShowAuthSidebar } = useGlobalContext();
  const logout = useLogout();
  const loginData = useAppSelector((state) => state.loginData.data);
  const { userTourGuideData, professionalTourGuideData, selectedTourGuide } =
    useGlobalContext();
  // sidebar data
  const [sidebarData, setSidebarData] = useState([
    {
      name: "Explore Nearby",
      link: "/explore-nearby",
      id: 1,
    },
    {
      name: "Create a Listing",
      link: "/create-property",
      id: 2,
    },
    {
      name: "My Listing",
      link: "/property-listing",
    },
    {
      name: "Find Property",
      link: "/find-home",
      id: 3,
    },
    {
      name: "Find Professional",
      link: "/find-professional",
    },
    {
      name: "Credit Card List",
      link: "/payment-wallet",
    },
    {
      name: "Reports & Feedback",
      link: "/report-feedback",
    },
    {
      name: "Contact Us",
      link: "/contact-us",
    },

    // {
    //   name: "Settings & Privacy",
    //   link: "/setting-privacy",
    // },
   
  ]);
  // requests menu
  const [helpSupportMenu, setHelpSupportMenu] = useState({
    title: "Help & Support",
    isOpen: false,
    sub_Menus: [
      {
        name: "Online Support",
        link: "/online-support",
      },
      {
        name: "FAQ",
        link: "/faq",
      },
    ],
  });
  // requests menu
  const [requestsMenu, setRequestsMenu] = useState({
    title: "Booking Request",
    isOpen: false,
    sub_Menus: [
      {
        name: "Sent",
        link: "/sent-requests",
      },
      {
        name: "Receive",
        link: "/upcoming-requests",
      },
      {
        name: "History",
        link: "/previous-requests",
      },
    ],
  });

  const [sidebarData1, setSidebarData1] = useState([
    {
      name: "Privacy Policy",
      link: "/privacy",
      id: 1,
    },
    {
      name: "Terms & Conditions",
      link: "/terms",
      id: 2,
    },])
  return (
    <CustomDrawer>
      <AuthSidebarStyle className={`${showAuthSidebar ? "d-block" : "d-none"}`}>
        <div className="inner-container d-flex flex-column justify-content-between h-100">
          <div>
            {sidebarData.map(({ name, link, id }, index) => (
              <div key={index} className="inner-container">
                <NavLink
                  onClick={() => setShowAuthSidebar(false)}
                  to={link}
                  className="text-decoration-none "
                >
                  <p>{name}</p>
                </NavLink>
                {/* <h1>
                  {JSON.stringify(userTourGuideData[selectedTourGuide].title)}
                </h1> */}
                {/* {!loginData?.ProfessionalDetails &&
                  userTourGuideData[
                    selectedTourGuide
                  ].title.toLocaleLowerCase() === name.toLocaleLowerCase() &&
                  userTourGuideData[selectedTourGuide].isOpen && (
                    <div className="position-absolute mt-2">
                      <SidebarTourGuide
                        title={userTourGuideData[selectedTourGuide].title}
                        sub_title={
                          userTourGuideData[selectedTourGuide].description
                        }
                      />
                    </div>
                  )} */}
              </div>
            ))}
            <div
              onClick={() => {
                setRequestsMenu({
                  ...requestsMenu,
                  isOpen: false,
                });
                setHelpSupportMenu({
                  ...helpSupportMenu,
                  isOpen: !helpSupportMenu.isOpen,
                });
              }}
              className="d-flex justify-content-between align-items-center cursor-pointer"
            >
              <p className="mb-0" id="requests">
                {helpSupportMenu.title}
              </p>
              <p>
                <img
                  src={helpSupportMenu.isOpen ? detailOpen : detailCollapse}
                  alt={helpSupportMenu.isOpen ? "details" : "show details"}
                />
              </p>
            </div>
            {helpSupportMenu.isOpen &&
              helpSupportMenu.sub_Menus.map(({ name, link }, index) => (
                <div key={index} className="inner-container ms-3">
                  <NavLink
                    onClick={() => setShowAuthSidebar(false)}
                    to={link}
                    className="text-decoration-none"
                  >
                    <p>{name}</p>
                  </NavLink>
                </div>
              ))}
            <div
              onClick={() => {
                setHelpSupportMenu({
                  ...helpSupportMenu,
                  isOpen: false,
                });
                setRequestsMenu({
                  ...requestsMenu,
                  isOpen: !requestsMenu.isOpen,
                });
              }}
              className="d-flex justify-content-between align-items-center cursor-pointer"
            >
              <p className="mb-0" id="requests">
                {requestsMenu.title}
              </p>
              <p>
                <img
                  src={requestsMenu.isOpen ? detailOpen : detailCollapse}
                  alt={requestsMenu.isOpen ? "details" : "show details"}
                />
              </p>
            </div>
            {requestsMenu.isOpen &&
              requestsMenu.sub_Menus.map(({ name, link }, index) => (
                <div key={index} className="inner-container ms-3 ">
                  <NavLink
                    onClick={() => setShowAuthSidebar(false)}
                    to={link}
                    className="text-decoration-none"
                  >
                    <p>{name}</p>
                  </NavLink>
                </div>
              ))}
                   {sidebarData1.map(({ name, link, id }, index) => (
              <div key={index} className="inner-container">
                <NavLink
                  onClick={() => setShowAuthSidebar(false)}
                  to={link}
                  className="text-decoration-none "
                >
                  <p>{name}</p>
                </NavLink>
                {/* <h1>
                  {JSON.stringify(userTourGuideData[selectedTourGuide].title)}
                </h1> */}
                {/* {!loginData?.ProfessionalDetails &&
                  userTourGuideData[
                    selectedTourGuide
                  ].title.toLocaleLowerCase() === name.toLocaleLowerCase() &&
                  userTourGuideData[selectedTourGuide].isOpen && (
                    <div className="position-absolute mt-2">
                      <SidebarTourGuide
                        title={userTourGuideData[selectedTourGuide].title}
                        sub_title={
                          userTourGuideData[selectedTourGuide].description
                        }
                      />
                    </div>
                  )} */}
              </div>
            ))}
          </div>

          <div className="logout-btn">
            <CustomButton
              bgcolor={primaryColor}
              color="white"
              padding="6px"
              width="80%"
              type="button"
              title="Logout"
              margin="auto"
              fontSize="16px"
              clicked={logout}
            />
          </div>
        </div>
      </AuthSidebarStyle>
    </CustomDrawer>
  );
};

export default AuthSidebar;
