import { useGlobalContext } from "../../../context/context";
import closeIcon from "../../../assets/icons/ic_story_close.svg";
import { CreatePostGridStyle } from "./style";
import { Col, Container, Row } from "react-bootstrap";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePostContext } from "../../../context/PostContext";

const CreatePostGrid = () => {
  const { createPostFiles, setCreatePostFiles } = usePostContext();
  const navigate = useNavigate();
  const removeSlideItem = (itemId: number) => {
    const allSlideItem = [...createPostFiles];
    setCreatePostFiles(allSlideItem.filter((_, id) => id !== itemId));
  };

  useEffect(() => {
    if (createPostFiles.length === 0) {
      navigate("/create-post");
    }
  }, [createPostFiles]);

  return (
    <CreatePostGridStyle>
      <Container>
        <Row className="d-flex justify-content-center align-align-items-center">
          {createPostFiles.map((item: File, index: number) => {
            if (item.type.startsWith("image/")) {
              return (
                <Col
                  sm={6}
                  md={4}
                  lg={4}
                  xl={3}
                  className="slider-item"
                  key={index}
                >
                  <img
                    className="media-post-item"
                    src={URL.createObjectURL(item)}
                    alt={"image-" + index}
                    key={index}
                  />
                  <img
                    onClick={() => removeSlideItem(index)}
                    src={closeIcon}
                    className="close-icon position-absolute"
                    alt="close-icon"
                  />
                </Col>
              );
            } else {
              return (
                <Col sm={6} md={4} lg={3} className="slider-item">
                  <video
                    src={URL.createObjectURL(item)}
                    className="media-post-item rounded"
                    controls
                  />
                  <img
                    onClick={() => removeSlideItem(index)}
                    src={closeIcon}
                    className="close-icon position-absolute"
                    alt="close-icon"
                  />
                </Col>
              );
            }
          })}
        </Row>
      </Container>
    </CreatePostGridStyle>
  );
};

export default CreatePostGrid;
