import { SuggestionStyle } from "./style";
import CustomButton from "../../components/CustomButton/CustomButton";
import {
  primaryColor,
  secondaryBlue,
  tertiaryGrey7,
} from "../../components/GlobalStyle";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { authorizationToken, media_base_url } from "../../utils/api_urls";
import { admin_getAll_users_url } from "../../utils/api_urls";
import NoDataAvailable from "../../components/NoData/NoDataAvailable";
import { userDataTypes } from "./types";
import placeholder from "../../assets/icons/ic_use_placeholder.svg";
import useFollow from "../../hooks/useFollow";
import { useNavigate } from "react-router-dom";
import UsersSkeleton from "../../components/Skeletons/UserSuggestions";

const Suggestions = () => {
  const [loading, setLoading] = useState(true);
  const [usersData, setUsersData] = useState({} as userDataTypes);
  const [error, setError] = useState("");
  const loginData = useAppSelector((state) => state.loginData.data);
  const navigate = useNavigate();
  const {
    loading: isFollowLoading,
    error: isFollowingError,
    followPromiseHandler,
  } = useFollow();
  // get all user promise
  const getAllUsersPromise = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        admin_getAll_users_url + 0,
        {},
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      console.log({ data });
      setUsersData(data.results);
      setLoading(false);
    } catch (error: any) {
      setError(error.response.data.responseMessage);
      setLoading(false);
    }
  };

  console.log({ usersData });

  useEffect(() => {
    getAllUsersPromise();
  }, []);

  if (loading) return <UsersSkeleton />;
  if (error) return <NoDataAvailable title={error} />;

  const { username, userFirstName, userLastName, profileImageURL, id } =
    loginData!.userDetails;

  return (
    <SuggestionStyle>
      <div className="d-flex align-align-items-center">
        <img
          src={profileImageURL ? media_base_url + profileImageURL : placeholder}
          alt={userFirstName}
          className="main-profile-icon cursor-pointer"
          onClick={() => navigate("/user-profile")}
        />
        <div className="profile-details mt-2">
          <h6
            className="profile-details-title mb-0 cursor-pointer"
            onClick={() => navigate("/user-profile")}
          >
            {/* <span>
              <img className="me-1" src={verifiedIcon} alt="verified" />
            </span> */}
            {userFirstName} {userLastName}
          </h6>
          <p className="profile-details-sub-title w-100">{username}</p>
        </div>
      </div>
      <div className="head-bar d-flex justify-content-between my-3 align-items-center">
        <p className="title w-100 mb-0">Suggestions For You</p>
        <CustomButton
          bgcolor="transparent"
          color={tertiaryGrey7}
          padding="4px 8px"
          width="auto"
          type="button"
          title="See All"
          margin="auto"
          fontSize="14px"
          fontFamily="EnnVisionsMedium"
          border="none"
        />
      </div>
      {usersData.users.map(
        (
          { firstName, lastName, profilePicture, emailAddress, id },
          index: number
        ) => (
          <div
            key={index}
            className="suggestion d-flex justify-content-between align-items-center"
          >
            <div className="d-flex justify-content-between">
              <img
                src={
                  profilePicture ? media_base_url + profilePicture : placeholder
                }
                alt={firstName}
                className="profile-icon cursor-pointer"
                onClick={() => navigate(`/professional/${id}`)}
              />

              <div className="suggestion-profile-details">
                <h6
                  className="suggestion-profile-details-title cursor-pointer mb-0"
                  onClick={() => navigate(`/professional/${id}`)}
                >
                  {firstName.substring(0,5)+'...'} {lastName.substring(0,5)+'...'}
                </h6>
                <p className="suggestion-profile-details-sub-title w-100">
                {emailAddress.substring(0, 12) + '...'}
                </p>
              </div>
            </div>
            <div className="ms-4">
              <CustomButton
                bgcolor="transparent"
                color={primaryColor}
                padding="4px 8px"
                width="auto"
                type="button"
                title="Follow"
                margin="auto"
                fontSize="14px"
                fontFamily="EnnVisionsMedium"
                border="none"
                // loading={isFollowLoading}
                clicked={() => {
                  const oldUser = { ...usersData };
                  const updatedUser = oldUser.users.filter(
                    (user) => user.id !== id
                  );
                  setUsersData({ ...usersData, users: updatedUser });
                  followPromiseHandler(id);
                }}
              />
            </div>
          </div>
        )
      )}
    </SuggestionStyle>
  );
};

export default Suggestions;
