import { Link } from "react-bootstrap/lib/Navbar";
import { PrivacyStyle } from "./style";
import Head from "../Head/Head";

type privacyProps = {
  title: string;
};
const Privacy: React.FC<privacyProps> = ({ title }) => {
  return (
    <>  <Head title="Privacy Policy"/> <PrivacyStyle>
    
    <blockquote>
      <h4>{title}</h4>
      {/* <p>Certainly, here is the modified privacy policy for HouseUp™:</p>
      <p>---</p> */}
      <p>
        
      *HouseUp™ PRIVACY POLICY*

(Latest update: May 1, 2023)
HouseUp™ is committed to protecting your privacy.
      </p>
      <p>HouseUp™'s Privacy Policy has been designed to safeguard the confidentiality, accuracy, and security of your personal information. These principles govern how <strong> HouseUp™ </strong>collects, uses, retains, and discloses your personal information in all of our <strong> HouseUp™ </strong>content and service offerings, including those on the <strong> HouseUp™ </strong>website at <a target="_blank" href="http://houseup.ca/" rel="noreferrer"> www.houseup.ca</a>. Please read this policy in conjunction with HouseUp™'s Website and Mobile Device App Use Terms and Conditions, which can be found [insert link here to HouseUp™'s Website and Mobile Device App Use Terms and Conditions].</p>
      <h6> What is personal information? </h6>
      <p>
      Personal information refers to any information about an identifiable individual. This may include your name, address, telephone number, birth date, purchase information, and email address. As a user of the <strong> HouseUp™ </strong>website or mobile device applications, or any <strong> HouseUp™ </strong>content or services, you may provide us with this information in various ways, such as when applying for a <strong> HouseUp™ </strong>user account/registration, accessing and using <strong> HouseUp™ </strong>content and services, making purchases, participating in online contests, signing up for various <strong> HouseUp™ </strong>programs or services, visiting or using our <strong> HouseUp™ </strong>website at<a target="_blank" href="http://houseup.ca/"> www.houseup.ca</a>., or using any of our mobile device applications. In all cases, <strong> HouseUp™ </strong>adheres to strict guidelines when handling your personal information.
      </p>
      <h6>Collecting your personal information </h6>
      <p>
       
When you provide <strong> HouseUp™ </strong>with personal information, we will inform you of the reasons for collecting it, especially if the purposes go beyond what is outlined in this Privacy Policy.
      </p>
      <p>
      <strong> HouseUp™ </strong>obtains your consent for the collection of your personal information. In some cases where the purpose of collection is obvious, your consent may be implied.
      </p>
      <p>
        We may also seek your permission to collect additional information that could be beneficial in serving you better. For quality service purposes, telephone calls to our user or customer service lines may be recorded. You will be informed if your call is part of a quality assurance program before speaking with a representative.
      </p>

      <h6>Using and disclosing your personal information</h6>
      <p>
       <strong> HouseUp™ </strong>only uses and discloses your personal information for the purposes identified in this Privacy Policy or for marketing or other purposes that were disclosed to you when we obtained your consent.
      </p>
      <p>
       Your personal information is used or disclosed only for the purposes outlined in this Privacy Policy, as it may be amended from time to time, or for other purposes to which you have provided specific consent.
      </p>
      <p>
       <strong> HouseUp™ </strong>may use your personal information to process your user or client application/registration, create your profile, fulfill requests for content, products or services, tailor content, products or services, and for advertising or marketing purposes. We may also use your information to better understand your needs for information, products, and services, and to offer relevant content, products, services, marketing, and advertising to meet those needs. We track and analyze your viewing times, patterns, purchases, and other transactions, shopping behaviors, account activity, and payment history for marketing purposes and to make promotional offers to you.
      </p>
      <p>
        We may collect information about your location, either because you choose to share it or through data from your device, such as GPS location, wireless network information, cell tower data, or your IP address. This location information is used to provide features on the <strong> HouseUp™ </strong>website or in connection with our mobile device applications and to enhance and customize website pages, mobile device applications, content, services, and marketing materials to make them more relevant to you.
      </p>
      <p>
       <strong> HouseUp™ </strong>may monitor how you interact with HouseUp™, including links across our website and mobile device applications, as well as content and services, to improve our website, mobile device applications, content, services, and marketing. This may include tracking clicks and gathering aggregate statistics, such as the number of times a particular link was clicked on.
      </p>
      <p>
      Similar to many websites and mobile device applications, <strong> HouseUp™ </strong>uses cookies and similar technologies to collect additional usage data and enhance our website and mobile device applications, content, and services. The use of cookies may be required for many parts of our content and services, such as searching and viewing public user profiles. Cookies are small data files that are transferred to your computer or mobile device. We may use session cookies and persistent cookies to understand how you interact with HouseUp™'s website and mobile device applications, monitor aggregate usage by <strong> HouseUp™ </strong>users, manage web/application traffic, and customize and improve our website, mobile device applications, content, services, and marketing. While most web browsers and mobile devices accept cookies by default, you can modify your settings to decline cookies or receive alerts when a website attempts to place a cookie on your computer or mobile device. Please note that disabling cookies may affect the functionality of some parts of the <strong> HouseUp™ </strong>website or mobile device applications.

      </p>
      <p>
       When you log into your <strong> HouseUp™ </strong>account using a web browser or mobile device, we associate that browser or mobile device with your account for purposes such as authentication and personalization. Depending on your settings, <strong> HouseUp™ </strong>may also personalize your experience based on information from other browsers or mobile devices, even those not used to log into HouseUp™. For example, if you visit other websites with certain content, <strong> HouseUp™ </strong>may display related ads on our website or mobile device applications or push them to your web browser or mobile device.
      </p>
      <p>
       <strong> HouseUp™ </strong>may share or disclose your information as directed by you, such as when you authorize a third-party web client or application to access your <strong> HouseUp™ </strong>account or specific portions of it, or when you instruct us to share your feedback with a business. If you use social media features on the <strong> HouseUp™ </strong>website or any mobile device application or any content or services to share information with another user or person who can access the <strong> HouseUp™ </strong>website or a mobile device application or such content or services, please be aware that this information may be shared with the third party.
      </p>
      <p>
       From time to time, <strong> HouseUp™ </strong>engages service providers to perform functions and provide services, such as hosting blogs and other social media functions, and to help us understand and improve the use of the <strong> HouseUp™ </strong>website and its content, services, and marketing. <strong> HouseUp™ </strong>may share your private personal information with these service providers, subject to obligations consistent with this Privacy Policy and any other appropriate confidentiality and security measures. These third parties will use your private personal data solely on our behalf and in accordance with HouseUp™'s instructions. We may also share your payment information, including your credit or debit card number, card expiration date, CVV code, and billing address, with payment services providers for payment processing, fraud prevention, dispute resolution, and other purposes related to credit or debit card use.
      </p>
      <p>
       
HouseUp™ may share or disclose your public information, such as your public user profile information and your public social media communications and interactions.
      </p>
      <p>
       In the event that <strong> HouseUp™ </strong>is involved in a bankruptcy, merger, acquisition, reorganization, or sale of assets, your information may be sold or transferred as part of that transaction. This Privacy Policy will apply to your information as transferred to the new entity. <strong> HouseUp™ </strong>may also disclose information about you to <strong> HouseUp™ </strong>affiliates to help improve the HouseUp™

      </p>
      <p>
        website and mobile device applications, as well as HouseUp™'s and its affiliates' content, services, and marketing, including the delivery of ads.
      </p>
      <p>
      <strong> HouseUp™ </strong>may share or disclose non-personal, aggregated, or device-level information. This information does not include your name, email address, phone number, or personalized purchase information. However, <strong> HouseUp™ </strong>may share non-personal, depersonalized, aggregated, or device-level information with entities through partnerships and associations that may use data in their possession, including data you may have provided to them, to link your name, email address, or other personal information to the information we provide.
      </p>
      <p>
      <strong> HouseUp™ </strong>may share information about you as permitted or required by law, including sharing with credit bureaus, regulators, and law enforcement agencies.
      </p>
      <p>Despite anything to the contrary in this Privacy Policy, <strong> HouseUp™ </strong>may preserve or disclose your information if we believe it is reasonably necessary to comply with a law, regulation, legal process, or governmental or administrative request, to protect the safety of any person, to address fraud, security or technical issues, or to protect <strong> HouseUp™ </strong>or any <strong> HouseUp™ </strong>users' rights or property. However, this Privacy Policy is not intended to limit any legal defenses or objections you may have to a third party's, including a government's, request to disclose your information.</p>
      <h6>Maintaining and safeguarding your personal information </h6>
      <p>
       <strong> HouseUp™ </strong>takes measures to ensure that our security practices protect against loss, theft, and unauthorized access, disclosure, use, or modification of your personal information.
      </p>
      <p>
       We limit access to your personal information to <strong> HouseUp™ </strong>associates who require it for use in accordance with HouseUp™'s established privacy principles.
      </p>
      <p>
       <strong> HouseUp™ </strong>associates are required to sign an acknowledgment and undertaking, which obligates them to maintain the confidentiality of your personal information and adhere to our security standards.
      </p>
      <p>
       We retain your information for as long as necessary to provide you with our services effectively.
      </p>
      <p>
       You have the right to withdraw your consent for the use of your personal information at any time, subject to legal restrictions and with reasonable notice to HouseUp™. We will inform you of the implications of withdrawing your consent. Information may be retained by <strong> HouseUp™ </strong>for audit or archival purposes.
      </p>
      <p>You are entitled to access your personal information. If you request access to your personal information, <strong> HouseUp™ </strong>will respond to your request within 30 days. There is no cost for reasonable requests unless you require copies of records. We will inform you of any applicable costs before copying those records. <strong> HouseUp™ </strong>may not be able to provide you with access to your personal information if it cannot be separated from the records of others, cannot be disclosed for reasons of security or commercial confidentiality, or is protected by legal privilege. You may access your personal information on record to ensure its accuracy by submitting a written request to us.</p>
      <h6>*HouseUp™ provides you with the answers*</h6>
      <p>
       <strong> HouseUp™ </strong>has established a Privacy Office and appointed a designated privacy officer to ensure that all members of our team comply with our Privacy Policy. <strong> HouseUp™ </strong>investigates and responds to any questions, complaints, or requests for information you may have.
      </p>
      <h6>*How to contact the <strong> HouseUp™ </strong>Privacy Office*</h6>
      <p>
      </p>
      <p>
       Email: info@houseup.com
      </p>
      <p>
       Attention: <strong> HouseUp™ </strong>Privacy Officer
      </p>
      <p>
       Email: info@houseup.com
      </p>

    </blockquote>
  </PrivacyStyle></>
   
  );
};

export default Privacy;
