import Card from "react-bootstrap/Card";
import {
  BackgroundImage,
  ImgContainer,
  MiniHeadingSecondary,
  primaryColor,
  ProfileMiniImg,
  tertiaryGrey20,
} from "../../components/GlobalStyle";
import unLikeIcon from "../../assets/icons/ic_newsfeed_like.svg";
import sent from "../../assets/icons/ic_newsfeed_sent.svg";
import comment from "../../assets/icons/ic_newsfeed_comment.svg";
import save from "../../assets/icons/ic_newsfeed_saved.svg";
import { BgImage, PostStyle } from "./style";
import CustomLink from "../../components/CustomLink/Index";
import moreOptionIcon from "../../assets/icons/ic_newsfeed_more.svg";
import AliceCarousel from "react-alice-carousel";
import { NewsFeedRecord } from "../../utils/types";
import { useAppSelector } from "../../app/hooks";
import {
  authorizationToken,
  follow_url,
  like_url,
  media_base_url,
  unfollow_url,
} from "../../utils/api_urls";
import axios from "axios";
import { useEffect, useReducer, useRef, useState } from "react";
import { toast } from "react-toastify";
import likeIcon from "../../assets/icons/ic_heart_like.svg";
import getTimeAgo from "../../components/GetTimeAgo";
import { useNewsfeedContext } from "../../context/NewsfeedContext";
import { RootState } from "../../redux/store";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import placeholder from "../../assets/icons/ic_pro_placeholder.svg";
import useDeleteEvent from "../../hooks/useDelete";
import placeholder_round from "../../assets/icons/ic_use_placeholder.svg";
import { addLikeTypes } from "../../hooks/useLike";
import PostPrevBtn from "../../components/Custom/PostPrevBtn";
import PostNextBtn from "../../components/Custom/PostNextBtn";
import { truncateString } from "../../utils/utilities";
import SlidePrevBtn from "../../components/Custom/SlidePrevBtn";
import SlideNextBtn from "../../components/Custom/SlideNextBtn";
import AutoVideoPlayer from "../../components/VideoPlayers/AutoVideoPlay";
import ViewmoreIcon from "../../components/Custom/ViewmoreIcon";
import { StoriesRecord } from "./types";
import { useGlobalContext } from "../../context/context";
import CustomModal from "../../components/Modal/CustomModal";
import ImageGallery from "react-image-gallery";
import close from "../../assets/icons/ic_story_close.svg";
import {lightGrey} from "../../components/GlobalStyle";
import React from "react";
// carosuel responseive
const responsive = {
  0: { items: 1 },
};

type PostProps = {
  postData: NewsFeedRecord;
  selectedPostHandler: (postData: NewsFeedRecord, post_type: string) => void;
  };

const Post: React.FC<PostProps> = ({ postData, selectedPostHandler }) => {
  const [propertyImgIndex, setPropertyImgIndex] = useState(0);
  const {
    createdDateTime,
    imagesURLS,
    description,
    postId,
    hideLikesAndViewsCounts,
    boostPermission,
    contactRequestPermission,
    saveFavourite,
    readComments,
    turnOffCommenting,
    sharingEnabled,

    lastLikeAndComment: {
      lastLikedUserId,
      lastCommentUserId,
      lastCommentUserFirstName,
      lastLikedUserLastName,
      lastCommentText,
      lastLikedUserFirstName,
      lastCommentUserProfilePicture,
      lastCommentUserLastName,
      totalLikes,
      totalComments,
      isLiked,
    },
    videoURL,
    newsFeedId,
    ownerData: {
      firstName,
      lastName,
      id: ownerId,
      profilePicture,
      isFollower,
      isFollowing,
    },
  } = postData;
 
  const [error, setError] = useState("");
  const [likesLoading, setLikesLoading] = useState(false);
  const [likesData, setLikesData] = useState(false);
  const [likesError, setLikesError] = useState(false);
  const { newsfeedData, setNewsfeedData,setIsShowStories } = useNewsfeedContext();
  const navigate = useNavigate();
  const likeToastId = useRef<any>();
  const [showImgsGalleryVisible, setShowImgsGalleryVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const userData = useAppSelector(
    (state: RootState) => state.loginData.data?.userDetails
  );
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  // login data
  const { data } = useAppSelector((state: RootState) => state.loginData);

  const handleDragStart = (e: React.DragEvent<HTMLElement>) =>
    e.preventDefault();
    const [state, setState] = React.useState({value: 10});

    function forceUpdate () {
        setState(prev => {
            return {...prev}
        })
    }
  // load post slides items
  const items = [
    ...imagesURLS.map((img) => (
      <BgImage onDragStart={handleDragStart}>
        <div className="post position-relative">
          <div className="post-image">
            <img src={media_base_url + img} alt="post" className="cursor-pointer" onClick={()=>{ setCurrentIndex(0);
                        setShowImgsGalleryVisible(true);
               <div
               onClick={() => navigateToStoriesModal(media_base_url + img)}
              //  key={index}
             >
             </div>
            }}/>
          </div>
        </div>
      </BgImage>
    ))
  ];

  // volume change detector
  const onVolumeChange = (muted: boolean) => {
    console.log("video volume change runs", muted);
    setIsMuted(muted);
  };
  const { setUserStoriesData, setTotalUserStoriesLength, setSlideIndex } =
  useGlobalContext();
  // navigate to stories
  const navigateToStoriesModal = (data: string) => {
    // setUserStoriesData(data);
    setSlideIndex(0);
    setIsShowStories(true);
  };
  // checking whether item is video
  if (videoURL) {
    // isMuted={isMuted}
    items.unshift(
      <AutoVideoPlayer
        videoUrl={media_base_url + videoURL}
        isMuted={isMuted}
        onVolumeChange={onVolumeChange}
      />
    );
  }
 
useEffect(()=>{},[])
  // loginData from redux
  const { data: loginData } = useAppSelector((state) => state.loginData);

  // follow promsie
  const followPromise = async () => {
    try {
      const { data } = await axios.post(
        follow_url,
        {
          followToUserId: ownerId,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      toast(data.responseMessage);
      setTimeout(() => {
        window.location.reload();
        navigate("/explore-nearby");
      }, 2000);
      



    } catch (err: any) {
      toast(err.response.data.responseMessage);
      setError(err.response.data.responseMessage);
    }
  };

  // unfollow promise
  const {
    apiDataPromise: unFollowPromise,
    loading,
    data: unFollowResults,
    error: unFollowError,
  } = useDeleteEvent();

  // add likes promise handler
  const addLikesPromiseHandler = async ({
    isLike,
    newsFeedId,
    userId,
  }: addLikeTypes) => {
    console.log({ isLike });
    try {
      setLikesLoading(true);
      const { data: likesData } = await axios.post(
        like_url,
        {
          newsFeedId: newsFeedId.toString(),
          userId: userId.toString(),
          isLike: !isLike,
        },
        {
          headers: {
            Authorization: `Bearer ${data?.jwtDetails.token}`,
          },
        }
      );
      setLikesData(likesData.results);
      setLikesLoading(false);

      likeToastId.current = toast(likesData.responseMessage, {
        type: "success",
      });
    } catch (error: any) {
      setLikesError(error.response.data.responseMessage);
      setLikesLoading(false);
      likeToastId.current = toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };

  // old post data
  const oldNewsfeedData = { ...newsfeedData };

  // on video ended
  const onVideoEnd = () => {};

  const handleTogglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (

    <PostStyle>
         <CustomModal
        isModalVisible={showImgsGalleryVisible}
        setIsModalVisible={setShowImgsGalleryVisible}
        showCloseBtn={false}
        width="100%"
      >
        <div
          style={{
            background: lightGrey,
          }}
        >
          <img
            className="close-icon"
            onClick={() => {
              setCurrentIndex(0);
              console.log("ola");
              
              setShowImgsGalleryVisible(false);
            }}
            src={close}
            alt="close"
          />
          <ImageGallery
            items={imagesURLS.map((img) => ({
              original: media_base_url + img,
              thumbnail: media_base_url + img,
            }))}
            showPlayButton={false}
            startIndex={currentIndex}
            showIndex
          />
          ;
        </div>
      </CustomModal>
      <div className="post-owner-section d-flex justify-content-between align-align-items-center">
        <div className="d-flex">
          <ImgContainer
            src={profilePicture ? media_base_url + profilePicture : placeholder}
            alt={firstName}
            height="40px"
            width="40px"
            className="cursor-pointer"
            onClick={() => navigate(`/professional/${ownerId}`)}
          />
          <div className="ms-3">
            <div className="d-flex">
              <p
                className="name mb-0 cursor-pointer"
                onClick={() => navigate(`/professional/${ownerId}`)}
              >
                {firstName} {lastName}
              </p>
              <div className="ms-2">
               {loginData?.userDetails.id !== ownerId ? <CustomButton
                  bgcolor="transparent"
                  color={primaryColor}
                  padding="0px"
                  width="auto"
                  type="submit"
                  textTransform="Captilize"
                  title={isFollowing ? "UnFollow" : "Follow"}
                  margin="auto"
                  fontFamily="EnnVisionsMedium"
                  fontSize="16px"
                  clicked={() => {
                    setNewsfeedData({
                      ...oldNewsfeedData,
                      newsFeedRecords: oldNewsfeedData.newsFeedRecords.map(
                        (newsfeed) => {
                          if (postData?.newsFeedId === newsfeed.newsFeedId) {
                            return {
                              ...newsfeed,
                              ownerData: {
                                ...newsfeed.ownerData,
                                isFollowing: !newsfeed.ownerData.isFollowing,
                              },
                            };
                          }
                          return newsfeed;
                        }
                      ),
                    });
                    isFollowing
                      ? unFollowPromise(unfollow_url, {
                          unFollowToUserId: ownerId,
                        }).then(() => {
                          toast("Unfollow Successsfully")
                          
                            setTimeout(() => {
                              window.location.reload();
                                    navigate("/explore-nearby");
                            }, 2000);
                        })
                      : followPromise();
                      navigate("/explore-nearby");


                  }

                }
                />:''}
              </div>
            </div>
            <p className="time mb-0">{getTimeAgo (createdDateTime)}</p>
          </div>
        </div>
        {/* <img
          className="view-more-icon cursor-pointer align-self-start"
          onClick={() => {
            selectedPostHandler(postData, "post_options");
          }}
          src={moreOptionIcon}
          alt="newFeed"
        /> */}

        <ViewmoreIcon
          onClick={() => {
            selectedPostHandler(postData, "post_options");
          }}
        />
      </div>

      <Card>
        <div className="position-relative">
          <AliceCarousel
            mouseTracking
            items={items}
            responsive={responsive}
            activeIndex={propertyImgIndex}
            controlsStrategy="alternate"
            disableButtonsControls
            disableDotsControls={items.length < 2}
          />
          {propertyImgIndex > 0 && (
            <SlidePrevBtn
              clicked={() => setPropertyImgIndex((prev) => prev - 1)}
            />
          )}
          {imagesURLS.length > 1 &&
            propertyImgIndex < imagesURLS.length - 1 && (
              <SlideNextBtn
                clicked={() => setPropertyImgIndex((prev) => prev + 1)}
              />
            )}
          {/* <div
            className="visited-text d-flex justify-content-between w-100 align-items-center"
            onClick={() => navigate(`/post/${postId}`)}
          >
            <h6 className="mb-0 text-light">Visit Now</h6>
            <span className="fs-3">&#8250;</span>
          </div> */}
        </div>

        <Card.Body
          className="cursor-pointer pb-0 pt-3"
          onClick={() => {
            navigate(`/post/${postId}`);
          }}
        >
          <MiniHeadingSecondary>{description}</MiniHeadingSecondary>
          {/* <Card.Text>
            <p className="sub-title fs-6">{address}</p>
          </Card.Text> */}
        </Card.Body>
        <div className="react d-flex justify-content-between align-items-center mt-1">
          <div className="react-icons mt-xs-2 mt-sm-0 ms-2">
            {!hideLikesAndViewsCounts && (
              <img
                className="cursor-pointer like-icon"
                src={isLiked ? likeIcon : unLikeIcon}
                onClick={() => {
                  setNewsfeedData({
                    ...oldNewsfeedData,
                    newsFeedRecords: oldNewsfeedData.newsFeedRecords.map(
                      (newsfeed) => {
                        if (postData?.newsFeedId === newsfeed.newsFeedId) {
                          return {
                            ...newsfeed,
                            lastLikeAndComment: {
                              ...newsfeed.lastLikeAndComment,
                              isLiked: !newsfeed.lastLikeAndComment.isLiked,
                            },
                          };
                        }
                        return newsfeed;
                      }
                    ),
                  });
                  addLikesPromiseHandler({
                    isLike: isLiked,
                    newsFeedId: newsFeedId!,
                    userId: ownerId!,
                  });
                }}
                alt={isLiked ? "like" : "dislike"}
              />
            )}
            {!turnOffCommenting && (
              <button onClick={() => selectedPostHandler(postData, "comment")}>
                <img src={comment} alt="comment" />
              </button>
            )}
            {sharingEnabled && (
              <button onClick={() => selectedPostHandler(postData, "social")}>
                <img src={sent} alt="send" />
              </button>
            )}
          </div>
          <div className="d-flex align-items-center me-2">
           
            {/* {contactRequestPermission &&
              loginData?.userDetails.id !== ownerId && (
                <CustomButton
                  bgcolor="transparent"
                  color={primaryColor}
                  padding="8px 8px"
                  width="100%"
                  type="submit"
                  title="Contact"
                  margin="auto"
                  textTransform="Captilize"
                  fontFamily="EnnVisionsMedium"
                  fontSize="16px"
                  clicked={() => {
                    navigate("/in-person", {
                      state: {
                        newsFeedId,
                        visitType: "IN_PERSON",
                      },
                    });
                  }}
                />
              )} */}
            {saveFavourite && (
              <span>
                <img
                  className="cursor-pointer me-2 save-icon"
                  src={save}
                  alt="save"
                />
              </span>
            )}
          </div>
        </div>
        {/* checking like exist in post */}
        <div className="caption-section px-2 mt-1">
          {lastLikedUserId && (
            <div
              className="d-flex align-items-center"
              onClick={() => {
                selectedPostHandler(postData, "like");
              }}
            >
              <p className="mb-2 ms-2 cursor-pointer liked-by">
                Liked by
                <strong className="ms-2">
                  {lastLikedUserFirstName} {lastLikedUserLastName}
                </strong>
                {totalLikes > 1 && (
                  <span className="ms-2">
                    and
                    <strong className="ms-2">{totalLikes} </strong>
                    others
                  </span>
                )}
              </p>
            </div>
          )}
          {lastCommentUserId && (
            <p className="post-caption ms-1 m-0">
              {lastCommentUserProfilePicture ? (
                <ProfileMiniImg
                  width="32px"
                  height="32px"
                  borderRadius="50%"
                  src={media_base_url + lastCommentUserProfilePicture}
                  alt={lastCommentUserFirstName}
                />
              ) : (
                <img
                  width={32}
                  height={32}
                  src={placeholder_round}
                  alt={lastCommentUserFirstName}
                />
              )}

              <strong className="ms-2">
                {lastCommentUserFirstName} {lastCommentUserLastName}
              </strong>
              <span className="ms-2">
                {truncateString(lastCommentText, 220)}
              </span>
            </p>
          )}
          {/* checking contact exist in post */}
          {lastCommentUserId && (
            <button
              disabled={!readComments}
              className="p-1 rounded ms-1 mt-3 mb-3"
              onClick={() => selectedPostHandler(postData, "comment")}
            >
              <CustomLink
                fontSize="14px"
                color={tertiaryGrey20}
                link="#"
                title={`View all ${totalComments} Comments`}
                textDecoration="none"
              />
            </button>
          )}
        </div>
      </Card>
    </PostStyle>
  );
};

export default Post;


