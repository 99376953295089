import { PostList4Styled } from "./style";
import post from "../../assets/images/ic_image_2.png";
import CustomButton from "../../components/CustomButton/CustomButton";
import { fontFamilyMedium, lightBlue2 } from "../../components/GlobalStyle";
import dropDown from "../../assets/icons/ic_drop_down.svg";
import icon1 from "../../assets/icons/ic_newsfeed_like.svg";
import icon2 from "../../assets/icons/ic_newsfeed_saved.svg";
import icon3 from "../../assets/icons/ic_newsfeed_sent.svg";
import { Col, Row } from "react-bootstrap";

const Post_List1 = ({ setIsPost4Modal }: any) => {
  return (
    <PostList4Styled>
      <div className="inner-section">
        <img src={post} alt="" className="main-img" />

        <p className="say-something">Say something about these photos...</p>

        <Row className="mt-4">
          <Col className="p-1" xs={3}>
            <img src={post} alt="" className="post-img" />
          </Col>
          <Col className="p-1" xs={3}>
            <img src={post} alt="" className="post-img" />
          </Col>
          <Col className="p-1" xs={3}>
            <img src={post} alt="" className="post-img" />
          </Col>
          <Col className="p-1" xs={3}>
            <div className="position-relative">
              {" "}
              <img src={post} alt="" />
              <p className="position-absolute more-img-text">3+</p>
              <div className="overlay"></div>
            </div>
          </Col>
        </Row>
        <div className="choose-section d-flex flex-wrap justify-content-between">
          <div className="d-flex">
            <p className="gallery">Gallery</p>
            <img className="mb-2 ms-1" src={dropDown} alt="" />
          </div>
          <div className="choose-section-icons mt-xs-2 mt-sm-0">
            <img src={icon1} alt="" />
            <img src={icon2} alt="" />
            <img src={icon3} alt="" />
            <img src={icon1} alt="" />
            <img src={icon2} alt="" />
            <img src={icon3} alt="" />
          </div>
        </div>
        <div className="submit-btn mt-2">
          <CustomButton
            bgcolor={lightBlue2}
            color="white"
            padding="10px 8px"
            width="100%"
            type="submit"
            title="NEXT"
            margin="auto"
            fontSize="18px"
            fontFamily={fontFamilyMedium}
            clicked={() => setIsPost4Modal(false)}
          />
        </div>
      </div>
    </PostList4Styled>
  );
};

export default Post_List1;
