import { useRef, useState } from "react";
import { Formik } from "formik";
import { Form, Modal } from "antd";
import ic_logo from "../../../assets/icons/ic_logo.svg";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import {
  BlackDot,
  fontFamilyMedium,
  lightColor1,
  mediaDeviceMax,
  primaryColor,
  pureDark,
  tertiaryGrey9,
  TertiraryHeading,
  UnderlineRed,
} from "../../../components/GlobalStyle";
import * as Yup from "yup";
import CustomButton from "../../../components/CustomButton/CustomButton";
import FormControl from "../../../components/FormControl";
import Headbar from "../../../components/Headbar/Headbar";
import Head from "../../../components/Head/Head";
import {
  authorizationToken,
  mpac_create_property_url,
} from "../../../utils/api_urls";
import { toast } from "react-toastify";
import axios from "axios";
import { useAppSelector } from "../../../app/hooks";
import { Autocomplete, Marker, GoogleMap } from "@react-google-maps/api";
import { AutoCompleteMarksStyle } from "./style";
import { usePropertyContext } from "../../../context/PropertyContext";
import { getCountryCode } from "countries-list";
import styled from "styled-components";

const libraries: (
  | "places"
  | "drawing"
  | "geometry"
  | "localContext"
  | "visualization"
)[] = ["places"]; // Move libraries array outside of the component

type locationDataTypes = {
  state: string;
  city: string;
  country: string;
  longitude: string;
  latitude: string;
};
const CreateProperty = () => {
  const { locationsId } = useParams();
  const [isModalVisibled, setIsModalVisibled] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const mpacCardChargeToastId = useRef<any>(null);
  const { data: loginData } = useAppSelector((state) => state.loginData);
  const {
    setMpacCreatePropertyData,
    setCreatePropertyData,
    setPropertyLocation,
  } = usePropertyContext();
  const formikRef = useRef<any>(null);

  const handleModalCancel = () => {
    setIsModalVisibled(false);
  };

  // maps marker data
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [markerPosition, setMarkerPosition] =
    useState<google.maps.LatLng | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);

  const onLoadAutocomplete = (
    autocomplete: google.maps.places.Autocomplete
  ) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      console.log({ place });
      if (place && place.geometry && place.geometry.location) {
        setMarkerPosition(place.geometry.location);
        map?.setCenter(place.geometry.location);
        map?.setZoom(15);
        console.log(
          place.geometry.location.lat(),
          place.geometry.location.lng(),
          "longitude and latitude"
        );

        // Extract street name, street number, and postal code
        let streetName = "";
        let streetNumber = "";
        let postalCode = "";
        let state = "";
        let city = "";
        let country = "";
        let longitude = "";
        let latitude = "";

        longitude = place.geometry.location.lng().toString();
        latitude = place.geometry.location.lat().toString();

        // Loop through the address components to extract required data
        (place.address_components ?? []).forEach((component) => {
          const types = component.types;
          if (types.includes("route")) {
            streetName = component.long_name;
          } else if (types.includes("street_number")) {
            streetNumber = component.long_name;
          } else if (types.includes("postal_code")) {
            postalCode = component.long_name;
          } else if (types.includes("administrative_area_level_1")) {
            state = component.long_name;
          } else if (
            types.includes("locality") ||
            types.includes("sublocality")
          ) {
            city = component.long_name;
          } else if (types.includes("country")) {
            country = component.long_name;
          }
          setPropertyLocation({
            state: state ? state : city,
            city: city ? city : state,
            country,
            longitude,
            latitude,
          });
        });
        console.log("Address: ", { place });
        const address = place.formatted_address;
        // Combine extracted data into a formatted address
        const extractedAddress = `${streetNumber} ${streetName}, ${postalCode}`;
        console.log(
          "Extracted Address:aa ",
          { streetNumber },
          { streetName },
          { postalCode, city: city ? city : "London", state },
          extractedAddress
        );
        if (formikRef.current !== null) {
          formikRef.current.setFieldValue("address", address);
          formikRef.current.setFieldValue("streetNumber", streetNumber);
          formikRef.current.setFieldValue("streetName", streetName);
          formikRef.current.setFieldValue("postalCode", postalCode);
        }
      }
    }
  };

  // on map load
  const onMapLoad = (map: google.maps.Map) => {
    setMap(map);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latLng = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );
          setMarkerPosition(latLng);
          console.log(
            position.coords.latitude,
            position.coords.longitude,
            "latitude",
            "longitude"
          );
          map?.setCenter(latLng);
          map?.setZoom(15);
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation not supported");
    }
  };

  const userCountry = getCountryCode(
    loginData?.userDetails.countryName ?? "Canada"
  );
  // Customize the style of the suggestions
  const autocompleteOptions = {
    componentRestrictions: {
      country: userCountry ? userCountry.toLowerCase() : "ca",
    }, // Restrict suggestions to Canada only
    // types: ["address"], // Restrict suggestions to addresses only
    // fields: ["formatted_address", "geometry"], // Retrieve formatted address and geometry
    // Add any other options as needed
  };

  type initialValueTypes = {
    address?: string;
    streetNumber: string;
    streetName: string;
    postalCode: string;
  };
  const initialValues: initialValueTypes = {
    address: "",
    streetNumber: "",
    streetName: "",
    postalCode: "",
  };

  const validationSchema = Yup.object({
    address: Yup.string().required("Address is required"),
    streetNumber: Yup.string(),
    streetName: Yup.string(),
    postalCode: Yup.string(),
  });

  // handle sumbit

  const handleSubmit = async (values: initialValueTypes) => {
    const createPropertyValues = {
      ...values,
    };
    delete createPropertyValues.address;
    try {
      setLoading(true);
      const { data } = await axios.post(
        mpac_create_property_url,
        {
          ...createPropertyValues,
          streetType: "ST",
          streetDirection: "E",
          municipality: "SCARBOROUGH",
          unitDesignator: null,
          unitNumber: null,
          province: "ON",
          reportType: "ADDRESS",
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setLoading(false);
      console.log(data.results.results[0].availableReports, "reports");
      setMpacCreatePropertyData(data.results);
      mpacCardChargeToastId.current = toast(data.responseMessage, {
        type: "success",
        autoClose: 1000,
      });
      setLoading(false);
      if (
        data.results.results[0].availableReports.length === 1 &&
        data.results.results[0].availableReports.includes("FREE")
      ) {
        setCreatePropertyData(values);
        navigate("/property-info");
      } else {
        navigate("/create-property-plans");
      }

      console.log({ data });
    } catch (error: any) {
      console.log({ error });
      setCreatePropertyData(values);
      mpacCardChargeToastId.current = toast(
        error.response.data.responseMessage,
        {
          type: "error",
          autoClose: 1000,
        }
      );
      navigate("/property-info");
      setLoading(false);
    }
  };

  return (
    <CreatePropertyStyle>
      <Head title="create-property" />
      <Modal
        open={isModalVisibled}
        footer={null}
        onCancel={handleModalCancel}
        centered={true}
      >
        <div className="login-container-card text-center">
          <div className="login-container-card-logo">
            <img src={ic_logo} alt="ic_logo" className="logo" />
          </div>
          <h5 className="question-text mt-3 fs-5">
            Location Updated Successfully
          </h5>
        </div>
      </Modal>
      <Container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={formikRef} // Assign the Formik instance to the ref
        >
          {(formik) => {
            console.log(formik.values, "formik values");
            return (
              <div className="left-side px-2">
                <TertiraryHeading>List a Property</TertiraryHeading>
                <div className="d-flex mt-2 mb-3">
                  <UnderlineRed />
                  <BlackDot />
                </div>
                <div className="left-side-inner-section mt-2">
                  <Form
                    className="mt-2"
                    name="basic"
                    onFinish={formik.handleSubmit}
                    autoComplete="off"
                  >
                    <div className="left-side-input-fields">
                      <AutoCompleteMarksStyle>
                        <div className="mt-2">
                          <Autocomplete
                            options={autocompleteOptions}
                            onLoad={onLoadAutocomplete}
                            onPlaceChanged={onPlaceChanged}
                          >
                            <input
                              name="address"
                              type="text"
                              placeholder="Search Location..."
                            />
                          </Autocomplete>
                        </div>
                      </AutoCompleteMarksStyle>
                      {formik.errors.address && formik.touched.address && (
                        <p className="text-danger mt-2">
                          {formik.errors.address}
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        height: "400px",
                        width: "100%",
                        marginTop: "12px",
                      }}
                    >
                      <GoogleMap
                        mapContainerStyle={{ height: "100%", width: "100%" }}
                        center={markerPosition || undefined}
                        zoom={15}
                        onLoad={onMapLoad}
                      >
                        {markerPosition && (
                          <Marker
                            position={markerPosition}
                            onLoad={(marker) => {
                              markerRef.current = marker;
                            }}
                          />
                        )}
                      </GoogleMap>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      <CustomButton
                        bgcolor={primaryColor}
                        color="white"
                        padding="6px"
                        width="200px"
                        type="submit"
                        title="Confirm Address"
                        fontSize="16px"
                        textTransform="Captilize"
                        loading={loading}
                        fontFamily="EnnVisions"
                      />
                    </div>
                  </Form>
                </div>
              </div>
            );
          }}
        </Formik>
      </Container>
    </CreatePropertyStyle>
  );
};

export default CreateProperty;

const CreatePropertyStyle = styled.div`
  background-color: ${tertiaryGrey9};
  padding-top: 26px;

  @media screen and (max-width: 768px) {
    height: 100%;
  }

  .left-side {
    max-width: 560px;
    margin: 0 auto;
    &-inner-section {
      border-radius: 6px;
      @media ${mediaDeviceMax.mobileL} {
        padding: 0px;
      }
    }
    .title {
      color: ${pureDark};
    }

    .text-start {
      font-size: 14px;
      color: ${pureDark};
      font-family: ${fontFamilyMedium};
      margin: 10px 0;
    }
  }
`;
