import styled from "styled-components";
import {
  fontFamilyMedium,
  primaryColor,
  tertiaryDark2,
  tertiaryGrey10,
  whiteColor,
  lightColor1,
  lightDark,
  lightGrey10,
  lightGrey2,
  pureDark,
  tertiaryGrey19,
  tertiaryGrey23,
  primaryRed,
} from "../../components/GlobalStyle";

const StoryStyle = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  /* background-color: ${tertiaryDark2}; */
  .inner-container {
    /* display: grid;
    column-gap: 45px;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr) 2fr repeat(2, 1fr);
    align-items: center; */
    width: 100%;
  }
  .story-arrows {
    background-color: red;
    .slide-img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .prev-slide-img {
      position: absolute;
      left: -8%;
      top: 37%;
      z-index: 30;
    }
    .next-slide-img {
      position: absolute;
      right: -8%;
      top: 37%;
      z-index: 30;
    }
  }

  .story {
    height: 242px;
    position: relative;
    .big-img {
      height: 100%;
    }
    .profile-details {
      left: 10px;
      bottom: 0;
      position: absolute;
      width: 100%;
      margin-top: 2%;
      .title {
        font-size: 14px;
        color: white;
        font-family: "EnnVisionsMedium";
      }
    }
  }
  .big-story {
    height: 100vh;
    position: relative;
    width: 500px;
    .icons {
      .like,
      .send {
        height: 44px;
        width: 44px;
      }
      img:not(:last-child) {
        margin-right: 10px;
      }
    }
    .big-img {
      height: 100vh;
    }
    .profile-details {
      padding: 10px;

      .title {
        font-size: 14px;
        color: white;
        font-family: "EnnVisionsMedium";
      }
      input {
        border-radius: 20px;
        padding: 10px;
        background-color: transparent;
        border: 1px solid #ffffff;
        color: #ffffff;

        &::placeholder {
          padding: 10px;
          color: #ffffff;
        }
      }
    }
  }
`;
export default StoryStyle;

export const MiniStoryStyle = styled.div`
  @media screen and (max-width: 1024px) {
    background-color: red;
    .miniImg1 {
      display: none !important;
    }
    .miniImg2 {
      column-span: span 2;
    }
    .miniImg3 {
    }
    .miniImg4 {
      display: none !important;
    }
  }
  @media screen and (max-width: 768px) {
    .miniImg2 {
      column-span: span 2;
    }
    .miniImg3 {
    }
  }
  @media screen and (max-width: 480px) {
    .miniImg1 {
      display: none !important;
    }
    .miniImg2 {
      display: none !important;
    }
    .miniImg3 {
      display: none !important;
    }
    .miniImg4 {
      display: none !important;
    }
  }
  .story {
    margin-right: 10px;
    position: relative;
    .profile-details {
      left: 10px;
      bottom: 0;
      .title {
        font-size: 14px;
        color: white;
        font-family: "EnnVisionsMedium";
      }
    }
  }
`;
export const BigStoryStyle = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;

  .threeDots{
    border: 1px solid black;
    padding-left: 5px;
    border-radius: 4px;
  }
  video {
    max-width: 540px;
    height: 98vh !important;
    object-fit: cover;
    display: flex;
    justify-content: center;
    border-radius: 7px;
    width: 100%;
  }

  .big-story {
    height: 98vh;
    position: relative;
    width: 430px;

    .indicators {
      gap: 10px;
      position: absolute;
      width: 96%;
      margin-top: 1%;
      img {
        cursor: pointer;
      }
      .indicator {
        height: 4px;
        border-radius: 10px;
        margin-top: 10px;
        &:first-child {
          margin-left: 10px;
        }
        &:last-child {
          margin-right: 10px;
        }
      }
      .indicator-fill {
        background-color: ${tertiaryGrey10};
      }
      .indicator-null {
        background-color: ${tertiaryDark2};
      }
    }

    .status-text {
      word-break: break-all;
      padding: 0 10px;
      /* background-image: linear-gradient(
        to bottom right,
        ${primaryColor},
        #640916
      ); */
      background-color: ${primaryColor};
      border-radius: 7px;
      h2 {
        color: white;
        text-align: center;
      }
    }

    .vide-section {
      height: 94vh;
      video {
        height: 94vh;
      }
    }

    .icons {
      img:not(:last-child) {
        margin-right: 10px;
      }
    }

    .big-img {
      height: 98vh;
      object-fit: cover;
      border-radius: 10px;
    }

    .profile-details {
      padding: 10px;
      position: absolute;
      width: 100%;
      margin-top: 20px;
      z-index: 10;

      .title {
        font-size: 14px;
        color: white;
        font-family: ${fontFamilyMedium};
      }

      .add-comment {
        border-radius: 20px;
        padding: 10px;
        padding: 10px;
        background-color: transparent;
        border: 1px solid ${whiteColor};
        color: ${whiteColor};

        &::placeholder {
          padding: 10px;
          color: ${whiteColor};
        }
      }
    }
  }
`;

// create story styles
export const CreateStoryStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 86px);

  .inner-section {
    width: 400px;
    background: ${whiteColor};
    border-radius: 6px;
    margin: 0 auto;
    height: calc(100vh - 150px);
    position: relative;
    .create-story-btn {
      position: absolute;
      bottom: 2%;
      z-index: 12;
      left: 50%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .b-refs-button {
    }
    .caption-section {
      height: 66vh;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    .media-post-item {
      height: 66vh;
      object-fit: cover;
    }
    video {
      height: 100vh;
    }
    .slider-item {
      position: relative;
      z-index: 2;

      .play-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 12;
      }

      .close-icon {
        right: 2%;
        top: 2%;
        position: absolute;
        border-radius: 50%;
        background-color: ${lightDark};
        cursor: pointer;
      }
    }
    video {
      width: 100%;
      height: 68vh;
    }

    .media-icon {
      bottom: 4%;
      right: 4%;
      cursor: pointer;
    }
    .media-post {
      bottom: 12%;
      right: 3%;
    }

    @media screen and (max-width: 480px) {
      width: 340px;
      margin: 0 auto;
    }

    .tag-section {
      border: 1px solid ${tertiaryGrey19};
      border-radius: 7px;
      padding: 8px 10px;

      button {
        font-size: 12px;
        padding: 4px;
        border-radius: 4px;
        color: ${pureDark};
        &:hover {
          background: ${primaryColor};
          color: ${whiteColor};
        }
      }
    }

    .post-img {
      max-height: 333px;
    }

    .choose-section {
      margin-top: 16px;
      border-bottom: 1px solid ${lightGrey2};
      margin-bottom: 10px;
      padding: 0 10px;
      .gallery {
        font-size: 18px;
        color: ${pureDark};
        font-family: "EnnVisionsMedium";
      }

      &-icons {
        img {
          background: #f5f5f5;
          border-radius: 3px;
          padding: 6px;
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
    input[type="checkbox"] {
      cursor: pointer;
    }
  }
`;

// create story styles
export const CreateStoryVideoStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 86px);

  .inner-section {
    width: 400px;
    background: ${whiteColor};
    border-radius: 6px;
    margin: 0 auto;
    position: relative;
    .create-story-btn {
      position: absolute;
      bottom: 0;
      z-index: 12;
      left: 50%;
      background-color: transparent;
      transform: translate(-50%, -50%);
      img {
        height: 64px;
      }
    }

    .media-post-item {
      height: 86vh;
      object-fit: cover;
      border-radius: 7px;
    }
  }

  .slider-item {
    position: relative;
    z-index: 2;

    .play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 12;
    }

    .close-icon {
      right: 2%;
      top: 2%;
      position: absolute;
      border-radius: 50%;
      background-color: ${lightDark};
      cursor: pointer;
    }
  }

  @media screen and (max-width: 480px) {
    width: 340px;
    margin: 0 auto;
  }
`;
// create images
export const CreateStoryImagesStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 86px);

  .inner-section {
    width: 400px;
    background: ${whiteColor};
    border-radius: 6px;
    margin: 0 auto;
    position: relative;

    .create-story-btn {
      position: absolute;
      bottom: 0;
      z-index: 12;
      background: transparent;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        height: 64px;
      }
    }

    .media-post-item {
      height: 86vh;
      object-fit: cover;
      border-radius: 7px;
    }
    video {
      height: 100vh;
    }
    .slider-item {
      position: relative;
      z-index: 2;

      .close-icon {
        right: 2%;
        top: 2%;
        position: absolute;
        border-radius: 50%;
        background-color: ${lightDark};
        cursor: pointer;
      }
    }

    @media screen and (max-width: 480px) {
      width: 340px;
      margin: 0 auto;
    }

    .post-img {
      max-height: 333px;
    }
  }
`;

// create story video grid

export const StoryVideosGridStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 86px);

  .media-post-item {
    height: 380px;
    object-fit: cover;
  }
  .slider-item {
    position: relative;

    .close-icon {
      right: 5%;
      top: 3%;
      position: absolute;
      border-radius: 50%;
      background-color: ${lightDark};
      cursor: pointer;
    }
  }
  video {
    width: 100%;
    height: auto;
  }
`;

// create story images grid
export const StoryImagesGridStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 86px);

  .media-post-item {
    height: 380px;
    object-fit: cover;
  }
  .slider-item {
    position: relative;

    .close-icon {
      right: 5%;
      top: 3%;
      position: absolute;
      border-radius: 50%;
      background-color: ${lightDark};
      cursor: pointer;
    }
  }
  video {
    width: 100%;
    height: auto;
  }
`;

// create story types

export const CreateStoryTypeStyle = styled.div`
  height: calc(100vh - 86px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${tertiaryGrey23};
  label {
    cursor: pointer;
  }

  #upload-photos {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  #upload-videos {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  .type-box {
    border-radius: 7px;
    border: 1px solid ${lightGrey2};
    padding: 12px;
    height: 156px;
    width: 200px;
    background-color: ${whiteColor};
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      height: 45px;
    }
    cursor: pointer;
    &-title {
      font-family: ${fontFamilyMedium};
      margin-top: 12px;
      font-size: 14px;
      margin-bottom: 0;
    }
  }
`;

// create story text
export const CreateStoryTextStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 86px);

  .inner-section {
    width: 400px;
    background: ${whiteColor};
    border-radius: 6px;
    margin: 0 auto;
    position: relative;

    .caption-section {
      height: 66vh;
    }

    @media screen and (max-width: 480px) {
      width: 340px;
      margin: 0 auto;
    }
  }
`;
// create story preview

export const CreateStoryPreviewStyle = styled.div`
  height: calc(100vh - 100px);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  .status {
    width: 100%;
    max-width: 460px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${primaryColor};
    height: 80vh;
    border-radius: 7px;
    position: relative;
    &-text {
      font-size: 22px;
      color: ${whiteColor};
      font-family: ${fontFamilyMedium};
    }
    .edit-btn {
      position: absolute;
      right: 1%;
      top: 1%;
      padding: 6px;
    }
    .create-story-btn {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      background: transparent;
      img {
        height: 64px;
      }
    }
  }
`;
