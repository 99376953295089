import AliceCarousel from "react-alice-carousel";
import {
  TertiraryHeading,
  lightColor1,
  primaryColor,
  whiteColor,
} from "../../../components/GlobalStyle";
import ProfessionalPlansStyle from "./style";
import select from "../../../assets/icons/ic_add_property_sel_payment.svg";
import unselectIcon from "../../../assets/icons/ic_add_property_payment.svg";
import { Container } from "react-bootstrap";
import RedBlackUnderline from "../../../components/RedBlackUnderline/RedBlackUnderline";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../redux/store";
import { useGlobalContext } from "../../../context/context";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  authorizationToken,
  professional_plans,
} from "../../../utils/api_urls";
import Loader from "../../../components/Loader/Loader";
import NoDataAvailable from "../../../components/NoData/NoDataAvailable";
import { professionalPlanTypes } from "../../../redux/features/types";

const responsive = {
  0: { items: 1.15 },
  568: { items: 2.15 },
  1024: { items: 3 },
};

const ProfessionalPlans = () => {
  const navigate = useNavigate();
  const {countryName:{results:{name:countryName}}}=useAppSelector(state=>state.appData.data)
  const [professionalPlans, setProfessionalPlans] = useState(
    [] as professionalPlanTypes[]
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { data: loginData } = useAppSelector(
    (state: RootState) => state.loginData
  );
  const { setProfessionalPlan } = useGlobalContext();
  const profesionalPlansPromise = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        professional_plans,
        {
          countryName
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setProfessionalPlans(data.results);
      setLoading(false);
    } catch (error: any) {
      setError(error.response.data.responseMessage);
      setLoading(false);
      console.log(error.response, "error");
    }
  };
  useEffect(() => {
    profesionalPlansPromise();
  }, []);

  const items = [
    ...professionalPlans.map((professionalPlan) => {
      const {
        description: plan_description,
        getProfessionalPlansSlapsResponse,
        getProfessionalPlansPriceResponse,
        isActive,
        planId,
        planName,
        validityDays,
      } = professionalPlan;
      return (
        <div className="card" key={planId}>
          <div className="d-flex justify-content-between head-bar">
            <div className="d-flex ">
              <h6 className="heading text-center">{planName}</h6>
              {/* <p className="sub-heading text-center mb-0 ms-2">
                {validityDays}
              </p> */}
            </div>
            {planName === "3 Months Free Trial" ? (
              <h5>Free</h5>
            ) : (
              <h5 className="price mb-0 text-center">
                {getProfessionalPlansPriceResponse[0].currencySymbol}{" "}
                {getProfessionalPlansPriceResponse[0].subscriptionAmount}{" "}
                {getProfessionalPlansPriceResponse[0].currency}
              </h5>
            )}
          </div>
          <div className="offers">
            {getProfessionalPlansSlapsResponse.map(
              ({ slabId, slabName, description, isActive }) => (
                <div key={slabId} className="offer ">
                  <img
                    src={isActive ? select : unselectIcon}
                    alt={`${select ? "select" : "unselect"}`}
                  />
                  <p className="mb-0 offer-type">{slabName}</p>
                </div>
              )
            )}
          </div>
          <button
            disabled={
              loginData?.planSubscriptionDetails &&
              loginData?.planSubscriptionDetails?.planId &&
              planId <= loginData?.planSubscriptionDetails?.planId
                ? true
                : false
            }
            style={{
              backgroundColor:
                loginData?.planSubscriptionDetails &&
                loginData?.planSubscriptionDetails?.planId &&
                planId <= loginData?.planSubscriptionDetails?.planId
                  ? lightColor1
                  : primaryColor,
              color:
                loginData?.planSubscriptionDetails &&
                loginData?.planSubscriptionDetails?.planId &&
                planId <= loginData?.planSubscriptionDetails?.planId
                  ? "black"
                  : whiteColor,
            }}
            className={`purchase-btn rounded w-100`}
            onClick={() => {
              setProfessionalPlan(professionalPlan);
              navigate("/bill-info", { state: { link: "/confirmed-dark" } });
            }}
          >
            Start With {planName}
          </button>
        </div>
      );
    }),
  ];

  return (
    <>
      <ProfessionalPlansStyle>
        <Container style={{height:"500px"}}>
          <TertiraryHeading>Professional Package</TertiraryHeading>
          <div className="d-flex mt-2">
            <RedBlackUnderline />
          </div>
          {loading ? (
            <Loader />
          ) : error ? (
            <NoDataAvailable />
          ) : (
            <div className="cards">
              <AliceCarousel
                mouseTracking
                items={items}
                responsive={responsive}
                disableDotsControls
                disableButtonsControls
                controlsStrategy="alternate"
              />
            </div>
          )}
        </Container>
      </ProfessionalPlansStyle>
    </>
  );
};

export default ProfessionalPlans;
