import { CreateStoryTypeStyle } from "./style";
import add_text from "../../assets/icons/ic_post_text.svg";
import select_img from "../../assets/icons/ic_post_image.svg";
import select_video from "../../assets/icons/ic_post_video.svg";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useStoryContext } from "../../context/StoryContext";

const CreateStoryType = () => {
  const { createStoryImages, setCreateStoryImages, setCreateStoryVideos } =
    useStoryContext();
  const navigate = useNavigate();

  const onImageFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const imageFileList = Array.from(e.target.files);
      setCreateStoryImages(imageFileList);
      console.log({ imageFileList });
      navigate("/create-story-images");
    }
  };

  // on video file selected
  const onVideoFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const videofileList = Array.from(e.target.files);
      console.log({ videofileList });
      setCreateStoryVideos(videofileList);
      navigate("/create-story-video");
    }
  };

  console.log({ createStoryImages });
  return (
    <CreateStoryTypeStyle>
      <Container>
        <Row className="d-flex justify-content-center gap-4">
          {/* <Col sm={6} className="type-box text-center">
            <label
              htmlFor="story-text"
              onClick={() => navigate("/create-story-text")}
            >
              <img src={add_text} alt="select-img" />
              <p className="type-box-title">Create Post With Text</p>
            </label>
          </Col> */}
          <Col sm={6} className="type-box text-center">
            <label htmlFor="upload-photos">
              <img src={select_img} alt="select-img" />
              <p className="type-box-title">Create Post With Image</p>
            </label>
            <input
              type="file"
              name="photos"
              id="upload-photos"
              multiple
              onChange={onImageFileChange}
              accept="image/*"
            />
          </Col>
          <Col sm={6} className="type-box text-center">
            <label htmlFor="upload-videos">
              <img src={select_video} alt="select-video" />
              <p className="type-box-title">Create Post With Video</p>
            </label>
            <input
              type="file"
              name="video"
              id="upload-videos"
              multiple
              onChange={onVideoFileChange}
              accept="video/*"
            />
          </Col>
        </Row>
      </Container>
    </CreateStoryTypeStyle>
  );
};

export default CreateStoryType;

// read file by input type file
function readFile(file: File): Promise<string> {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => resolve(reader.result as string),
      false
    );
    reader.readAsDataURL(file);
  });
}
