import CustomButton from "../../../components/CustomButton/CustomButton";
import { Navigate, useNavigate } from "react-router-dom";
import CreatePropertyPlansStyle from "./style";
import { Col, Container, Row } from "react-bootstrap";
import {
  BlackDot,
  fontFamilyMedium,
  primaryColor,
  TertiraryHeading,
  UnderlineRed,
} from "../../../components/GlobalStyle";
import redTickIcon from "../../../assets/icons/ic_select_red.svg";
import dullRedTickIcon from "../../../assets/icons/ic_select_gry.svg";
import Head from "../../../components/Head/Head";
import { useAppSelector } from "../../../app/hooks";
import { useEffect, useState } from "react";
import HeadBarPriceDiscount from "../../../components/Headbar/HeadBarPriceDiscount";
import { mpacAvailableListingPlanTypes } from "../../../redux/features/types";
import { usePropertyContext } from "../../../context/PropertyContext";
import useMediaArea from "../../../hooks/useMediaArea";

const CreatePropertyPlans = () => {
  const navigate = useNavigate();
  const [listingPlansData, setListingPlansData] = useState(
    [] as mpacAvailableListingPlanTypes[]
  );
  const { height } = useMediaArea();
  const { mpacCreatePropertyData, setCreatePropertyPlan } =
    usePropertyContext();
  // app data from redux
  const { mpacAvailableListingPlan } = useAppSelector(
    (state) => state.appData.data
  );

  // add create property data
  const handleProperty = (plan_id: number) => {
    const selectedPlan = listingPlansData.find(
      ({ planId }) => planId == plan_id
    );
    if (selectedPlan) {
      setCreatePropertyPlan(selectedPlan);
    }
    navigate("/create-property-reports");
  };

  useEffect(() => {
    // check if mpac create property data is not null
    if (Object.keys(mpacCreatePropertyData!).length !== 0) {
      const filteredListingPlans = mpacAvailableListingPlan.filter(
        ({ identifierName }) =>
          mpacCreatePropertyData!.results[0].availableReports.includes(
            identifierName
          )
      );
      setListingPlansData(filteredListingPlans);
    }
  }, []);

  // check if mpac create property data is null

  if (Object.keys(mpacCreatePropertyData!).length === 0)
    return <Navigate to="/create-property" replace />;

  return (
    <CreatePropertyPlansStyle height={height}>
      <Head title="create-property" />
      <Container>
        <TertiraryHeading>List a Property</TertiraryHeading>
        <div>
          <UnderlineRed />
          <BlackDot />
        </div>
        <Row>
          {listingPlansData.map(
            ({
              identifierName,
              propertySlabs,
              planName,
              subscriptionAmount,
              currency,
              currencySymbol,
              planId,
              subTotal,
              displayName,
            }) => (
              <Col key={planId} md={6} className="property-card">
                <HeadBarPriceDiscount
                  currencySymbol={currencySymbol}
                  displayName={displayName}
                  subTotal={subTotal}
                  subscriptionAmount={subscriptionAmount}
                />
                <div className="property-card-inner-section">
                  <div className="property-card-inner-section-items">
                    {propertySlabs.map(({ isActive, slabId, slabName }) => (
                      <div key={slabId} className="d-flex feature mt-2">
                        <img
                          src={isActive ? redTickIcon : dullRedTickIcon}
                          alt={isActive ? "check-icon" : "uncheck-icon"}
                        />
                        <p className="feature-text ms-2 mb-0">{slabName}</p>
                      </div>
                    ))}
                  </div>

                  <div className="mt-3">
                    <CustomButton
                      bgcolor={primaryColor}
                      color="white"
                      padding="8px"
                      width="100%"
                      type="submit"
                      title="Start your listing"
                      margin="auto"
                      fontSize="16px"
                      fontFamily={fontFamilyMedium}
                      clicked={() => handleProperty(planId)}
                    />
                  </div>
                </div>
              </Col>
            )
          )}
        </Row>
      </Container>
    </CreatePropertyPlansStyle>
  );
};

export default CreatePropertyPlans;
