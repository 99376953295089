import { Card } from "react-bootstrap";
import StoriesListStyle from "../../components/StoriesList/style";
import { StoriesList } from "../User/types";
import { BackgroundImage } from "../../components/GlobalStyle";
import { media_base_url } from "../../utils/api_urls";
import { truncateString } from "../../utils/utilities";
import { Dispatch, SetStateAction, useState } from "react";
import { useGlobalContext } from "../../context/context";
import StorySlider from "../User/ViewProfile/StorySlider";

type StoriesProps = {
  stories: StoriesList[];
  isOpenModal?: boolean;
  setIsLoginModal?: Dispatch<SetStateAction<boolean>>;
};
const Stories: React.FC<StoriesProps> = ({
  stories,
  isOpenModal = true,
  setIsLoginModal,
}) => {
  const [isShowStories, setIsShowStories] = useState(false);
  const { setSlideIndex } = useGlobalContext();

  console.log({ isShowStories });
  // opening story modal
  const openStoryModal = (index: number) => {
    if (isOpenModal) {
      setSlideIndex(index);
      setIsShowStories(true);
    } else {
      setIsLoginModal!(true);
    }
  };

  return (
    <>
      {isShowStories && (
        <StorySlider
          setIsShowStories={setIsShowStories}
          isShowStories={isShowStories}
          userStoriesData={stories}
        />
      )}
      <StoriesListStyle>
        {stories.map((storyData, index) => {
          const {
            ownerData: { profilePicture },
            storyText,
            imagesURLS,
            videosURLS,
            storyId,
          } = storyData;
          console.log({ storyText, imagesURLS, videosURLS });
          return (
            <div key={storyId}>
              <div id="overlay">
                {storyText && storyText.trim() ? (
                  <Card
                    className="story-card d-flex justify-content-center align-items-center"
                    onClick={() => openStoryModal(index)}
                  >
                    <div className="status-text d-flex align-items-center justify-content-center">
                      <h6 className="mb-0">{truncateString(storyText, 60)}</h6>
                    </div>
                  </Card>
                ) : imagesURLS ? (
                  <Card className="story-card">
                    <BackgroundImage
                      key={index}
                      className="card-img-top"
                      repeat="no-repeat"
                      url={media_base_url + imagesURLS}
                      size="cover"
                      postion="center"
                      height="144px"
                      width="100px"
                      onClick={() => openStoryModal(index)}
                    />
                    {/* <Card.Body className="position-absolute bottom-0">
                    <Card.Title>
                      {firstName} {lastName}
                    </Card.Title>
                  </Card.Body> */}
                  </Card>
                ) : (
                  <Card className="story-card">
                    <video
                      id="video1"
                      className="video-section"
                      onClick={() => openStoryModal(index)}
                    >
                      <source
                        src={media_base_url + videosURLS}
                        type="video/mp4"
                      />
                      Your browser does not support HTML video.
                    </video>
                  </Card>
                )}
              </div>
            </div>
          );
        })}
      </StoriesListStyle>
    </>
  );
};

export default Stories;
