import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import {
  authorizationToken,
  professional_details_url,
} from "../../../utils/api_urls";
import { professionalDetailTypes } from "./types";

type initialStateTypes = {
  loading: boolean;
  result: professionalDetailTypes;
  error: string;
};
const initialState: initialStateTypes = {
  loading: true,
  result: {} as professionalDetailTypes,
  error: "",
};

// Generates pending, fulfilled and rejected action types
export const getprofessionalDetail = createAsyncThunk(
  "admin/professional/details",
  async (userId: number, thunkAPI) => {
    const state: any = thunkAPI.getState();
    try {
      const { data } = await axios.post(
        professional_details_url,
        {
          userId,
        },
        {
          headers: {
            ...authorizationToken(state.loginData.data),
          },
        }
      );
      console.log(data, "professional details");
      return data.results;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.data.response.errorMessage || "Something went wrong"
      );
    }
  }
);

const professionalDetailTypesSlice = createSlice({
  name: "professionalProfileDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getprofessionalDetail.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getprofessionalDetail.fulfilled,
      (state, action: PayloadAction<professionalDetailTypes>) => {
        state.result = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(
      getprofessionalDetail.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
  },
});

export default professionalDetailTypesSlice.reducer;
