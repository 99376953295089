import placeholder from "../../../assets/icons/ic_use_placeholder.svg";
import soldPropertyIcon from "../../../assets/icons/ic_professional_post.svg";
import person from "../../../assets/icons/ic_professional_meet_peron.svg";
import { Col, Row } from "react-bootstrap";
import { media_base_url } from "../../../utils/api_urls";
import { ProfesseionalProfileBoxStyle } from "./style";

type professeionalProfileBoxProps = {} & professionalProfileTypes;

const ProfesseionalProfileBox: React.FC<professeionalProfileBoxProps> = ({
  firstName,
  lastName,
  userName,
  totalProperties,
  totalSoldProperties,
  countryCode,
  phoneNumber,
  profilePictureURL,
  totalFollowers,
  totalFollowing,
}) => {
  return (
    <ProfesseionalProfileBoxStyle>
      <div className="owner-details">
        <Row>
          <Col md={6} lg={8}>
            <div className="d-flex justify-content-sm-start align-items-center flex-wrap ">
              <label className="label">
                {/* <input
                  type="file"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    let files = event.target.files;
                    if (files) {
                      //   setProfileImage(files[0]);
                    }
                  }}
                /> */}
                <span>
                  <img
                    src={
                      profilePictureURL
                        ? media_base_url + profilePictureURL
                        : placeholder
                    }
                    alt={firstName}
                    className="profile-img"
                  />
                </span>
              </label>

              <div className="bio ms-3">
                <h6>
                  {firstName} {lastName}
                </h6>
                <p className="branch">{userName}</p>
                <p className="location mb-0">
                  {countryCode}
                  {phoneNumber}
                </p>
              </div>
            </div>
          </Col>
          <Col md={6} lg={4}>
            <div className="sold-properties">
              <Row className="align-items-center">
                <Col xs={6} className="mt-md-0 p-0">
                  <div className="properties d-flex align-items-center justify-content-center justify-content-md-start p-2">
                    <span>
                      <img className="mb-1" src={person} alt="persons" />
                    </span>
                    <p className="quantity ms-2 mb-0">{totalFollowers}</p>
                    <div className="d-flex justify-content-center">
                      <p className="title text-center text-md-start ms-2 mb-0">
                        Followers
                      </p>
                    </div>
                  </div>
                  <div className="properties d-flex align-items-center justify-content-center justify-content-md-start p-2 mt-2">
                    <span>
                      <img
                        className="mb-1"
                        src={soldPropertyIcon}
                        alt="buildings"
                      />
                    </span>
                    <p className="quantity ms-2 mb-0">{totalProperties}</p>
                    <div className="d-flex justify-content-center">
                      <p className="title text-center text-md-start ms-2 mb-0">
                        Available
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={6} className="p-0">
                  <div className="properties d-flex align-items-center justify-content-center justify-content-md-start p-2">
                    <span>
                      <img className="mb-1" src={person} alt="persons" />
                    </span>
                    <p className="quantity ms-2 mb-0">{totalFollowing}</p>
                    <p className="title text-center text-md-start ms-2 mb-0">
                      Following
                    </p>
                  </div>

                  <div className="properties d-flex align-items-center justify-content-center justify-content-md-start p-2 mt-2">
                    <span>
                      <img
                        className="mb-1"
                        src={soldPropertyIcon}
                        alt="building"
                      />
                    </span>
                    <p className="quantity ms-2 mb-0">{totalSoldProperties}</p>
                    <p className="title text-center text-md-start ms-2 mb-0">
                      Sold
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </ProfesseionalProfileBoxStyle>
  );
};

export default ProfesseionalProfileBox;

// professional types

export interface professionalProfileTypes {
  id: number;
  firstName: string;
  lastName: string;
  userName: string;
  profilePictureURL: any;
  status: number;
  emailAddress: string;
  phoneNumber: string;
  countryCode: string;
  totalFollowers: number;
  totalFollowing: number;
  totalVisitRequests: any;
  totalSendRequests: any;
  totalSoldProperties: number;
  totalProperties: number;
  postList: any;
  propertiesList: any;
  likedNewsFeedList: any;
  storiesNewsFeedList: any;
  userNewsFeedList: any;
  favouriteNewsFeedList: any;
  professionalDetails: ProfessionalDetails;
  active: boolean;
  createdAt: string;
}

export interface ProfessionalDetails {
  id: number;
  address: string;
  businessName: string;
  bannerImage: any;
  professionTypeId: number;
  professionalStatusId: number;
}
