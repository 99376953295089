import { Col, Row } from "react-bootstrap";
import FormControl from "../../components/FormControl";
import { useFormikContext } from "formik";
import { RoomsStyle } from "./style";
import { useAppSelector } from "../../app/hooks";
import { generatePropertyInitTypes } from "./types";
import Headbar from "../../components/Headbar/Headbar";
import addFeatureIcon from "../../assets/icons/ic_add_property_general_info_add.svg";
import { usePropertyContext } from "../../context/PropertyContext";

const Rooms = () => {
  const { values, setFieldValue, errors, touched } =
    useFormikContext<generatePropertyInitTypes>();
  const { propertyRoomName } = useAppSelector((state) => state.appData.data);
  const propertyRoomNameOptions =[...propertyRoomName].sort((a, b) => a.displayName.localeCompare(b.displayName)).map(
    ({ displayName }, index) => ({
      value: displayName,
      label: displayName,
    })
  );
  const groundFloor=["Living room","Kitchen","Office","Attached Garage / Detached Garage","Laundry","Front Hall / Entrance",
    "Family Room","Bath Room","Bedroom","Laundry Room","Pantry","Sun Room","Walk-in closet","Primary Suite","Dining Room","Studio",
  "Cold Room/Wine Cellar","Utility Room"]
  const secondFloor=["Living room","Attic","Office","Laundry",
    "Family Room","Bath Room","Bedroom","Laundry Room","Primary Suite","Studio",
  "Utility Room"]
  const ThirdFloor=["Office","Attic","Laundry",
    "Family Room","Bath Room","Bedroom","Laundry Room","Primary Suite","Studio",
"Utility Room"]

  //   property rooms info
  const {
    groundFloorInfoItems,
    setGroundFloorInfoItems,
    firstFloorInfoItems,
    setFirstFloorInfoItems,
    secondFloorInfoItems,
    setSecondFloorInfoItems,
    thirdFloorInfoItems,
    setThirdFloorInfoItems,
  } = usePropertyContext();

  // limit to the max value if it's greater
  const limitUptoMax = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const maxValue = 11;
    const keyPressed = event.key;
    const currentValue = event.currentTarget.value + keyPressed;

    // If the pressed key is '-' and the input value is empty or starts with '-',
    // prevent the key press
    if (
      keyPressed === "-" &&
      (currentValue === "" || currentValue.includes("-"))
    ) {
      event.preventDefault();
      return;
    }

    if (Number(currentValue) > maxValue) {
      event.preventDefault();
    }
  };

  // prevent minus values
  const preventMinusValue = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyPressed = event.key;
    const currentValue = event.currentTarget.value + keyPressed;

    // If the pressed key is '-' and the input value is empty or starts with '-',
    // prevent the key press
    if (
      keyPressed === "-" &&
      (currentValue === "" || currentValue.includes("-"))
    ) {
      event.preventDefault();
      return;
    }
  };

  return (
    <RoomsStyle>
      <Headbar
        title="Ground Floor (Optional)"
        icon={addFeatureIcon}
        doTask={() =>
          setGroundFloorInfoItems([
            ...groundFloorInfoItems,
            groundFloorInfoItems.length,
          ])
        }
      />
      <div className="rooms-box">
        {groundFloorInfoItems.map((_, index) => (
          <Row className="align-items-center" key={index}>
            <Col sm={6} md={4} className="mt-2">
              <label
                htmlFor="room-name"
                className={`mb-2 ${index == 0 ? "d-block" : "d-md-none"}`}
              >
                Room Name
              </label>
              <FormControl
                control="selects"
                showLabel={false}
                name="selectRoom"
                placeholder="Select Room"
                value={
                  values?.groundFloorInfo?.[index]?.name
                    ? propertyRoomName.find(
                        ({ keyName }) =>
                          values?.groundFloorInfo?.[index]?.name === keyName
                      )?.displayName
                    : ""
                }
                options={propertyRoomNameOptions.filter(room => groundFloor.includes(room.value))}
                onChange={(value: string) => {
                  setFieldValue(
                    `groundFloorInfo.${index}.${"name"}`,
                    propertyRoomName.find(
                      ({ displayName }) => displayName === value
                    )?.keyName
                  );
                }}
                showErroMessage={false}
                className={
                  errors.propertySubTypeId && touched.propertySubTypeId
                    ? "is-invalid"
                    : "customInput"
                }
              />
            </Col>
            <Col sm={6} md={4} className="mt-2">
              <label
                htmlFor="length"
                className={`mb-2 ${index == 0 ? "d-block" : "d-md-none"}`}
              >
                Length
              </label>

              <div className="d-flex" id="length">
                <input
                  type="number"
                  placeholder="Feet"
                  min={0}
                  disabled={!values?.groundFloorInfo?.[index]?.name}
                  onKeyDown={preventMinusValue}
                  value={values?.groundFloorInfo?.[index]?.length?.feet || ""}
                  onChange={(event) => {
                    setFieldValue(
                      `groundFloorInfo.${index}.${"length"}.${"feet"}`,
                      +event.target.value
                    );
                  }}
                />
                <input
                  type="number"
                  placeholder="Inch"
                  min={0}
                  max={11}
                  disabled={!values?.groundFloorInfo?.[index]?.name}
                  onKeyDown={limitUptoMax}
                  value={values?.groundFloorInfo?.[index]?.length?.inch || ""}
                  onChange={(event) => {
                    setFieldValue(
                      `groundFloorInfo.${index}.${"length"}.${"inch"}`,
                      +event.target.value
                    );
                  }}
                />
              </div>
            </Col>
            <Col sm={6} md={4} className="mt-2">
              <label
                htmlFor="width"
                className={`mb-2 ${index == 0 ? "d-block" : "d-md-none"}`}
              >
                Width
              </label>

              <div className="d-flex" id="width">
                <input
                  type="number"
                  placeholder="Feet"
                  min={0}
                  disabled={!values?.groundFloorInfo?.[index]?.name}
                  onKeyDown={preventMinusValue}
                  value={values?.groundFloorInfo?.[index]?.width?.feet || ""}
                  onChange={(event) => {
                    setFieldValue(
                      `groundFloorInfo.${index}.${"width"}.${"feet"}`,
                      +event.target.value
                    );
                  }}
                />
                <input
                  type="number"
                  placeholder="Inch"
                  min={0}
                  max={11}
                  disabled={!values?.groundFloorInfo?.[index]?.name}
                  onKeyDown={limitUptoMax}
                  value={values?.groundFloorInfo?.[index]?.width?.inch || ""}
                  onChange={(event) => {
                    setFieldValue(
                      `groundFloorInfo.${index}.${"width"}.${"inch"}`,
                      +event.target.value
                    );
                  }}
                />
              </div>
            </Col>
          </Row>
        ))}
      </div>
      <div className="mt-3">
        <Headbar
          title="First Floor (Optional)"
          icon={addFeatureIcon}
          doTask={() =>
            setFirstFloorInfoItems([
              ...firstFloorInfoItems,
              firstFloorInfoItems.length,
            ])
          }
        />
      </div>
      <div className="rooms-box">
        {firstFloorInfoItems.map((_, index) => (
          <Row className="align-items-center" key={index}>
            <Col sm={6} md={4} className="mt-2">
              <label
                htmlFor="room-name"
                className={`mb-2 ${index == 0 ? "d-block" : "d-md-none"}`}
              >
                Room Name
              </label>

              <FormControl
                control="selects"
                showLabel={false}
                name="selectRoom"
                placeholder="Select Room"
                value={
                  values?.firstFloorInfo?.[index]?.name
                    ? propertyRoomName.find(
                        ({ keyName }) =>
                          values?.firstFloorInfo?.[index]?.name === keyName
                      )?.displayName
                    : ""
                }
                options={propertyRoomNameOptions}
                onChange={(value: string) => {
                  setFieldValue(
                    `firstFloorInfo.${index}.${"name"}`,
                    propertyRoomName.find(
                      ({ displayName }) => displayName === value
                    )?.keyName
                  );
                }}
                showErroMessage={false}
                className={
                  errors.propertySubTypeId && touched.propertySubTypeId
                    ? "is-invalid"
                    : "customInput"
                }
              />
            </Col>
            <Col sm={6} md={4} className="mt-2">
              <label
                htmlFor="length"
                className={`mb-2 ${index == 0 ? "d-block" : "d-md-none"}`}
              >
                Length
              </label>

              <div className="d-flex" id="length">
                <input
                  type="number"
                  placeholder="Feet"
                  min={0}
                  disabled={!values?.firstFloorInfo?.[index]?.name}
                  onKeyDown={preventMinusValue}
                  value={values?.firstFloorInfo?.[index]?.length?.feet || ""}
                  onChange={(event) => {
                    setFieldValue(
                      `firstFloorInfo.${index}.${"length"}.${"feet"}`,
                      +event.target.value
                    );
                  }}
                />
                <input
                  type="number"
                  placeholder="Inch"
                  min={0}
                  max={11}
                  disabled={!values?.firstFloorInfo?.[index]?.name}
                  onKeyDown={limitUptoMax}
                  value={values?.firstFloorInfo?.[index]?.length?.inch || ""}
                  onChange={(event) => {
                    setFieldValue(
                      `firstFloorInfo.${index}.${"length"}.${"inch"}`,
                      +event.target.value
                    );
                  }}
                />
              </div>
            </Col>
            <Col sm={6} md={4} className="mt-2">
              <label
                htmlFor="width"
                className={`mb-2 ${index == 0 ? "d-block" : "d-md-none"}`}
              >
                Width
              </label>

              <div className="d-flex" id="width">
                <input
                  type="number"
                  placeholder="Feet"
                  min={0}
                  disabled={!values?.firstFloorInfo?.[index]?.name}
                  onKeyDown={preventMinusValue}
                  value={values?.firstFloorInfo?.[index]?.width?.feet || ""}
                  onChange={(event) => {
                    setFieldValue(
                      `firstFloorInfo.${index}.${"width"}.${"feet"}`,
                      +event.target.value
                    );
                  }}
                />
                <input
                  type="number"
                  placeholder="Inch"
                  min={0}
                  max={11}
                  disabled={!values?.firstFloorInfo?.[index]?.name}
                  onKeyDown={limitUptoMax}
                  value={values?.firstFloorInfo?.[index]?.width?.inch || ""}
                  onChange={(event) => {
                    setFieldValue(
                      `firstFloorInfo.${index}.${"width"}.${"inch"}`,
                      +event.target.value
                    );
                  }}
                />
              </div>
            </Col>
          </Row>
        ))}
      </div>

      <div className="mt-3">
        <Headbar
          title="Second Floor (Optional)"
          icon={addFeatureIcon}
          doTask={() =>
            setSecondFloorInfoItems([
              ...secondFloorInfoItems,
              firstFloorInfoItems.length,
            ])
          }
        />
      </div>
      <div className="rooms-box">
        {secondFloorInfoItems.map((_, index) => (
          <Row className="align-items-center" key={index}>
            <Col sm={6} md={4} className="mt-2">
              <label
                htmlFor="room-name"
                className={`mb-2 ${index == 0 ? "d-block" : "d-md-none"}`}
              >
                Room Name
              </label>

              <FormControl
                control="selects"
                showLabel={false}
                name="selectRoom"
                placeholder="Select Room"
                value={
                  values?.secondFloorInfo?.[index]?.name
                    ? propertyRoomName.find(
                        ({ keyName }) =>
                          values?.secondFloorInfo?.[index]?.name === keyName
                      )?.displayName
                    : ""
                }
                options={propertyRoomNameOptions.filter(room => secondFloor.includes(room.value))}
                onChange={(value: string) => {
                  setFieldValue(
                    `secondFloorInfo.${index}.${"name"}`,
                    propertyRoomName.find(
                      ({ displayName }) => displayName === value
                    )?.keyName
                  );
                }}
                showErroMessage={false}
                className={
                  errors.propertySubTypeId && touched.propertySubTypeId
                    ? "is-invalid"
                    : "customInput"
                }
              />
            </Col>
            <Col sm={6} md={4} className="mt-2">
              <label
                htmlFor="length"
                className={`mb-2 ${index == 0 ? "d-block" : "d-md-none"}`}
              >
                Length
              </label>

              <div className="d-flex" id="length">
                <input
                  type="number"
                  placeholder="Feet"
                  min={0}
                  disabled={!values?.secondFloorInfo?.[index]?.name}
                  onKeyDown={preventMinusValue}
                  value={values?.secondFloorInfo?.[index]?.length?.feet || ""}
                  onChange={(event) => {
                    setFieldValue(
                      `secondFloorInfo.${index}.${"length"}.${"feet"}`,
                      +event.target.value
                    );
                  }}
                />
                <input
                  type="number"
                  placeholder="Inch"
                  min={0}
                  max={11}
                  disabled={!values?.secondFloorInfo?.[index]?.name}
                  onKeyDown={limitUptoMax}
                  value={values?.secondFloorInfo?.[index]?.length?.inch || ""}
                  onChange={(event) => {
                    setFieldValue(
                      `secondFloorInfo.${index}.${"length"}.${"inch"}`,
                      +event.target.value
                    );
                  }}
                />
              </div>
            </Col>
            <Col sm={6} md={4} className="mt-2">
              <label
                htmlFor="width"
                className={`mb-2 ${index == 0 ? "d-block" : "d-md-none"}`}
              >
                Width
              </label>

              <div className="d-flex" id="width">
                <input
                  type="number"
                  placeholder="Feet"
                  min={0}
                  disabled={!values?.secondFloorInfo?.[index]?.name}
                  onKeyDown={preventMinusValue}
                  value={values?.secondFloorInfo?.[index]?.width?.feet || ""}
                  onChange={(event) => {
                    setFieldValue(
                      `secondFloorInfo.${index}.${"width"}.${"feet"}`,
                      +event.target.value
                    );
                  }}
                />
                <input
                  type="number"
                  placeholder="Inch"
                  min={0}
                  max={11}
                  disabled={!values?.secondFloorInfo?.[index]?.name}
                  onKeyDown={limitUptoMax}
                  value={values?.secondFloorInfo?.[index]?.width?.inch || ""}
                  onChange={(event) => {
                    setFieldValue(
                      `secondFloorInfo.${index}.${"width"}.${"inch"}`,
                      +event.target.value
                    );
                  }}
                />
              </div>
            </Col>
          </Row>
        ))}
      </div>
      {/* garage structures */}
      <div className="mt-3">
        <Headbar
          title="Third Floor (Optional)"
          icon={addFeatureIcon}
          doTask={() =>
            setThirdFloorInfoItems([
              ...thirdFloorInfoItems,
              firstFloorInfoItems.length,
            ])
          }
        />
      </div>
      <div className="rooms-box">
        {thirdFloorInfoItems.map((_, index) => (
          <Row className="align-items-center" key={index}>
            <Col sm={6} md={4} className="mt-2">
              <label
                htmlFor="room-name"
                className={`mb-2 ${index == 0 ? "d-block" : "d-md-none"}`}
              >
                Room Name
              </label>

              <FormControl
                control="selects"
                showLabel={false}
                name="selectRoom"
                placeholder="Select Room"
                value={
                  values?.thirdFloorInfo?.[index]?.name
                    ? propertyRoomName.find(
                        ({ keyName }) =>
                          values?.thirdFloorInfo?.[index]?.name === keyName
                      )?.displayName
                    : ""
                }
                options={propertyRoomNameOptions.filter(room => ThirdFloor.includes(room.value))}
                onChange={(value: string) => {
                  setFieldValue(
                    `thirdFloorInfo.${index}.${"name"}`,
                    propertyRoomName.find(
                      ({ displayName }) => displayName === value
                    )?.keyName
                  );
                }}
                showErroMessage={false}
                className={
                  errors.propertySubTypeId && touched.propertySubTypeId
                    ? "is-invalid"
                    : "customInput"
                }
              />
            </Col>
            <Col sm={6} md={4} className="mt-2">
              <label
                htmlFor="length"
                className={`mb-2 ${index == 0 ? "d-block" : "d-md-none"}`}
              >
                Length
              </label>

              <div className="d-flex" id="length">
                <input
                  type="number"
                  placeholder="Feet"
                  min={0}
                  disabled={!values?.thirdFloorInfo?.[index]?.name}
                  onKeyDown={preventMinusValue}
                  value={values?.thirdFloorInfo?.[index]?.length?.feet || ""}
                  onChange={(event) => {
                    setFieldValue(
                      `thirdFloorInfo.${index}.${"length"}.${"feet"}`,
                      +event.target.value
                    );
                  }}
                />
                <input
                  type="number"
                  placeholder="Inch"
                  min={0}
                  max={11}
                  disabled={!values?.thirdFloorInfo?.[index]?.name}
                  onKeyDown={limitUptoMax}
                  value={values?.thirdFloorInfo?.[index]?.length?.inch || ""}
                  onChange={(event) => {
                    setFieldValue(
                      `thirdFloorInfo.${index}.${"length"}.${"inch"}`,
                      +event.target.value
                    );
                  }}
                />
              </div>
            </Col>
            <Col sm={6} md={4} className="mt-2">
              <label
                htmlFor="width"
                className={`mb-2 ${index == 0 ? "d-block" : "d-md-none"}`}
              >
                Width
              </label>

              <div className="d-flex" id="width">
                <input
                  type="number"
                  placeholder="Feet"
                  min={0}
                  disabled={!values?.thirdFloorInfo?.[index]?.name}
                  onKeyDown={preventMinusValue}
                  value={values?.thirdFloorInfo?.[index]?.width?.feet || ""}
                  onChange={(event) => {
                    setFieldValue(
                      `thirdFloorInfo.${index}.${"width"}.${"feet"}`,
                      +event.target.value
                    );
                  }}
                />
                <input
                  type="number"
                  placeholder="Inch"
                  min={0}
                  max={11}
                  disabled={!values?.thirdFloorInfo?.[index]?.name}
                  onKeyDown={limitUptoMax}
                  value={values?.thirdFloorInfo?.[index]?.width?.inch || ""}
                  onChange={(event) => {
                    setFieldValue(
                      `thirdFloorInfo.${index}.${"width"}.${"inch"}`,
                      +event.target.value
                    );
                  }}
                />
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </RoomsStyle>
  );
};

export default Rooms;
