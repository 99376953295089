import styled from "styled-components";
import { fontFamilyRegular } from "../../components/GlobalStyle";

type circleNumberProps = {
  step: number;
  bgColor: string;
  color: string;
  border: string;
  isCurrent: boolean;
};
const CircleNumber: React.FC<circleNumberProps> = ({
  step,
  bgColor,
  color,
  border,
  isCurrent,
}) => {
  return (
    <Wrapper
      bgColor={bgColor}
      color={color}
      border={border}
      isCurrent={isCurrent}
    >
      <div className="circle">{step}</div>
    </Wrapper>
  );
};

export default CircleNumber;

type circleNumberStyleProps = {
  bgColor: string;
  color: string;
  border: string;
  isCurrent: boolean;
};
const Wrapper = styled.div<circleNumberStyleProps>`
  .circle {
    width: ${(props) => (props.isCurrent ? "30px" : "28px")};
    height: ${(props) => (props.isCurrent ? "30px" : "28px")};
    line-height: 100px;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid ${(props) => props.border};
    background-color: ${(props) => props.bgColor};
    color: ${(props) => props.color};
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: ${fontFamilyRegular};
  }
`;
