import styled from "styled-components";
import { fontFamilyMedium, lightGrey9 } from "../../components/GlobalStyle";

export const ReportAndFeedbackStyle = styled.div`
  padding-top: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 12px;
  max-width: 660px;
  width: 96%;
  .logo {
    height: 144px;
    width: 144px;
  }
  .description {
    color: ${lightGrey9};
    font-size: 14px;
  }
  .title {
    font-size: 20px;
    font-family: ${fontFamilyMedium};
  }
  .online-support-section {
    &-label {
      font-size: 17px;
      font-family: ${fontFamilyMedium};
    }
  }
`;
export default ReportAndFeedbackStyle;
