import {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";
import { StoriesRecord } from "../screens/ExploreNearby/types";

type StoryContext = {
  slideIndex: number;
  setSlideIndex: Dispatch<React.SetStateAction<number>>;
  userStoriesData: StoriesRecord;
  setUserStoriesData: Dispatch<SetStateAction<StoriesRecord>>;
  createStoryImages: any[];
  setCreateStoryImages: Dispatch<SetStateAction<File[]>>;
  createStoryVideos: File[];
  setCreateStoryVideos: Dispatch<SetStateAction<any[]>>;
  storyText: string;
  setStoryText: Dispatch<SetStateAction<string>>;
};

const StoryContext = createContext({} as StoryContext);

const StoryProvider = ({ children }: { children: React.ReactNode }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [userStoriesData, setUserStoriesData] = useState({} as StoriesRecord);
  const [createStoryImages, setCreateStoryImages] = useState<File[]>([]);
  const [createStoryVideos, setCreateStoryVideos] = useState<File[]>([]);
  const [storyText, setStoryText] = useState("");

  return (
    <StoryContext.Provider
      value={{
        slideIndex,
        setSlideIndex,
        userStoriesData,
        setUserStoriesData,
        createStoryImages,
        setCreateStoryImages,
        createStoryVideos,
        setCreateStoryVideos,
        storyText,
        setStoryText,
      }}
    >
      {children}
    </StoryContext.Provider>
  );
};

// make sure use
const useStoryContext = () => {
  return useContext(StoryContext);
};

export { useStoryContext, StoryProvider };
