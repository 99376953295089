import styled from "styled-components";
import { fontFamilyMedium } from "../GlobalStyle";

const TermsStyle = styled.div`
  max-width: 800px;
  margin: 0 auto;
  width: 90%;
  padding-top: 30px;
  h4,
  h6 {
    font-family: ${fontFamilyMedium};
  }
  ol {
    list-style-type: lower-roman;
  }
`;

export default TermsStyle;
