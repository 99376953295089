import { useEffect, useRef, useState } from "react";
import { CreateStoryVideoStyle } from "./style";
import { Formik } from "formik";
import * as Yup from "yup";
import Head from "../../components/Head/Head";
import placeholder from "../../assets/icons/ic_res_placeholder.svg";
import { authorizationToken, create_story_url } from "../../utils/api_urls";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import AliceCarousel from "react-alice-carousel";
import { toast } from "react-toastify";
import { Form } from "antd";
import CustomModal from "../../components/Modal/CustomModal";
import EnnvisionModal from "../../components/CustomModals/EnnvisionModal";
import { useStoryContext } from "../../context/StoryContext";
import { Input } from "antd";
import createIcon from "../../assets/icons/ic_upload_camera_story.svg";
import play_video from "../../assets/icons/ic_play_video.svg";
import LoadingOverlay from "../../components/Modal/LoadingOverlay";

const { TextArea } = Input;

type createPostInitialTypes = {
  storyText: string;
  contactRequestPermission: boolean;
  boostPermission: boolean;
  saveFavourite: boolean;
  sharingEnabled: boolean;
  turnOffCommenting: boolean;
  readComments: boolean;
  hideLikesAndViewsCounts: boolean;
};

const CreateStoryVideo = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCreatingPost, setIsCreatingPost] = useState(false);
  const { data: loginData } = useAppSelector((state) => state.loginData);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const { createStoryVideos } = useStoryContext();

  useEffect(() => {
    if (createStoryVideos.length < 1) {
      navigate("/create-story-type");
    }
  }, []);

  const navigate = useNavigate();

  const { result: locationData } = useAppSelector(
    (state) => state.userLocation
  );

  let initialValues: createPostInitialTypes = {
    storyText: "",
    contactRequestPermission: true,
    boostPermission: true,
    saveFavourite: true,
    sharingEnabled: true,
    turnOffCommenting: false,
    readComments: true,
    hideLikesAndViewsCounts: false,
  };

  const validationSchema = Yup.object({
    storyText: Yup.string().optional(),
    contactRequestPermission: Yup.boolean(),
    boostPermission: Yup.boolean(),
    saveFavourite: Yup.boolean(),
    sharingEnabled: Yup.boolean(),
    turnOffCommenting: Yup.boolean(),
    readComments: Yup.boolean(),
    hideLikesAndViewsCounts: Yup.boolean(),
  });

  const handleSubmit = async (values: createPostInitialTypes) => {
    const formData = new FormData();

    createStoryVideos.forEach((file: any) => {
      formData.append("video", file);
    });

    formData.append(
      "data",
      new Blob(
        [
          JSON.stringify({
            ...values,
            userId: loginData?.userDetails.id,
            postLayout: 1,
            ...locationData,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );

    try {
      setIsCreatingPost(true);
      const { data } = await axios.post(create_story_url, formData, {
        headers: {
          ...authorizationToken(loginData!),
        },
      });
      setIsModalVisible(true);
      setTimeout(() => {
        setIsModalVisible(false);
        navigate("/explore-nearby");
      }, 2000);
      setIsCreatingPost(false);
      console.log({ data });
    } catch (error: any) {
      console.log({ error });
      setIsCreatingPost(false);
      toast.error(error.response.data.responseMessage);
    }
  };

  const responsive = {
    0: { items: 1 },
  };

  const handleVideoClick = () => {
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
      setIsPlaying(!video.paused);
    }
  };

  console.log({ isPlaying });
  //   const handleVideoClick = (event: React.MouseEvent<HTMLVideoElement>) => {
  //     event.stopPropagation();
  //     navigate("/story-videos-grid");
  //     // Handle video click behavior here if needed
  //   };
  const videoItems =
    createStoryVideos.length !== 0
      ? createStoryVideos.map((item: File, index) => (
          <div key={index} className="slider-item">
            <div className="video-container">
              <video
                src={URL.createObjectURL(item)}
                ref={videoRef}
                className="media-post-item rounded"
                onClick={handleVideoClick}
              />
              {!isPlaying && (
                <img src={play_video} alt="play button" className="play-btn" />
              )}
            </div>
          </div>
        ))
      : [];

  console.log({ videoItems, createStoryVideos });

  // do task
  const doTask = () => {
    navigate("/explore-nearby");
  };
  return (
    <CreateStoryVideoStyle>
      <Head title="create-story" />
      {isCreatingPost && <LoadingOverlay message="creating..." />}
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Story Created"
          description="Story Created Successfully"
          doTask={doTask}
        />
      </CustomModal>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          console.log(formik.values, "formik values", formik.errors);
          return (
            <Form
              name="basic"
              onFinish={formik.handleSubmit}
              autoComplete="off"
            >
              <div className="inner-section">
                <button type="submit" className="create-story-btn">
                  <img src={createIcon} alt="creat_icon" />
                </button>
                {createStoryVideos.length === 0 ? (
                  <span>
                    <img
                      src={placeholder}
                      alt="placeholder"
                      className="media-post-item rounded"
                    />
                    <p className="text-danger mt-2 px-3">File is Required</p>
                  </span>
                ) : (
                  <AliceCarousel
                    mouseTracking
                    items={videoItems}
                    disableButtonsControls
                    disableDotsControls
                    responsive={responsive}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </CreateStoryVideoStyle>
  );
};

export default CreateStoryVideo;
