import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useGlobalContext } from "../../context/context";
import { EDIT_USER_ROUTE, UPDATE_PROFFESIONAL } from "../../routes/routes";
import {
  authorizationToken,
  delete_professional_url,
} from "../../utils/api_urls";
import CustomButton from "../CustomButton/CustomButton";
import EnnvisionModal from "../CustomModals/EnnvisionModal";
import EnnvisionWarningModal from "../CustomModals/WarningModal";
import { primaryColor } from "../GlobalStyle";
import CustomModal from "../Modal/CustomModal";
import { UpgradeAccountMenuStyle } from "./style";
import useLogout from "../../hooks/useLogout";
// import ViewProfileTourGuide from "../TourGuide/ViewProfile";
// import ViewProfessionalTourGuide from "../TourGuide/Professional";

type upgradeAccountMenuProps = {
  setIsShowUpgradeOptions: React.Dispatch<React.SetStateAction<boolean>>;
};
const UpgradeAccountMenu: React.FC<upgradeAccountMenuProps> = ({
  setIsShowUpgradeOptions,
}) => {
  const navigate = useNavigate();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const { setIsShowMenu, setIsProfessional } = useGlobalContext();
  const { data: loginData } = useAppSelector((state) => state.loginData);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const logout = useLogout();
  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState("");

  const {
    setHomeUnAuthAddProperty,
    selectedTourGuide,
    userTourGuideData,
    professionalTourGuideData,
  } = useGlobalContext();

  // is professional
  const isProfessional = loginData?.ProfessionalDetails;

  // delete professional promise
  const deleteProfessionalPromise = async () => {
    try {
      setIsDeleting(true);
      const { data } = await axios.delete(delete_professional_url, {
        data: {
          userId: loginData?.userDetails.id,
        },
        headers: {
          ...authorizationToken(loginData!),
        },
      });
      setIsDeleting(false);
      setIsDeleteModal(false);
      setIsProfessional(false);
      setIsModalVisible(true);
      setTimeout(() => {
        logout();
        setIsModalVisible(false);
      }, 2000);
    } catch (error: any) {
      console.log(error);
      setError(error.response.data.responseMessage);
      setIsDeleting(false);
      setIsDeleteModal(true);
      alert(error.response.data.responseMessage);
    }
  };

  const options = [
    // {
    //   title: "Upgrade Account",
    //   link: "/professional-plans",
    //   taskTodo: () => {
    //     setIsShowUpgradeOptions(false);
    //   },
    // },
    {
      title: "Edit Profile",
      link: EDIT_USER_ROUTE,
      taskTodo: () => {
        setIsShowUpgradeOptions(false);
      },
    },
    {
      title: "Edit Professional",
      link: UPDATE_PROFFESIONAL,
      taskTodo: () => {
        setIsShowUpgradeOptions(false);
      },
    },
    {
      title: "Delete",
      link: "",
      taskTodo: () => {
        setIsShowUpgradeOptions(false);
        setIsDeleteModal(true);
      },
    },
  ];

  // do task when professional deletes
  const doTask = () => {
    logout();
    setIsModalVisible(false);
  };

  return (
    <UpgradeAccountMenuStyle>
      <CustomModal
        isModalVisible={isDeleteModal}
        setIsModalVisible={setIsDeleteModal}
        showCloseBtn={false}
      >
        <EnnvisionWarningModal
          setIsModalVisible={setIsDeleteModal}
          isLoading={isDeleting}
          title="Delete Professional"
          description="Are You Sure You Want To Delete Your Account? This Action Is Irreversible And All Your Data And Information Associated With The Account Will Be Permanently Deleted."
          doTask={deleteProfessionalPromise}
        />
      </CustomModal>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Professional Deleted"
          description="Professional Account Delete Successfully"
          doTask={doTask}
        />
      </CustomModal>
      <div className="options">
        {options.map(({ title, taskTodo, link }, index) => (
          <div className="option" key={index}>
            <Link onClick={taskTodo} to={link} className="link mb-0">
              {title}
            </Link>
            {/* {index === selectedTourGuide &&
              index < 1 &&
              userTourGuideData.length > 0 &&
              userTourGuideData[selectedTourGuide].isOpen &&
              selectedTourGuide < 1 && (
                <div
                  className="position-absolute"
                  style={{
                    zIndex: 10,
                  }}
                >
                  <ViewProfessionalTourGuide
                    title={professionalTourGuideData[selectedTourGuide].title}
                    sub_title={
                      professionalTourGuideData[selectedTourGuide].description
                    }
                  />
                </div>
              )} */}
          </div>
        ))}

        <div className="option">
          <CustomButton
            bgcolor="transparent"
            color={primaryColor}
            padding="0px"
            width="auto"
            type="submit"
            title="Signout"
            fontSize="16px"
            fontFamily="EnnVisionsMedium"
            border="none"
            clicked={logout}
          />
        </div>
      </div>
    </UpgradeAccountMenuStyle>
  );
};

export default UpgradeAccountMenu;
