import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { user_update_status_url } from "../../../utils/api_urls";
import axios from "axios";

type updateStatusDataTypes = {
  userId: number;
  statusId: number;
};
type initialStateTypes = {
  loading: boolean;
  result: null | updateStatusDataTypes;
  error: any;
};
const initialState: initialStateTypes = {
  loading: false,
  result: {} as updateStatusDataTypes,
  error: "",
};

// Generates pending, fulfilled and rejected action types
export const updateUserStatus = createAsyncThunk(
  "user/updateStatus",
  async (_, thunkAPI) => {
    try {
      const { data } = await axios({
        url: user_update_status_url,
      });
      console.log(data, "user location");
      return data.results;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const updateUserStatusSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateUserStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      updateUserStatus.fulfilled,
      (state, action: PayloadAction<updateStatusDataTypes>) => {
        state.result = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(updateUserStatus.rejected, (state, action) => {
      state.loading = false;
      state.result = null;
      state.error = action.payload || "Something went wrong";
    });
  },
});

export default updateUserStatusSlice.reducer;
