import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

const PropertyListingSkeleton = () => {
  return (
    <Wrapper>
      {Array.from({ length: 3 }).map((_, index) => (
        <div key={index} className="properties">
          <Skeleton width="50%" className="mt-2" />
          <Skeleton height={10} width="30%" />
          <Row className="properties-container">
            {Array.from({ length: 4 }).map((_, index) => (
              <Col sm={6} md={4} lg={3} key={index} className="property">
                <div className="inner-container">
                  <Skeleton width="86%" className="mt-2" />
                  <Skeleton width="60%" />

                  <div className="d-flex justify-content-between">
                    <Skeleton width={60} className="rounded" />
                    <Skeleton width={60} className="rounded" />
                  </div>
                  <div className="d-flex justify-content-between">
                    <Skeleton width={60} className="rounded" />
                    <Skeleton width={60} className="rounded" />
                    <Skeleton width={60} className="rounded" />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </Wrapper>
  );
};

export default PropertyListingSkeleton;

const Wrapper = styled.div`
  .properties {
    margin-top: 16px;
    &-container {
      margin-top: 16px;
      .property {
        .inner-container {
          box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 20px;
          padding: 4px 10px;
          border-radius: 7px;
        }
      }
    }
  }
`;
