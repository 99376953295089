import React, { useState } from "react";
import { useNewsfeedContext } from "../../context/NewsfeedContext";
import { primaryColor, primaryRed } from "../GlobalStyle";
import Wrapper from "./style";
import ConfirmationModal from "./modalStyle";
import { authorizationToken, hide_post_url } from "../../utils/api_urls";
import axios from "axios";
import { useAppSelector } from "../../app/hooks";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomModal from "../Modal/CustomModal";
import EnnvisionModal from "../CustomModals/EnnvisionModal";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { useGlobalContext } from "../../context/context";
import CustomButton from "../CustomButton/CustomButton";
import EnnvisionWarningModal from "../CustomModals/WarningModal";

const OtherPostOptions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const loginData = useAppSelector((state) => state.loginData.data);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const { setShowSocialOptions, setShowPostOptions, selectedPost } =
    useNewsfeedContext();
  const { setCounter } = useGlobalContext();
  const navigate = useNavigate();
  const copyToClipboard = useCopyToClipboard();

  // hide post promise
  const hidePropertyPromise = async () => {
    try {
     setIsConfirmModalVisible(true)
    setIsModalVisible(false)
      // setShowPostOptions(false)
      // const { data } = await axios.post(
      //   hide_post_url,
      //   {
      //     hideType: "POST",
      //     newsFeedId: selectedPost.newsFeedId,
      //     hideToId: selectedPost.postId,
      //   },
      //   {
      //     headers: {
      //       ...authorizationToken(loginData!),
      //     },
      //   }
      // );
      // setIsLoading(false);
      // setIsModalVisible(false);
      // setTimeout(() => {
      //   setCounter((prev) => prev + 1);
      //   setShowPostOptions(false);
      //   setIsModalVisible(true);
      //   setIsConfirmModalVisible(false)
      //   navigate("/explore-nearby");
      // }, 2000);
      // toast(data.responseMessage, {
      //   type: "success",
      // });
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
    
      toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };

  // Confirmation dialog functions
  const showConfirmModal = () => {

    setIsConfirmModalVisible(true);
  };

  const whenClickedYes = async () => {
      try {
        setIsLoading(true);
        // setShowPostOptions(false)
        // setIsModalVisible(false)
        const { data } = await axios.post(
          hide_post_url,
          {
            hideType: "POST",
            newsFeedId: selectedPost.newsFeedId,
            hideToId: selectedPost.postId,
          },
          {
            headers: {
              ...authorizationToken(loginData!),
            },
          }
        );
        setIsLoading(false);
        setIsModalVisible(true);
        setIsLoading(false);
        setIsDeleteModal(false);
        setIsModalVisible(true);
        setTimeout(() => {
          setCounter((prev) => prev + 1);
          setIsModalVisible(false);
          navigate("/explore-nearby");
          setShowPostOptions(false);
        }, 2000);
        // setTimeout(() => {
        //   setCounter((prev) => prev + 1);
        //   setIsModalVisible(false);
        //   setShowPostOptions(false);
        //   setIsConfirmModalVisible((false))
        // }, 2000);
        toast(data.responseMessage, {
          type: "success",
        });
      } catch (error: any) {
        console.log(error);
        setIsLoading(false);
        setShowPostOptions(false);
        toast(error.response.data.responseMessage, {
          type: "error",
        });
      }
    };
    

  const hidePostCancelled = () => {
    setIsConfirmModalVisible(false);
  };

  const postOptions = [
    {
      title: "Copy link to Share this Post",
      doTask: () => {
        copyToClipboard(`post/${selectedPost.postId}`);
        setShowPostOptions(false);
      },
    },
    {
      title: "Hide Post",
      doTask: showConfirmModal,
    },
  ];

  return (
    <Wrapper>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Property Hide"
          description="Property successfully hidden from your timeline."
          doTask={() => {
            
            setShowPostOptions(false);
            setCounter((prev) => prev + 1);
            navigate("/explore-nearby");
          }}
        />
      </CustomModal>
      <CustomModal
        isModalVisible={isDeleteModal}
        setIsModalVisible={setIsDeleteModal}
        showCloseBtn={false}
      >
        <EnnvisionWarningModal
          setIsModalVisible={setIsDeleteModal}
          isLoading={isLoading}
          title="Hide Property"
          description="Are You Sure You Want To Hide Your Property? This Action Is Irreversible And All Your Data And Information Associated With The Property Will Be Hidden."
          doTask={() => {
            // setShowPropertyOptions(false);
            whenClickedYes()
          }}
        />
      </CustomModal>
      {/* <CustomModal
        isModalVisible={isConfirmModalVisible}
        setIsModalVisible={setIsConfirmModalVisible}
        showCloseBtn={false}

      >
        <ConfirmationModal>
        <div className="confirmationDiv">
          <h4>Are you sure you want to hide this post?</h4>
          <div className="d-flex align-items-center gap-5">
          <CustomButton
                bgcolor={primaryColor}
                color="white"
                padding="8px 8px"
                width="100%"
                type="submit"
                title="Cancle"
                margin="auto"
                fontFamily="EnnVisionsMedium"
                fontSize="16px"
                clicked={() =>setIsConfirmModalVisible(false)
                }
              />
          <CustomButton
                bgcolor={primaryColor}
                color="white"
                padding="8px 8px"
                width="100%"
                type="submit"
                title="Yes"
                margin="auto"
                fontFamily="EnnVisionsMedium"
                fontSize="16px"
                clicked={() => whenClickedYes()}
              />
              </div>
        </div>
        </ConfirmationModal>
      </CustomModal> */}

      <ul>
        <li
          className="title"
          onClick={() => {
            navigate("/post-report");
            setShowPostOptions(false);
          }}
          style={{ color: primaryRed, fontWeight: "500" }}
        >
          Report Post
        </li>
        <li
          className="title"
          onClick={()=>{setIsDeleteModal(true)}}
          style={{ color: primaryRed, fontWeight: "500" }}
        >
          {isLoading ? "Report Hiding..." : "Hide Post"}
        </li>
        {/* <li className="d-flex justify-content-between w-100">
          <label htmlFor="custom-switch">Turn comment off for this Post</label>
          <Form.Check
            onChange={(event) => console.log(event.target.checked)}
            type="switch"
            id="custom-switch"
          />
        </li> */}
        {postOptions.map(({ title, doTask }, index) => {
          return <li key={index} onClick={doTask}>{title}</li>;
        })}
        <li
          onClick={() => {
            setShowSocialOptions(true);
            setShowPostOptions(false);
          }}
        >
          Share on other media
        </li>
      </ul>
    </Wrapper>
  );
};

export default OtherPostOptions;
