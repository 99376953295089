import { prRecordTypes } from "../../utils/types";
import { media_base_url } from "../../utils/api_urls";
import placeholder from "../../assets/icons/ic_pro_placeholder.svg";
import { useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import { ProfessionalDrawerStyle } from "./style";
import { userProfileTypes } from "../User/types";
type professionalProps = {} & userProfileTypes;

const DrawerProfessional: React.FC<professionalProps> = ({
  firstName,
  lastName,
  profilePictureURL,
  id: userId,
  professionalDetails: { professionalStatusId },
}) => {
  const professions = useAppSelector((state) => state.appData.data.professions);

  console.log({ professions, professionalStatusId });
  const navigate = useNavigate();
  return (
    <ProfessionalDrawerStyle>
      <div
        className="professional-container cursor-pointer"
        style={{
          backgroundImage: `url(${
            profilePictureURL ? media_base_url + profilePictureURL : placeholder
          })`,
          backgroundRepeat: "no-repeat",
          position: "relative",
          height: "240px",
          backgroundSize: `${profilePictureURL ? "auto" : "cover"}`,
          borderRadius: "5px",
          backgroundPosition: "center",
          backgroundColor: "white",
        }}
        onClick={() => navigate(`/professional/${userId}`)}
      />
      <div className="d-flex justify-content-center">
        <div className="detail-text">
          <h6 className="name">
            {firstName} {lastName}
          </h6>
          {/* <p className="profession">{emailAddress}</p> */}
          <p className="profession">
            {professions.find(({ id }) => id === professionalStatusId)?.name ||
              ""}
          </p>
          {/* <div className="contact-btns d-flex justify-content-center mt-3">
          <CustomButton
            bgcolor={primaryColor}
            color="white"
            padding="6px 8px"
            width="130px"
            type="submit"
            title="Chat"
            margin="auto"
            icon={<img className="me-2" src={chat} alt="chat" />}
            fontSize="16px"
            border="none"
            // clicked={() => editHandler(content)}
          />
          <div className="d-flex ms-2">
            <CustomButton
              bgcolor={greenishColor}
              color="white"
              padding="6px 8px"
              width="130px"
              type="submit"
              title="Call"
              margin="auto"
              fontSize="16px"
              border="none"
              icon={
                <img className="me-2" src={phone} alt="phone" />
              }
              // clicked={() => editHandler(content)}
            />
          </div>
        </div> */}
        </div>
      </div>
    </ProfessionalDrawerStyle>
  );
};

export default DrawerProfessional;
