import closeIcon from "../../assets/icons/ic_story_close.svg";
import { StoryImagesGridStyle } from "./style";
import { Col, Container, Row } from "react-bootstrap";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStoryContext } from "../../context/StoryContext";

const StoryImagesGrid = () => {
  const { createStoryImages, setCreateStoryImages } = useStoryContext();
  const navigate = useNavigate();
  const removeSlideItem = (itemId: number) => {
    const allSlideItem = [...createStoryImages];
    setCreateStoryImages(allSlideItem.filter((_, id) => id !== itemId));
  };

  useEffect(() => {
    if (createStoryImages.length === 0) {
      navigate("/create-story-type");
    }
  }, [createStoryImages]);

  return (
    <StoryImagesGridStyle>
      <Container>
        <Row className="d-flex justify-content-center align-align-items-center">
          {createStoryImages.map((item: File, index) => (
            <Col
              sm={6}
              md={4}
              lg={4}
              xl={3}
              className="slider-item rounded"
              key={index}
            >
              <div className="position-relative">
                <img
                  className="media-post-item rounded"
                  src={URL.createObjectURL(item)}
                  alt={"image-" + index}
                  key={index}
                />
                <img
                  onClick={() => removeSlideItem(index)}
                  src={closeIcon}
                  className="close-icon position-absolute"
                  alt="close-icon"
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </StoryImagesGridStyle>
  );
};

export default StoryImagesGrid;
