import { Navigate, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "antd";
import FormControl from "../../components/FormControl";
import CustomButton from "../../components/CustomButton/CustomButton";
import { ErrorText, primaryColor } from "../../components/GlobalStyle";
import ic_logo from "../../assets/icons/ic_logo.svg";
import ResetPasswordStyle from "./style";
import AuthNavbar from "../../components/Navbar/AuthNavbar";
import { useGlobalContext } from "../../context/context";
import { useRef, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { toast } from "react-toastify";
import Head from "../../components/Head/Head";
import { errorTypes } from "../../utils/types";
import axios from "axios";
import { user_reset_password_url } from "../../utils/api_urls";
import CustomModal from "../../components/Modal/CustomModal";
import EnnvisionModal from "../../components/CustomModals/EnnvisionModal";

type initialValuesTypes = {
 
  password: string;
  confirmPassword:string;
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const { userPhoneNumber, resetPasswordToken } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null>(null);
  const resetPasswordToastId = useRef<any>(null);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  // get Validations from redux appData
  const { validations } = useAppSelector((state) => state.appData.data);

  // reset password validations
  // const phoneNumber = validations[15];
  const password = validations[28];
  const confirmPassword = validations[28];
  // reset password regExpressions
  // const phoneNumberReg = new RegExp(phoneNumber.pattern);
   const passwordReg = new RegExp(password.pattern);

  // initialValues
  const initialValues: initialValuesTypes = {
    password: "",
    confirmPassword:"",
  };

  // validation schema
  const validationSchema = Yup.object({
    confirmPassword: Yup.string()
    .required(confirmPassword.notBlankMsg)
    .matches(passwordReg, confirmPassword.patternMsg),
    password: Yup.string()
      .required(password.notBlankMsg)
      .matches(passwordReg, password.patternMsg),
  });

  const handleSubmit = async (values: any) => {   
    const resetUserData = {
      ...values,
      phoneNumber: userPhoneNumber,
      resetPasswordToken,
    };
    try {
      setLoading(true);
      if(password === confirmPassword){
        const { data } = await axios.post(user_reset_password_url, resetUserData);
        if (data.responseCode === "500") {
          resetPasswordToastId.current = toast(data.responseMessage, {
            type: "error",
            autoClose: 1000,
          });
          setLoading(false);
          return;
        }
        resetPasswordToastId.current = toast(data.responseMessage, {
          type: "success",
          autoClose: 1000,
        });
        setIsModalVisible(true);
        setLoading(false);
        console.log({ data });
      }
    } catch (error: any) {
      if( password === confirmPassword){
        resetPasswordToastId.current = toast(
          "Password doesn't match",
          {
            type: "error",
            autoClose: 1000,
          }
        );
        setLoading(false);
        setError(error.response.data.responseMessage);
      }else{   resetPasswordToastId.current = toast(
        error.response.data.responseMessage,
        {
          type: "error",
          autoClose: 1000,
        }
      );
      setLoading(false);
      setError(error.response.data.responseMessage);}
      }
      
  };

  // do task when password reset
  const doTask = () => {
    setIsModalVisible(false);
    navigate("/login", {
      replace: true,
    });
  };

  return (
    <>
      <Head title="reset-password" />
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      >
        <EnnvisionModal
          description="Your password has been reset successfully. Thank you for using our app and keep your account secure"
          doTask={doTask}
          closeText="Thanks"
          bgBtn={primaryColor}
          color="white"
        />
      </CustomModal>
      <ResetPasswordStyle>
        <div className="reset-password-container-card">
          <div className="reset-password-container-card-logo">
            <img src={ic_logo} alt="ic_logo" className="logo" />
          </div>
          <div className="reset-password-container-card-form">
            <h6 className="fw-bold my-2 text-center">Reset Passsword</h6>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => {
                console.log(formik.values);
                return (
                  <Form
                    name="basic"
                    onFinish={formik.handleSubmit}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    // validateMessages={validationSchema}
                  >
                    <div className="reset-password-input-fields">
                      <FormControl
                        control="password"
                        type="text"
                        name="password"
                        label="New Password"
                        placeholder="Password"
                        fontFamily="EnnVisionsMedium"
                        labelFamily="EnnVisionsMedium"
                        labelMarginBottom="8px"
                        // disabled={true}
                        className={
                          formik.errors.password &&
                          formik.touched.password
                            ? "is-invalid"
                            : "custompasswordInput"
                        }
                      />
                      <div className="reset-password-input-fields-field">
                        <FormControl
                          control="password"
                          type="text"
                          name="confirmPassword"
                          label="Confirm Password"
                          placeholder="Confirm Password"
                          fontFamily="EnnVisionsMedium"
                          labelMarginBottom="8px"
                          className={
                            formik.errors.confirmPassword && formik.touched.confirmPassword
                              ? "is-invalid"
                              : "customPasswordInput"
                          }
                        />
                      </div>

                      {errorMsg ? <ErrorText>{errorMsg}</ErrorText> : null}
                      {error ? <ErrorText>{error}</ErrorText> : null}
                      <div className="my-2">
                        <CustomButton
                          bgcolor={primaryColor}
                          color="white"
                          padding="8px"
                          width="100%"
                          type="submit"
                          title="Reset Password"
                          fontSize="14px"
                          fontFamily="EnnVisionsMedium"
                          loading={loading}
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </ResetPasswordStyle>
    </>
  );
};

export default ResetPassword;
