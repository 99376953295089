import Head from "../../../components/Head/Head";
import Inputs from "./Inputs";
import { BecomeProfessionalStyle } from "./style";

const BecomeProfessional = () => {
  return (
    <>
      <Head title="become-professional" />
      <BecomeProfessionalStyle>
        <Inputs />
      </BecomeProfessionalStyle>
    </>
  );
};

export default BecomeProfessional;
