import styled from "styled-components";
import {
  fontFamilyMedium,
  lightColor1,
  lightGrey,
  pureDark,
  tertiaryGrey19,
  whiteColor,
} from "../GlobalStyle";

export const PostPrivacyStyle = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 440px;
  .inner-section {
    border: 1px solid ${lightColor1};
    border-radius: 6px;
    padding: 12px;
    margin: 0 auto;
    @media screen and (max-width: 480px) {
      width: 96%;
      margin: 0 auto;
    }

    .top-section {
      border: 1px solid ${tertiaryGrey19};
      border-radius: 7px;
      padding: 8px;
      background: ${whiteColor};
    }
    .mini-title {
      color: ${pureDark};
      font-size: 14px;
      font-family: ${fontFamilyMedium};
    }
    .sub-title {
      color: ${lightGrey};
      font-size: 12px;
      border-bottom: 1px solid ${tertiaryGrey19};
      padding-bottom: 8px;
    }

    .btm-border {
      border-bottom: 1px solid ${tertiaryGrey19};
    }

    .advanced-title {
      font-size: 17px;
      color: ${pureDark};
      font-family: ${fontFamilyMedium};
    }

    .advanced-settings {
      border: 1px solid ${tertiaryGrey19};
      border-radius: 7px;
      padding: 8px;
    }

    .post-img {
      max-height: 333px;
    }

    .choose-section {
      margin-top: 16px;
      .gallery {
        font-size: 17px;
        color: ${pureDark};
        font-family: ${fontFamilyMedium};
      }

      &-icons {
        img {
          background: ${lightColor1};
          border-radius: 3px;
          padding: 6px;
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }
`;

export const PrivacyStyle = styled.div`
  max-width: 800px;
  margin: 0 auto;
  width: 90%;
  padding-top: 30px;
  h4,
  h6 {
    font-family: ${fontFamilyMedium};
  }
`;
