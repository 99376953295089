import { Col, Container, Row } from "react-bootstrap";
import SendRequestStyle, { BookingOptionsStyle, ShortNoteModalStyle } from "./style";
import placeholder from "../../assets/icons/ic_pro_placeholder.svg";
import viewmore from "../../assets/icons/ic_newsfeed_more.svg";
import message from "../../assets/icons/ic_property_detail_chat.svg";
import call from "../../assets/icons/ic_property_detail_call.svg";
import video from "../../assets/icons/ic_video.svg";

import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import axios from "axios";
import {
  authorizationToken,
  change_booking_status_url,
  follow_url,
  media_base_url,
  sent_requests_url,
} from "../../utils/api_urls";
import { toast } from "react-toastify";
import NoDataAvailable from "../NoData/NoDataAvailable";
import { objectNotEmpty, truncateString } from "../../utils/utilities";
import CustomButton from "../CustomButton/CustomButton";
import {
  lightGrey,
  primaryColor,
  pureDark,
  secondaryDark,
  whiteColor,
} from "../GlobalStyle";
import CustomModal from "../Modal/CustomModal";
import PendingOptions from "./PendingOptions";
import ConfirmationOptions from "./ConfirmationOptions";
import { useBookingContext } from "../../context/BookingContext";
import { useGlobalContext } from "../../context/context";
import usePostEvent from "../../hooks/usePostEvent";
import LoadingOverlay from "../Modal/LoadingOverlay";
import { Input, Rate } from "antd";
import RescheduleOptions from "./RescheduleOptions";
import BookingsSkeleton from "../Skeletons/Bookings";
import { useNavigate } from "react-router-dom";
import { NewsFeedRecord } from "../../utils/types";

const { TextArea } = Input;

const SentRequests = () => {
  const navigate=useNavigate()
  const [isLoading, setIsLoading] = useState(true);
  const { data: loginData } = useAppSelector((state) => state.loginData);
  const [showBookingOptions, setShowBookingOptions] = useState(false);
  const { visitRequestStatus } = useAppSelector(
    (state) => state.appData.data.statusList
  );
  const { setBookingId, setSelectedBooking, selectedBooking, bookingId } =
    useBookingContext();
  console.log({ selectedBooking, bookingId });
  const [error, setError] = useState("");
  const [requestData, setRequestData] = useState({} as sendRequestTypes);
  const { counter, setCounter } = useGlobalContext();
  const [shortNoteModal, setShortNoteModal] = useState(false);
  const [shortNote, setShortNote] = useState("");
  const [rating, setRating] = useState(0);
  
// sent id's
  const sentIds = [1, 2, 4, 8, 9, 10];
  const [visitRequestData, setVisitRequestData] = useState(
    visitRequestStatus
      .map((data) => {
        if (data.id === 1) {
          return { ...data, isSelect: true };
        }
        return { ...data, isSelect: false };
      })
      .filter(({ id }) => sentIds.includes(id))
  );
  const extractCityFromAddress = (address: string) => {
    const addressParts = address.split(', ');
    // Assuming there are at least two parts in the address
    return addressParts? addressParts.length > 1 ? addressParts[addressParts.length - 2]:addressParts : '';
  
  };
  // send request promise
  const sentRequestPromise = async (requestStatusId: string) => {
    try {
      setIsLoading(true);
      setError("");
      const { data } = await axios.post(
        sent_requests_url,
        {
          userId: loginData?.userDetails.id,
          requestStatusId,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setRequestData(data.results);
      setIsLoading(false);
    } catch (error: any) {
      console.log(error);
      setError(error.response.data.responseMessage);
      setIsLoading(false);
      toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };
  const selectedStatus = visitRequestData.find(
    ({ isSelect }) => isSelect === true
  );
  useEffect(() => {
    if (selectedStatus) {
      sentRequestPromise(selectedStatus.id.toString());
    }
  }, [counter]);

  console.log({ counter });

  // toggle visit request
  const toggleSendRequests = (index: number) => {
    const oldData = [...visitRequestData];
    const newData = oldData.map((data) => ({ ...data, isSelect: false }));
    newData[index].isSelect = true;
    setVisitRequestData(newData);
  };

  console.table(visitRequestData);
const opennew=(propId:number,profId:number)=>{
  if(propId)
  {navigate(`/property-details/${propId}`)}
  if(profId)
  //correct the url
  {navigate(`/property-details/${profId}`)}

}


  
    const { loading, error: confirmError, data, apiDataPromise } = usePostEvent();
    
    return (
      <Container className="py-4">
        <CustomModal
          isModalVisible={shortNoteModal}
          setIsModalVisible={setShortNoteModal}
          showCloseBtn={false}
          onCancel={() => {}}
        >
          <ShortNoteModalStyle>
            <h6 className="heading">Short Note</h6>
            <TextArea
              rows={4}
              placeholder="Short Note"
              value={shortNote}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                setShortNote(event.target.value)
              }
            />
            {objectNotEmpty(selectedBooking) &&
              selectedBooking.requestStatusId === 5 && (
                <Rate
                  className="my-2"
                  value={rating}
                  style={{
                    color: primaryColor,
                  }}
                  onChange={(value) => setRating(value)}
                />
              )}
            <div
              className={`${
                objectNotEmpty(selectedBooking)
                  ? ""
                  : selectedBooking.requestStatusId !== 5
                  ? "my-2"
                  : ""
              }`}
            >
              <CustomButton
                bgcolor={primaryColor}
                color="white"
                padding="8px 8px"
                width="100%"
                type="submit"
                title="SUBMIT"
                margin="10px auto"
                loading={loading}
                fontFamily="EnnVisionsMedium"
                fontSize="16px"
                // disabled={
                //   objectNotEmpty(selectedBooking)
                //     ? false
                //     : selectedBooking.requestStatusId === 5
                //     ? !rating || !shortNote
                //     : !shortNote
                // }
                // disabled={shortNote || !rating}

                // clicked={() => {
                //   console.log("submit");
                  
                //   const cancelStatusData = {
                //     bookingId: selectedBooking.bookingId,
                //     requestStatusId: 6,
                //     shortNote,
                //   };
                //   const completeStatusData = {
                //     bookingId: selectedBooking.bookingId,
                //     requestStatusId: 5,
                //     shortNote,
                //     rattingStar: rating,
                //   };
                //   apiDataPromise(
                //     change_booking_status_url,
                //     selectedBooking.requestStatusId === 5
                //       ? completeStatusData
                //       : cancelStatusData
                //   ).then(() => {
                //     setCounter((prev) => prev + 1);
                //     setRating(0);
                //     setShortNote("");
                //     setShortNoteModal(false);
                //   });
                // }}
                clicked={() => {
                  const completeStatusData = {
                    bookingId: selectedBooking.bookingId,
                    requestStatusId: 5,
                    shortNote: shortNote,
                    rattingStar: rating,
                  };
                  apiDataPromise(change_booking_status_url, completeStatusData).then(() => {
                    setCounter((prev) => prev + 1);
                    setShortNote("");
                    setRating(0);
                    setShortNoteModal(false);
                  });
                }}
              />
            </div>
          </ShortNoteModalStyle>
        </CustomModal>
  
        {loading && <LoadingOverlay message="Booking..." />}
        <CustomModal
          isModalVisible={showBookingOptions}
          setIsModalVisible={setShowBookingOptions}
          showCloseBtn={false}
        >
          {selectedStatus && selectedStatus.id === 1 ? (
            <PendingOptions setShowBookingModal={setShowBookingOptions} />
          ) : selectedStatus && selectedStatus.id === 8 ? (
            <RescheduleOptions setShowBookingModal={setShowBookingOptions} />
          ) : (
            <ConfirmationOptions setShowBookingModal={setShowBookingOptions} />
          )}
        </CustomModal>
        <Row>
          {visitRequestData.map(({ name, id, isSelect }, index) => (
            <Col md={2} className="mt-2">
              <div className="reason-section d-flex my-2"/>

              <CustomButton
                           key={id}
                bgcolor={isSelect ? primaryColor : "transparent"}
                color={isSelect ? whiteColor : secondaryDark}
                padding="8px 8px"
                width="100%"
                border={`1px solid ${isSelect ? primaryColor : lightGrey}`}
                type="submit"
                title={name}
                margin="auto"
                textTransform="Captilize"
                fontFamily="EnnVisionsMedium"
                fontSize="16px"
                clicked={() => {
                  console.log("title",name);
                  
                  toggleSendRequests(index);
                  sentRequestPromise(id.toString());
                }}
              />
            </Col>
          ))}
        </Row>
        {isLoading ? (
          <BookingsSkeleton />
        ) : error ? (
          <NoDataAvailable title={error} />
        ) : (
          <Row>
            {requestData.sendRequests.map((bookingData) => {
              const {
                bookingId,
                host,
                description,
                visitType,
                address,
                visitDate,
                visitStartTime,
                visitEndTime,
                newsFeedId,
                requestStatusId,
                propertyId,
                professionalId,
              
              } = bookingData;
              console.log(address,"address");
              
              const { firstName, lastName, profilePicture,phoneNumber } = host;
              return (
              <Col sm={6} md={4} key={bookingId}>
                  <SendRequestStyle>
                    <div className="d-flex justify-content-between align-items-start">
                      <div className="d-flex gap-3">
                        <figure className="m-0 profile">
                          <img
                          onClick={() => navigate(`/professional/${host.id}`)}
                            src={
                              host.hasOwnProperty("profilePicture")
                                ? media_base_url + profilePicture
                                : placeholder
                            }
                            className="rounded cursor-pointer"
                            alt="image"
                          />
                        </figure>
                        <div>
                          <h4
                          onClick={()=>opennew(propertyId,professionalId)}
                          className="m-0 d-flex gap-2 heading cursor-pointer">
                            {firstName} {lastName}
                          </h4>
                          <p className="m-0 address">
                          {phoneNumber}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex flex-column align-items-end gap-2">
                        {(requestStatusId === 1 || requestStatusId === 8) && (
                                                               

                          <i className="px-2 cursor-pointer">
                            <img
                              style={{ height: "6px" }}
                              src={viewmore}
                              alt="view more"
                              onClick={() => {
                                setBookingId(bookingId);
                                setShowBookingOptions(true);
                                setSelectedBooking({
                                  bookingId,
                                  requestStatusId,
                                  newsFeedId,
                                  visitType,
                                  description,
                                  visitDate,
                                  visitEndTime,
                                  visitStartTime,
                                });
                              }}
                            />
                          </i>
                          
                        )} 
  
                        
                        {/* <div className="d-flex gap-2 icon-container">
                          <i className="active">
                            <img src={message} alt="message" />
                          </i>
                          {visitType !== "IN_PERSON" && (
                            <i>
                              <img src={video} alt="video" />
                            </i>
                          )}
                          <i>
                            <img src={call} alt="call" />
                          </i>
                        </div> */}
                      </div>
                    </div>
                    {/* <div className="d-flex mt-3 mb-2">
                      <p className="highlighted text-dark">Status:</p>
                      <p className="highlighted ms-2">
                        {requestStatusId === 2
                          ? "Accepted"
                          : requestStatusId === 8
                          ? "Request for Reschedule Visit"
                          : requestStatusId === 9
                          ? "Approved Reschedule Visit"
                          : requestStatusId === 10
                          ? "Decline Reschedule Visit"
                          : visitRequestStatus.find(
                              ({ id }) => id === requestStatusId
                            )?.name}
                      </p>
                    </div> */}
                 <div className="reason-section d-flex my-2"/>

                    <div className="d-flex align-items-center">
                      <p className="highlighted">
                        {visitType === "IN_PERSON" ? "in person" : "virtual"}
                      </p>
                      <p className="ms-3">{visitDate}</p>
                      <p className="ms-3">
                        {visitStartTime}
                        {visitEndTime && ` to ${visitEndTime}`}
                      </p>
                    </div>
                    <div className="reason-section d-flex my-2"/>
                    <p className="my-2">{truncateString(description, 116)}</p>
                    
                    
          {requestStatusId === 2 && (
              <> 
              <div className="reason-section d-flex my-2"/>

          <div className="my-2">
            <CustomButton
              bgcolor={primaryColor}
              color={whiteColor}
              padding="8px"
              width="100%"
              type="button"
              border={`1px solid ${primaryColor}`}
              title="Reached Location"
              margin="auto"
              fontFamily="EnnVisionsMedium"
              fontSize="16px"
              clicked={() => {
                console.log(bookingId,bookingData,"Nafda");
                console.log("Reached Location");
                
                apiDataPromise("property/visit/changeStatus", {
                  bookingId:bookingId,
                  requestStatusId: 4,
                }).then(() => setCounter((prev) => prev + 1));
              }}
            />
          </div>
          </>
        )}
                               

                    {requestStatusId === 4 && (
                      <>
                       <div className="reason-section d-flex my-2"/>
                      <div className="my-2">
                        <CustomButton
                          bgcolor={primaryColor}
                          color={whiteColor}
                          padding="8px"
                          width="100%"
                          type="submit"
                          border={`1px solid ${primaryColor}`}
                          title="Completed"
                          margin="auto"
                          fontFamily="EnnVisionsMedium"
                          fontSize="16px"
                          clicked={() => {
                            console.log("completed");
                            
                            setShortNoteModal(true);
                            console.log("nada",requestStatusId);
                            
                            setSelectedBooking({
                              bookingId,
                              requestStatusId:5,
                              newsFeedId,
                              visitType,
                              description,
                              visitDate,
                              visitEndTime,
                              visitStartTime,
                            });
                          }}
                          />
                      </div>
                          </>
                    )}
                    {/* show cancel and accept in approve */}
                    {(requestStatusId === 9 || requestStatusId === 10) && (
                      <div className="d-flex gap-2">
                        <CustomButton
                          bgcolor="transparent"
                          color={pureDark}
                          padding="8px"
                          width="100%"
                          type="submit"
                          border={`1px solid ${pureDark}`}
                          title="Cancel"
                          margin="auto"
                          fontFamily="EnnVisionsMedium"
                          fontSize="16px"
                          clicked={() => {
                            console.log("cancle");
                            
                            setSelectedBooking({
                              bookingId:bookingId,
                              requestStatusId:3,
                              newsFeedId,
                              visitType,
                              description,
                              visitDate,
                              visitEndTime,
                              visitStartTime,
                            });
                            setShortNoteModal(true);
                          }}
                        />
                        <CustomButton
                          bgcolor={primaryColor}
                          color="white"
                          padding="8px"
                          width="100%"
                          type="submit"
                          title="ACCEPT"
                          margin="auto"
                          fontFamily="EnnVisionsMedium"
                          fontSize="16px"
                          clicked={() =>
                            {console.log("accept")
                            
                            apiDataPromise(change_booking_status_url, {
                              bookingId,
                              requestStatusId: 2,
                            }).then(() => {
                              setCounter((prev) => prev + 1);
                            })}
                          }
                        />
                      </div>
                    )}
                    {requestStatusId === 8 && (
                      <div className="reason-section d-flex">
                        <p className="highlighted">Reschedule</p>
                        <p className="ms-3">{visitDate}</p>
                        <p className="ms-3">
                          {visitStartTime}
                          {visitEndTime && ` to ${visitEndTime}`}
                        </p>
                      </div>
                    )}
                  </SendRequestStyle>
                </Col>
              );
            })}
          </Row>
        )}
      </Container>
    );
  };

  // sent id's
 

export default SentRequests;

// types of send requests

export interface sendRequestTypes {
  totalItems: number;
  sendRequests: SendRequest[];
  totalPages: number;
  currentPage: number;
}

export interface SendRequest {
  bookingId: number;
  createdDateTime: string;
  visitType: string;
  description: string;
  visitDate: string;
  visitStartTime: string;
  visitEndTime: string;
  newsFeedId: number;
  requestStatusId: number;
  rejectReasonId: any;
  address: string;
  postType: string;
  host: Host;
  propertyId:number
  professionalId:number

}

export interface Host {
  firstName: string;
  lastName: string;
  userName: string;
  emailAddress: string;
  phoneNumber: string;
  id: number;
  profilePicture?: string;
}
