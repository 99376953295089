import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { StorySliderStyle } from "./style";
import like from "../../assets/icons/ic_heart_like.svg";
import unLike from "../../assets/icons/ic_newsfeed_like.svg";

import send from "../../assets/icons/ic_story_send.svg";
import mute from "../../assets/icons/ic_story_speaker.svg";
import volume from "../../assets/icons/ic_story_speaker-1.svg";
import more from "../../assets/icons/ic_story_more_info.svg";
import play from "../../assets/icons/ic_story_pause.svg";
import pause from "../../assets/icons/ic_play_video.svg";
import {
  BackgroundImage,
  DivImage,
  ProfileMiniImg,
  whiteColor,
} from "../../components/GlobalStyle";
import { BigStoryStyle } from "../Story/style";
import { media_base_url } from "../../utils/api_urls";
import { StoriesRecord } from "./types";
import { useGlobalContext } from "../../context/context";
import getTimeAgo from "../../components/GetTimeAgo";
import profile_placeholder from "../../assets/icons/ic_use_placeholder.svg";
import NextBtn from "../../components/Custom/NextBtn";
import PrevBtn from "../../components/Custom/PrevBtn";
import { useNewsfeedContext } from "../../context/NewsfeedContext";
import { useNavigate } from "react-router-dom";
import CommentsModal from "../../components/Modals/Comments/CommentsModal";
import ShowCommentsBtn from "../../components/Custom/ShowCommentsBtn";
import useLike from "../../hooks/useLike";
import VideoPlayer from "../../components/VideoPlayers/VideoPlayer";
import defaultImg from "../../assets/icons/greyBg.jpeg";
type StorySliderTypes = {
  userStoriesData: StoriesRecord;
  setIsShowStories: Dispatch<SetStateAction<boolean>>;
  setUserStoriesData: Dispatch<SetStateAction<StoriesRecord>>;
};
function StorySlider({
  userStoriesData,
  setIsShowStories,
  setUserStoriesData,
}: StorySliderTypes) {
  const { ownerData, storyDTOList } = userStoriesData;
  const { loading, addLikesPromiseHandler, error } = useLike();
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);

  const handleTogglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const {
    setShowStoryOptions,
    showStoryComments,
    setShowStoryComments,
    selectedStories,
    setSelectedStories,
    setSelectedStory,
  } = useNewsfeedContext();
  const { slideIndex, setSlideIndex } = useGlobalContext();
  const navigate = useNavigate();
  const setSelectedStoryHandler = (storyId:number) => {
    // You can now do something with the selected storyId, such as saving it
    console.log("Selected Story ID:", storyId);

    // Additionally, you may want to perform other actions here, like opening a modal
    // or updating the state to track the selected story
  };
  useEffect(() => {
    let lastSlideIndex = storyDTOList.length;

    if (slideIndex < 0) {
      setSlideIndex(lastSlideIndex);
    }
    // checking if lastSlideIndex is available or not
    if (lastSlideIndex > 0) {
      if (slideIndex > lastSlideIndex || slideIndex == lastSlideIndex) {
        setIsShowStories(false);
      }
    }
  }, [slideIndex, storyDTOList]);

  // useEffect(() => {
  //   const slidesInterval = setInterval(() => {
  //     setSlideIndex(slideIndex + 1);
  //   }, 5000);

  //   return () => {
  //     clearInterval(slidesInterval);
  //   };
  // }, [slideIndex]);

  // owner data
  const { firstName, lastName, profilePicture, id: userId } = ownerData;

  // set selected story data
  const setSlectedStoryHandler = (index:number) => {
    setSelectedStories(userStoriesData);
console.log("which story",userStoriesData);

          console.log("infex",slideIndex,index);
          
          setSelectedStory(userStoriesData.storyDTOList[index]);
                    setShowStoryOptions(true);
          };

  // navigate to user details
  const userDetailsNavigation = () => {
    navigate(`/professional/${userId}`);
  };

  // on video ended
  const onVideoEnd = () => {
    setSlideIndex((prev) => prev + 1);
  };

  return (
    <StorySliderStyle>
      {showStoryComments && (
        <CommentsModal
          isModalVisible={showStoryComments}
          setIsModalVisible={setShowStoryComments}
          readComments={selectedStories.storyDTOList[slideIndex].readComments}
          turnOffCommenting={
            selectedStories.storyDTOList[slideIndex].turnOffCommenting
          }
          newsFeedId={selectedStories.storyDTOList[slideIndex].newsFeedId}
          title="Story Comments"
        />
      )}
      <div className="section-center">
        {storyDTOList.map(
          (
            {
              imagesURLS,
              videosURLS,
              storyText,
              saveFavourite,
              sharingEnabled,
              turnOffCommenting,
              createdDateTime,
              newsFeedId,
              lastLikeAndComment: { isLiked },
            },
            personslideIndex: number
          ) => {
            
            let position = "nextSlide";
            if (personslideIndex === slideIndex) {
              position = "activeSlide";
            }
            // if (
            //   personslideIndex === slideIndex - 1 ||
            //   (slideIndex === 0 && personslideIndex === slides!.length - 1)
            // ) {
            //   position = "lastSlide";
            // }

            // checking it's video or not
            function isVideoUrl(url: string) {
              const videoExtensions = [".mp4", ".avi", ".mov"]; // Add more video extensions if needed
              const extension = url.substring(url.lastIndexOf("."));
              return videoExtensions.includes(extension.toLowerCase());
            }

            return (
              <article key={personslideIndex} className={position}>
                <BigStoryStyle>
                  <div className="big-story bigImg">
                    <div
                      style={{
                        zIndex: "300",
                      }}
                    >
                      <div
                        className="indicators"
                        style={{
                          display: "grid",
                          gridTemplateColumns: `repeat(${storyDTOList.length}, 1fr)`,
                        }}
                      >
                        {storyDTOList.map((_, itemSlideIndex) =>
                          itemSlideIndex > slideIndex ? (
                            <div className="indicator indicator-null mb-1" />
                          ) : (
                            <div className="indicator indicator-fill mb-1" />
                          )
                        )}
                      </div>
                    </div>
                    <div className="profile-details d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <ProfileMiniImg
                          className="mini-img cursor-pointer"
                          onClick={userDetailsNavigation}
                          src={
                            profilePicture
                              ? media_base_url + profilePicture
                              : profile_placeholder
                          }
                          alt={firstName}
                          borderRadius="50%"
                          height="42px"
                          width="42px"
                        />
                        <div className="d-flex flex-column">
                          <p
                            className="title ms-2 mb-0 text-start cursor-pointer"
                            onClick={userDetailsNavigation}
                          >
                            {firstName} {lastName}
                          </p>
                          <p className="mb-0">
                            <span className="title ms-1">
                              {getTimeAgo(createdDateTime)}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="icons">
                        {videosURLS && (
                          <>
                            <img
                              src={isPlaying ? play : pause}
                              alt={isPlaying ? "play" : "pause"}
                              onClick={handleTogglePlay}
                            />
                            <img
                              src={isMuted ? mute : volume}
                              alt={isMuted ? "muted" : "volume"}
                              onClick={() => setIsMuted((prev) => !prev)}
                            />
                          </>
                        )}
                        <div className="threeDots">
                        <img
                          src={more}
                          className="cursor-pointer"
                          alt="more"
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          onClick={()=>{setSlectedStoryHandler(personslideIndex)}}
                          
                          />
                          </div>
                      </div>
                    </div>
                    {storyText && storyText.trim().length > 0 ? (
                      // story type
                      <div className="status-text h-100 px-4">
                        <h2 className="fs-2 h-100 d-flex align-items-center justify-content-center text-break">
                          {storyText}
                        </h2>
                      </div>
                    ) : imagesURLS.length !== 0 ? (
                      // images type
                      // <BackgroundImage
                      //   className="big-img"
                      //   postion="top"
                      //   repeat="no-repeat"
                      //   width="auto"
                      //   size="cover"
                      //   url={media_base_url + imagesURLS[0]}
                      // />
                      <DivImage
                        style={{
                          backgroundImage: `url("${defaultImg}")`,
                        }}
                      >
                        <img src={media_base_url + imagesURLS[0]} alt=""  style={{width:"100%"}}/>
                      </DivImage>
                    ) : videosURLS ? (
                      // video type
                      <div
                        className="video-section h-100"
                        style={{
                          opacity: videosURLS ? 1 : 0,
                        }}
                      >
                        <VideoPlayer
                          videoUrl={media_base_url + videosURLS}
                          onVideoEnd={onVideoEnd}
                          isMuted={isMuted}
                          isPlaying={isPlaying}
                          controls={false}
                        />
                      </div>
                    ) : (
                      <DivImage
                        style={{
                          backgroundImage: `url("${defaultImg}")`,
                        }}
                      >
                        <img src={media_base_url + imagesURLS[0]} alt="" />
                      </DivImage>
                      // default type when no text, no images, and no videos
                    )}
                    <div className="profile-details position-absolute bottom-0 w-100 d-flex justify-content-between align-items-center">
                      <div
                        style={{
                          width:
                            !saveFavourite && !sharingEnabled ? "100%" : "88%",
                                                    }}
                      >
                        <ShowCommentsBtn
                          firstName={firstName}
                          lastName={lastName}
                          onClick={() => {
                            setSelectedStories(userStoriesData);
                            setShowStoryComments(true);
                          }}
                          saveFavourite={saveFavourite}
                          sharingEnabled={sharingEnabled}
                          turnOffCommenting={turnOffCommenting}
                          color={whiteColor}
                          border={whiteColor}
                        />
                      </div>
                      <div className="icons">
                        {saveFavourite && (
                          <img
                          style={{ boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)" }}

                            src={isLiked ? like : unLike}
                            height={36}
                            width={36}
                            className="mt-1 like cursor-pointer"
                            alt={`${isLiked ? "like" : "dislike"}`}
                            onClick={() => {
                              const oldStoryData = {
                                ...userStoriesData,
                              };
                              setUserStoriesData({
                                ...oldStoryData,
                                storyDTOList: oldStoryData.storyDTOList.map(
                                  (storyData, index) => {
                                    if (index === slideIndex) {
                                      return {
                                        ...storyData,
                                        lastLikeAndComment: {
                                          ...storyData.lastLikeAndComment,
                                          isLiked:
                                            !storyData.lastLikeAndComment
                                              .isLiked,
                                        },
                                      };
                                    }
                                    return storyData;
                                  }
                                ),
                              });
                              addLikesPromiseHandler({
                                isLike: isLiked,
                                newsFeedId,
                                userId,
                              });
                            }}
                          />
                        )}
                        {/* {sharingEnabled && (
                          <img
                            src={send}
                            height={36}
                            width={36}
                            className="mt-1 send"
                            alt="send"
                          />
                        )} */}
                      </div>
                    </div>
                  </div>
                </BigStoryStyle>
              </article>
            );
          }
        )}
        {slideIndex > 0 && (
          <PrevBtn clicked={() => setSlideIndex(slideIndex - 1)} />
        )}
        {storyDTOList.length >= 2 && (
          <NextBtn clicked={() => setSlideIndex(slideIndex + 1)} />
        )}
      </div>
    </StorySliderStyle>
  );
}

export default StorySlider;
