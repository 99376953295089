import styled from "styled-components";
import {
  fontFamilyMedium,
  fontFamilyRegular,
  pureDark,
  whiteColor,
} from "../GlobalStyle";

export const FooterStyle = styled.footer`
  border-radius: 4px;
  a {
    font-family: ${fontFamilyMedium};
    margin-bottom: 0;
    color: black;
    text-decoration: none;
    font-size: 16px;
  }
`;
export const FooterHomeStyle = styled.footer`
  background-color: #eee;
  border-top: 1px solid #dce0e0;
  padding: 16px;
  border-radius: 4px;
  .inner-container {
    margin-right: 80px;
    @media screen and (max-width: 850px) {
      margin-right: 0;
    }
  }
  p {
    font-family: ${fontFamilyRegular};
    font-size: 14px;
    margin-left: 80px;
    color: ${pureDark};

    @media screen and (max-width: 850px) {
      margin-left: 0;
    }
  }
  a {
    font-family: ${fontFamilyRegular};
    font-size: 14px;
    margin-bottom: 0;
    color: ${pureDark};
    text-decoration: none;
    font-size: 16px;
  }
`;
export const SocialFooterStyled = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 20;
  .hero-footer {
    background-color: ${whiteColor};
    box-shadow: 0px -2px 6px #00000029;
    .icon {
      cursor: pointer;
      &:not(:first-child) {
        margin-left: 12px;
      }
    }
  }
`;
