import styled from "styled-components";
import {
  fontFamilyMedium,
  lightColor1,
  lightGrey2,
  pureDark,
  secondaryDark4,
  whiteColor,
} from "../../../components/GlobalStyle";

const CreatePropertyReportsStyle = styled.div`
  background-color: white;
  height: calc(100vh - 86px);
  padding-top: 40px;

  .title {
    &:last-child {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    height: 100%;
  }

  .property-intro {
    font-family: ${fontFamilyMedium};

    .heading {
      font-size: 16px;
      color: ${pureDark};
    }

    .free {
      font-size: 16px;
      color: ${pureDark};
    }

    .price {
      font-size: 16px;
      color: ${lightGrey2};
    }
  }
  .row {
    .property-card {
      &-inner-section {
        border: 1px solid ${lightColor1};

        &-items {
          background: ${whiteColor};
          padding: 16px;
          position: relative;
          border-radius: 6px;
          height: 380px;
          overflow-y: auto;
          padding: 0 12px;

          .feature {
            margin-top: 14px;

            &-text {
              color: ${secondaryDark4};
              font-size: 14px;
            }
          }
        }
      }

      .property-below-btn {
        position: sticky;
        bottom: 5%;
      }
    }
  }
`;

export default CreatePropertyReportsStyle;
