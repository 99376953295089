import { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import AppBar from "react-bootstrap/Navbar";
import { NavLink, useNavigate } from "react-router-dom";
import NavbarStyle from "./style";
import logo from "../../assets/icons/ic_white_logo.svg";
import logo1 from "../../assets/icons/ic_logo.svg";
import NavMenu from "./NavMenu";
import { useAppSelector } from "../../app/hooks";
import { useAuthContext } from "../../context/AuthContext";
import { useGlobalContext } from "../../context/context";
import Sidebar from "../Sidebars/Sidebar";
import { RxHamburgerMenu } from "react-icons/rx";
import Skeleton from "react-loading-skeleton";
import { useNavbarContext } from "../../context/NavbarContext";

function Navbar() {
  const { loading } = useNavbarContext();
  const [changeNavColor, setChangeNavColor] = useState(false);
  const { showSidebar, setShowSidebar } = useGlobalContext();
  const loginData = useAppSelector((state) => state.loginData.data);
  const navigate = useNavigate();
  const navColorHandler = () => {
    if (window.scrollY >= 80) {
      setChangeNavColor(true);
    } else {
      setChangeNavColor(false);
    }
  };
  window.addEventListener("scroll", navColorHandler);

  // nav items
  const navMenuItems = [
    {
      title: "Find Home",
      link: "/",
    },
    {
      title: "Find Property",
      link: "/find-home",
    },
    {
      title: "Find Professional",
      link: "/find-professional",
    },
    // {
    //   title: "Resources",
    //   link: "/resources",
    // },
    {
      title: "Contact Us",
      link: "/contact-us",
    },
  ];

  return (
    <NavbarStyle>
      <AppBar
        className={changeNavColor ? "scroll-navbar" : "scroll-navbar"}
        expand="lg"
        fixed="top"
      >
        <Container>
          {!loginData && <AppBar.Toggle aria-controls="navbarScroll" />}
          <div>
            {loginData ? (
              <div className="d-flex align-items-center">
                <RxHamburgerMenu
                  onClick={() => setShowSidebar(!showSidebar)}
                  className={`fs-1 mt-2 ${
                    changeNavColor ? "text-dark" : "text-dark"
                  } me-4 cursor-pointer`}
                />
                <img
                  className="company-logo"
                  onClick={() => navigate("/explore-nearby")}
                  src={changeNavColor ? logo1 : logo1}
                  alt="logo"
                />
              </div>
            ) : loading ? (
              <Skeleton
                circle
                height={60}
                width={60}
                containerClassName="avatar-skeleton"
              />
            ) : (
              <img
                className="company-logo"
                src={changeNavColor ? logo1 : logo1}
                alt="logo"
              />
            )}
          </div>
          <AppBar.Brand href="#" className="d-lg-block d-lg-none">
            <NavMenu changeNavColor={changeNavColor} />
          </AppBar.Brand>
          {!loginData && (
            <AppBar.Collapse id="navbarScroll">
              <Nav className="mx-auto my-2 my-lg-0" navbarScroll>
                {navMenuItems.map(({ title, link }, index) => (
                  <>
                    <NavLink
                      className={
                        changeNavColor
                          ? "nav-link nav-link-dark"
                          : "nav-link nav-link-dark"
                      }
                      to={link}
                      key={index}
                    >
                      {loading ? <Skeleton width={80} /> : title}
                    </NavLink>
                  </>
                ))}
                {/* <NavLink
                  className={
                    changeNavColor ? "nav-link nav-link-dark" : "nav-link"
                  }
                  to="/"
                >
                  Home
                </NavLink>
                <NavLink
                  className={
                    changeNavColor ? "nav-link nav-link-dark" : "nav-link"
                  }
                  to="/find-home"
                >
                  Find Property
                </NavLink>
                <NavLink
                  className={
                    changeNavColor ? "nav-link nav-link-dark" : "nav-link"
                  }
                  to="/find-professional"
                >
                  Find Professional
                </NavLink>
                <NavLink
                  className={
                    changeNavColor ? "nav-link nav-link-dark" : "nav-link"
                  }
                  to="/resources"
                >
                  Resources
                </NavLink> */}
              </Nav>
            </AppBar.Collapse>
          )}

          <div className="navbar-brand pe-auto mt-2 d-none d-lg-block">
            {loading ? (
              <Skeleton width={80} />
            ) : (
              <NavMenu changeNavColor={changeNavColor} />
            )}
          </div>
        </Container>
      </AppBar>
      <Sidebar />
    </NavbarStyle>
  );
}

export default Navbar;
