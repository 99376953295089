import logo from "../../assets/icons/ic_logo.svg";
import CustomButton from "../../components/CustomButton/CustomButton";
import { Input } from "antd";
import { OnlineSupportStyle } from "./style";
import { primaryColor } from "../../components/GlobalStyle";
import usePostEvent from "../../hooks/usePostEvent";
import { useState } from "react";
import { online_support_url } from "../../utils/api_urls";
import CustomModal from "../../components/Modal/CustomModal";
import EnnvisionModal from "../../components/CustomModals/EnnvisionModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const { TextArea } = Input;

const OnlineSupport = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [comment, setComment] = useState("");
  const navigate = useNavigate();

  const { loading, data, error, apiDataPromise } = usePostEvent();
  // handle submit
  const handleSubmit = async () => {
    apiDataPromise(online_support_url, {
      comment,
    })
      .then(() => {
        setIsModalVisible(true);
        setTimeout(() => {
          setIsModalVisible(true);
          navigate("/explore-nearby");
        }, 2000);
      })
      .catch(() => toast(error));
  };

  // do task when user deleted
  const doTask = () => {
    setIsModalVisible(false);
    navigate("/explore-nearby");
  };
  return (
    <OnlineSupportStyle>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Online Support"
          description="Our support team assist you with your questions"
          doTask={doTask}
        />
      </CustomModal>
      <img className="logo" src={logo} alt="logo" />
      <div className="online-support-section mt-3">
        <h6 className="title text-center">Online Support</h6>
        <p className="description text-center">
          Our support team is available 24/7 to assist you with any questions or
          issues. Please feel free to contact us anytime for prompt assistance.
        </p>
        <label htmlFor="comments" className="online-support-section-label">
          Comments
        </label>
        <TextArea
          disabled={loading}
          className="mt-2"
          id="comments"
          rows={4}
          onChange={(e) => setComment(e.target.value)}
        />
        <div className="d-flex justify-content-center my-2">
          <CustomButton
            bgcolor={primaryColor}
            color="white"
            padding="8px 8px"
            width="260px"
            type="submit"
            disabled={!comment}
            loading={loading}
            textTransform="Captilize"
            title="Submit"
            margin="auto"
            fontFamily="EnnVisionsMedium"
            fontSize="16px"
            clicked={handleSubmit}
          />
        </div>
        {error && <p className="text-danger">{error}</p>}
      </div>
    </OnlineSupportStyle>
  );
};

export default OnlineSupport;
