import {
  BlackDot,
  lightGrey2,
  primaryColor,
  TertiraryHeading,
  UnderlineRed,
} from "../../../components/GlobalStyle";
import { UpdateProfessionalStyle, ProInputsStyle } from "./style";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormControl from "../../../components/FormControl";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { EyeOutlined, SelectOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  authorizationToken,
  media_base_url,
  update_professional_url,
} from "../../../utils/api_urls";
import axios from "axios";
// import ProfessionalProfileBox from "../User/BecomeProfessional/ProfileBox";
import Head from "../../../components/Head/Head";
import Loader from "../../../components/Loader/Loader";
import ViewDoc from "./ViewDoc";
import { toast } from "react-toastify";
import CustomModal from "../../../components/Modal/CustomModal";
import EnnvisionModal from "../../../components/CustomModals/EnnvisionModal";
import { getProfessionalDropdown } from "../../../redux/features/professional/dropdownSlice";
import { getprofessionalDetail } from "../../../redux/features/professional/professionalDetailSlice";
import type { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import AutoCompleteAddress from "../../../maps/AutoCompleteAddress";
import { updateUser } from "../../../redux/features/user/updateUserSlice";
import CustomPhoneInput from "../../../components/CustomInputNumber/CustomPhoneInput";
import { FiEdit2 } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";

const UpdateProfessional = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [viewDocs, setViewDocs] = useState({
    registerDoc: false,
    idProfDoc: false,
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAddressEdit, setIsAddressEdit] = useState(false);
  const [updatingProfessionalError, setUpdatingProfessionalError] =
    useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const updateProfessionalToast = useRef<any>(null);

  const { loading, data, error } = useAppSelector(
    (state) => state.professionalsDropdown
  );

  const { id } = useParams();

  const {
    loading: userloading,
    result: professionalData,
    error: userError,
  } = useAppSelector((state) => state.professionalDetails);

  // get Validations from redux appData
  const {
    countryName: {
      results: {
        countryCode,
        countryFlagURL,
        examplePhoneNumber,
        name,
        phoneNumberLength,
      },
    },
    validations,
  } = useAppSelector((state) => state.appData.data);
  const { data: loginData } = useAppSelector((state) => state.loginData);
  // user validations
  const addressValidation = validations[1];
  const bussinessNameValidation = validations[31];
  const business_register_no_validation = validations[36];
  // become professional regExpressions
  const addressRegex = new RegExp(addressValidation.pattern);
  const bussinessNameRegex = new RegExp(bussinessNameValidation.pattern);
  const business_registration_no_regex = new RegExp(
    business_register_no_validation.pattern
  );

  // inital values types
  type initialValuesTyes = {
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    username: string;
    // profileImage: string;
    address: string;
    professionTypeId: number | string;
    businessName: string;
    businessStartedDate: string;
    businessRegisterNumber: string;
    businessRegisterDoc: any;
    idProfDoc: any;
    longitude: number;
    latitude: number;
  };

  useEffect(() => {
    (async () => {
      await dispatch(getProfessionalDropdown());
      dispatch(getprofessionalDetail(loginData?.userDetails.id!));
    })();
  }, []);

  const validationSchema = Yup.object({
    address: Yup.string()
      .required(addressValidation.notBlankMsg)
      .matches(addressRegex, addressValidation.patternMsg),
    professionTypeId: Yup.string().required("profession is required!"),
    businessName: Yup.string()
      .required(bussinessNameValidation.notBlankMsg)
      .matches(bussinessNameRegex, bussinessNameValidation.patternMsg),
    businessStartedDate: Yup.string().required("field is required!"),
    businessRegisterNumber: Yup.string()
      .required(business_register_no_validation.notBlankMsg)
      .matches(
        business_registration_no_regex,
        business_register_no_validation.patternMsg
      ),
    // idProfDoc: Yup.mixed().when(
    //   professionalData?.ProfessionalDetails?.idProfileDocURL,
    //   {
    //     is: (idProfileDocURL: string) => idProfileDocURL ? true:false,
    //     then: Yup.mixed().required("File is required"),
    //     // otherwise: Yup.mixed(),
    //   }
    // ),
    idProfDoc: Yup.mixed().when("isRequired", {
      is: !professionalData?.professionalDetails?.idProfileDocURL,
      then: Yup.mixed().required("File is required"),
    }),
    businessRegisterDoc: Yup.mixed().when("isRequired", {
      is: !professionalData?.professionalDetails?.businessRegisterDocURL,
      then: Yup.mixed().required("File is required"),
    }),

    // .test(
    //   "fileSize",
    //   "File too large",
    //   (value) => value && value.size <= FILE_SIZE
    // )
    // .test(
    //   "fileFormat",
    //   "Unsupported Format",
    //   (value) => value && FILE_SUPPORTED_FORMATS.includes(value.type)
    // ),
    // businessRegisterDoc: Yup.mixed().when(
    //   professionalData?.ProfessionalDetails?.businessRegisterDocURL,
    //   {
    //     is: (businessRegisterDocURL: string) => businessRegisterDocURL ? true:false,
    //     then: Yup.mixed().required("File is required"),
    //     // otherwise: Yup.mixed(),
    //   }
    // ),
    // .test(
    //   "fileSize",
    //   "File too large",
    //   (value) => value && value.size <= FILE_SIZE
    // )
    // .test(
    //   "fileFormat",
    //   "Unsupported Format",
    //   (value) => value && FILE_SUPPORTED_FORMATS.includes(value.type)
    // ),
  });

  // on submit for user update handler
  const onSubmit = async (values: initialValuesTyes) => {
    const userData = {
      userId: professionalData.user.id,
      firstName: values.firstName,
      lastName: values.lastName,
    };
    await dispatch(updateUser(userData!));
  };

  const handleSubmit = async (values: initialValuesTyes) => {
    let allValues: any = {
      ...values,
    };
    await onSubmit(values);
    const formdata = new FormData();
    // bussiness register doc
    if (values.businessRegisterDoc) {
      formdata.append(
        "businessRegisterDoc",
        values.businessRegisterDoc,
        values.businessRegisterDoc.name
      );
      allValues = {
        ...allValues,
        isBusinessRegisterDocURLEdited: true,
        businessRegisterDocURL:
          professionalData?.professionalDetails?.businessRegisterDocURL,
      };
    } else {
      delete allValues.businessRegisterDoc;
      allValues = {
        ...allValues,
        businessRegisterDocURL:
          professionalData?.professionalDetails?.businessRegisterDocURL,
        isBusinessRegisterDocURLEdited: false,
      };
    }

    // id prof doc
    if (values.idProfDoc) {
      formdata.append("idProfDoc", values.idProfDoc, values.idProfDoc.name);
      allValues = {
        ...allValues,
        isIdProfileDocURLEdited: true,
        idProfileDocURL: professionalData?.professionalDetails?.idProfileDocURL,
        idProfDoc: false,
      };
    } else {
      delete allValues.idProfDoc;
      allValues = {
        ...allValues,
        idProfileDocURL: professionalData?.professionalDetails?.idProfileDocURL,
        isIdProfileDocURLEdited: false,
      };
    }

    formdata.append(
      "data",
      new Blob(
        [
          JSON.stringify({
            ...allValues,
            id: professionalData?.professionalDetails?.professionalId,
            userId: professionalData?.user?.id,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );

    console.log({ data });

    try {
      setIsUpdating(true);

      const { data } = await axios.post(update_professional_url, formdata, {
        headers: {
          ...authorizationToken(loginData!),
        },
      });
      setIsUpdating(false);
      setIsModalVisible(true);
      setTimeout(() => {
        setIsModalVisible(false);
        navigate("/find-home");
      }, 2000);
      updateProfessionalToast.current = toast(data.responseMessage, {
        type: "success",
        autoClose: 1000,
      });
      console.log({ data });
    } catch (error: any) {
      updateProfessionalToast.current = toast(
        error.response.data.responseMessage,
        {
          type: "error",
          autoClose: 1000,
        }
      );
      setIsUpdating(false);
    }
  };

  console.log({ userloading });
  if (userloading || loading) {
    return <Loader />;
  }

  const initialValues: initialValuesTyes = {
    firstName: professionalData.user.firstName || "",
    lastName: professionalData.user.lastName || "",
    emailAddress: professionalData.user.emailAddress || "",
    phoneNumber: professionalData.user.phoneNumber || "",
    username: professionalData.user.userName || "",
    // profileImage: data?.userDetails.profileImageURL || "",
    address: professionalData?.professionalDetails?.address || "",
    professionTypeId:
      professionalData?.professionalDetails?.professionTypeId || "",
    businessName: professionalData?.professionalDetails?.businessName || "",
    businessStartedDate:
      professionalData?.professionalDetails?.businessStartedDate || "",
    businessRegisterNumber:
      professionalData?.professionalDetails?.businessRegisterNumber || "",
    businessRegisterDoc: null,
    idProfDoc: null,
    longitude: professionalData?.professionalDetails?.longitude,
    latitude: professionalData?.professionalDetails?.latitude,
  };

  // do task
  const doTask = () => {
    navigate("/find-home");
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    console.log({ current });
    return current && current >= dayjs().startOf("day");
  };

  console.log({ userloading, loading });

  return (
    <UpdateProfessionalStyle>
      <Head title="update-professional" />
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Update Professional"
          description="Congratulations! User Account Has Been Successfully Updated. User Can Open Profile And Check Updated."
          doTask={doTask}
        />
      </CustomModal>
      {/* <ProfessionalProfileBox /> */}
      <ProInputsStyle>
        <TertiraryHeading className="ms-3 mt-4">
          Edit Professional
        </TertiraryHeading>
        <div className="ms-3">
          <UnderlineRed />
          <BlackDot />
        </div>

        <div className="inputs mt-2">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              console.log(formik.values, "formik values");
              return (
                <Form
                  typeof="application/json"
                  name="basic"
                  onSubmit={formik.handleSubmit}
                  autoComplete="off"
                >
                  <Row className="login-input-fields">
                    <Col sm={6} md={4} className="mt-1 mt-sm-0">
                      {isAddressEdit ? (
                        <div className="d-flex align-items-center gap-2">
                          <AutoCompleteAddress
                            label="Address"
                            placeholder="Search Address..."
                            name="address"
                            onChange={({ address, longitude, latitude }) => {
                              console.log(
                                { address, longitude, latitude },
                                "address in autocomplete"
                              );
                              formik.setFieldValue("address", address);
                              formik.setFieldValue("longitude", longitude);
                              formik.setFieldValue("latitude", latitude);
                            }}
                          />
                          <IoMdClose
                            onClick={() => setIsAddressEdit(false)}
                            className="mt-4 fs-4 cursor-pointer"
                          />
                        </div>
                      ) : (
                        <div className="d-flex align-items-center gap-2">
                          <FormControl
                            control="input"
                            type="text"
                            name="address"
                            padding="6px"
                            readOnly={true}
                            border={`1px solid ${lightGrey2}`}
                            label="Address"
                            placeholder="address..."
                            labelFamily="EnnVisionsMedium"
                            className={
                              formik.errors.address && formik.touched.address
                                ? "is-invalid"
                                : "customInput"
                            }
                          />
                          <FiEdit2
                            onClick={() => setIsAddressEdit(true)}
                            className="mt-4 cursor-pointer"
                          />
                        </div>
                      )}
                    </Col>
                    <Col sm={6} md={4} className="mt-1 mt-sm-0">
                      <FormControl
                        control="input"
                        type="text"
                        name="businessName"
                        padding="6px"
                        border={`1px solid ${lightGrey2}`}
                        label="Business Name"
                        placeholder="Business Name"
                        labelFamily="EnnVisionsMedium"
                        className={
                          formik.errors.businessName &&
                          formik.touched.businessName
                            ? "is-invalid"
                            : "customInput"
                        }
                      />
                    </Col>
                    <Col sm={6} md={4} className="mt-1 mt-sm-0">
                      <FormControl
                        control="select"
                        type="text"
                        name="professionTypeId"
                        labelMarginBottom="10px"
                        padding="2px"
                        loading={loading}
                        defaultValue={
                          professionalData &&
                           data.find(
                            ({ id }) =>
                              professionalData?.professionalDetails
                                ?.professionTypeId === id
                          )?.name
                        }
                        border={`1px solid ${lightGrey2}`}
                        label="Select Profession"
                        placeholder="Select Business Type"
                        labelFamily="EnnVisionsMedium"
                        labelFont="14px"
                        className={
                          formik.errors.professionTypeId &&
                          formik.touched.professionTypeId
                            ? "is-invalid"
                            : "customInput"
                        }
                        options={
                          error
                            ? []
                            :[...data].sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name }) => ({
                                value: id,
                                label: name,
                              }))
                        }
                      />
                    </Col>
                    <Col sm={6} md={4} className="mt-2">
                      <div className="w-100 position-relative">
                        <FormControl
                          control="file"
                          type="file"
                          name="businessRegisterDoc"
                          label="Business Register Doc"
                          padding="7px"
                          placeholder="Document"
                          border={`1px solid ${lightGrey2}`}
                          suffix={<SelectOutlined />}
                          labelFamily="EnnVisionsMedium"
                          className={
                            formik.errors.businessRegisterDoc &&
                            formik.touched.businessRegisterDoc
                              ? "is-invalid"
                              : "customInput"
                          }
                        />
                        <div
                          style={{
                            bottom: "10%",
                            right: "2%",
                            zIndex: 200,
                          }}
                          className="position-absolute cursor-pointer"
                        >
                          <EyeOutlined
                            onClick={() =>
                              setViewDocs({
                                idProfDoc: false,
                                registerDoc: true,
                              })
                            }
                            className="fs-4"
                          />
                        </div>
                        <div className="d-none" key="11">
                          <ViewDoc
                            img_url={
                              professionalData?.professionalDetails
                                ?.businessRegisterDocURL
                            }
                            visible={viewDocs.registerDoc}
                            setVisible={(value: boolean) =>
                              setViewDocs({
                                ...viewDocs,
                                registerDoc: value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </Col>
                    {/* <Col sm={6} md={4} className="mt-2">
                      <FormControl
                        control="input"
                        type="text"
                        name="website"
                        label="Website"
                        border={`1px solid ${lightGrey2}`}
                        placeholder="Website"
                        labelFamily="EnnVisionsMedium"
                        className={
                          formik.errors.email && formik.touched.email
                            ? "is-invalid"
                            : "customInput"
                        }
                      />
                    </Col> */}
                    <Col sm={6} md={4} className="mt-3">
                      <FormControl
                        control="dates"
                        type="text"
                        padding="6.5px"
                        name="businessStartedDate"
                        border={`1px solid ${lightGrey2}`}
                        label="Business Start Date"
                        placeholder="Business Started"
                        labelFamily="EnnVisionsMedium"
                        disabledDate={disabledDate}
                        value={formik?.values?.businessStartedDate}
                        onChange={(value: string) =>
                          formik.setFieldValue("businessStartedDate", value)
                        }
                        className={
                          formik.errors.businessStartedDate &&
                          formik.touched.businessStartedDate
                            ? "is-invalid"
                            : "customInput"
                        }
                      />
                    </Col>
                    <Col sm={6} md={4} className="mt-3">
                      <FormControl
                        control="input"
                        type="text"
                        name="businessRegisterNumber"
                        border={`1px solid ${lightGrey2}`}
                        label="Business Register Number"
                        placeholder="Business Register Number"
                        labelFamily="EnnVisionsMedium"
                        className={
                          formik.errors.businessRegisterNumber &&
                          formik.touched.businessRegisterNumber
                            ? "is-invalid"
                            : "customInput"
                        }
                      />
                    </Col>
                    <Col sm={6} md={4} className="mt-1 mt-sm-0">
                      <div className="w-100 position-relative">
                        <FormControl
                          control="file"
                          type="file"
                          name="idProfDoc"
                          label="ID Prof Doc"
                          padding="7px"
                          placeholder="Document"
                          border={`1px solid ${lightGrey2}`}
                          suffix={<SelectOutlined />}
                          labelFamily="EnnVisionsMedium"
                          className={
                            formik.errors.idProfDoc && formik.touched.idProfDoc
                              ? "is-invalid"
                              : "customInput"
                          }
                        />
                        <div
                          style={{
                            bottom: "10%",
                            right: "2%",
                            zIndex: 200,
                          }}
                          className="position-absolute cursor-pointer"
                        >
                          <EyeOutlined
                            onClick={() =>
                              setViewDocs({
                                registerDoc: false,
                                idProfDoc: true,
                              })
                            }
                            className="fs-4"
                          />
                        </div>
                        <div className="d-none">
                          <ViewDoc
                            img_url={
                              professionalData?.professionalDetails
                                ?.idProfileDocURL
                            }
                            visible={viewDocs.idProfDoc}
                            setVisible={(value: boolean) =>
                              setViewDocs({
                                ...viewDocs,
                                idProfDoc: value,
                              })
                            }
                          />
                        </div>
                      </div>

                      {/* {formik.errors.idProfDoc && formik.touched.idProfDoc && (
                        <p>{formik.errors.idProfDoc}</p>
                      )} */}
                    </Col>

                    <div className="mt-2 d-flex justify-content-end">
                      <CustomButton
                        bgcolor={`${primaryColor}`}
                        color="white"
                        padding="7px 8px"
                        width="auto"
                        type="submit"
                        loading={isUpdating}
                        title="Update Professional"
                        fontSize="16px"
                      />
                    </div>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </div>
      </ProInputsStyle>
    </UpdateProfessionalStyle>
  );
};

export default UpdateProfessional;
