import styled from "styled-components";
import { fontFamilyMedium, lightGrey9 } from "../../components/GlobalStyle";
import { lightGrey4 } from "../../components/GlobalStyle";

const HelpAndSupportSyle = styled.div`
  padding-top: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 12px;
  .logo {
    height: 144px;
    width: 144px;
  }
  .description {
    color: ${lightGrey9};
  }
  .title {
    font-size: 20px;
    font-family: ${fontFamilyMedium};
  }
  .online-support-section {
    &-label {
      font-size: 17px;
      font-family: ${fontFamilyMedium};
    }
  }
  .faq-container {
    &-section {
      border: 1px solid ${lightGrey4};
      padding: 12px;
      border-radius: 5px;
      margin-top: 18px;
      cursor: pointer;
      h6 {
        font-family: ${fontFamilyMedium};
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }
`;
export default HelpAndSupportSyle;

export const OnlineSupportStyle = styled.div`
  padding-top: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 12px;
  max-width: 660px;
  .logo {
    height: 144px;
    width: 144px;
  }
  .description {
    color: ${lightGrey9};
    font-size: 14px;
  }
  .title {
    font-size: 20px;
    font-family: ${fontFamilyMedium};
  }
  .online-support-section {
    &-label {
      font-size: 17px;
      font-family: ${fontFamilyMedium};
    }
  }
`;

export const FAQStyle = styled.div`
  padding-top: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 12px;
  .logo {
    height: 144px;
    width: 144px;
  }
  .description {
    color: ${lightGrey9};
  }
  .title {
    font-size: 20px;
    font-family: ${fontFamilyMedium};
  }

  .faq-container {
    .faq-question {
      font-family: ${fontFamilyMedium};
      font-size: 16px;
      margin-bottom: 0;
    }
    .faq-answer {
      font-family: ${fontFamilyMedium};
      font-size: 14px;
      margin-bottom: 0;
    }
    &-section {
      border: 1px solid ${lightGrey4};
      padding: 12px;
      border-radius: 5px;
      margin-top: 18px;
      cursor: pointer;
    }
  }
`;
