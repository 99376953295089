import { PrivacyReportStyle, ShowReasonModalStyle } from "./style";
import { useState } from "react";
import { TertiraryHeadingMini, primaryColor } from "../GlobalStyle";
import {
  authorizationToken,
  change_booking_status_url,
  report_post_url,
} from "../../utils/api_urls";
import axios from "axios";
import { useAppSelector } from "../../app/hooks";
import { toast } from "react-toastify";
import { useNewsfeedContext } from "../../context/NewsfeedContext";
import CustomModal from "../Modal/CustomModal";
import EnnvisionModal from "../CustomModals/EnnvisionModal";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import CustomButton from "../CustomButton/CustomButton";
import { Input } from "antd";
import EnnvisionWarningModal from "../CustomModals/WarningModal";
import { useBookingContext } from "../../context/BookingContext";
import usePostEvent from "../../hooks/usePostEvent";
import LoadingOverlay from "../Modal/LoadingOverlay";

const { TextArea } = Input;

const BookingReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const loginData = useAppSelector((state) => state.loginData.data);
  const visitRejectionReasons = useAppSelector(
    (state) => state.appData.data.statusList.visitRejectionReasons
  );
  const {
    shortNote,
    setShortNote,
    selectedBooking,
    setRejectionReasonId,
    rejectionReasonId,
  } = useBookingContext();
  const { selectedPost } = useNewsfeedContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showReportedModal, setShowReportedModal] = useState(false);
const {bookingId}=useParams()
  const [showReasonModal, setShowReasonModal] = useState(false);
  const navigate = useNavigate();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showCancelWarning, setShowCancelWarning] = useState(false);
  const { loading, error, data, apiDataPromise } = usePostEvent();
console.log("selectedBooking",bookingId);

  // update privacy policy
  const reportReasonPromise = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        report_post_url,
        {
          reportType: "POST",
          id: selectedPost.postId,
          reportReasonId: 1,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setIsLoading(false);
      setIsModalVisible(true);
      setTimeout(() => {
        setIsModalVisible(false);
        navigate("/explore-nearby");
      }, 2000);
      toast(data.responseMessage, {
        type: "success",
      });
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
      toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };

  // delete user promise
  const deleteUserPromise = async () => {
    try {
      setIsDeleting(true);
      const { data } = await axios.post(
        "delete_user_url",
        {
          userId: loginData?.userDetails.id,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setIsDeleteModal(false);
      setIsModalVisible(true);
      setTimeout(() => {
        setIsModalVisible(false);
      }, 2000);
      setIsDeleting(false);
    } catch (error: any) {
      console.log(error);
      setIsDeleting(false);
    }
  };

  // do task when user deleted
  const doTask = () => {
    setShowReportedModal(false);
  };

  return (
    <>
      {loading && <LoadingOverlay message="Reporting..." />}
      <CustomModal
        isModalVisible={showCancelWarning}
        setIsModalVisible={setShowCancelWarning}
        showCloseBtn={false}
      >
        <EnnvisionWarningModal
          setIsModalVisible={setShowCancelWarning}
          isLoading={isDeleting}
          title="Cancel Visiting Request"
          description="You can block them directly from their profile. If you do, they won't be able to find your profile, posts or story or send you messages on Instagram. Instagram won't let them know you blocked them."
          doTask={() => {
            apiDataPromise(change_booking_status_url, {
              bookingId: Number(bookingId),
              requestStatusId: 3,
              rejectionReasonId,
              shortNote,
            }).then(() => {
              setShowReportedModal(true);
              setTimeout(() => {
                navigate(-1);
              }, 2000);
            });
          }}
        />
      </CustomModal>
      <CustomModal
        isModalVisible={showReportedModal}
        setIsModalVisible={setShowReportedModal}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Canceled Visiting Request"
          description="Visiting Request Canceled Successfully."
          doTask={doTask}
        />
      </CustomModal>
      <CustomModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        showCloseBtn={false}
      >
        <EnnvisionModal
          title="Booking Report"
          description="Booking Reported Successfully"
          doTask={() => navigate("/explore-nearby")}
        />
      </CustomModal>
      <CustomModal
        isModalVisible={showReasonModal}
        setIsModalVisible={setShowReasonModal}
        showCloseBtn={false}
        onCancel={() => {}}
      >
        <ShowReasonModalStyle>
          <h6 className="heading">Short Now</h6>
          <TextArea
            rows={4}
            placeholder="Description"
            value={shortNote}
            onChange={(event) => setShortNote(event.target.value)}
          />
          <p className="mt-2">Hide all post from your timeline</p>
          <CustomButton
            bgcolor={primaryColor}
            color="white"
            padding="8px 8px"
            width="100%"
            type="submit"
            title="SUBMIT"
            margin="auto"
            loading={loading}
            fontFamily="EnnVisionsMedium"
            fontSize="16px"
            clicked={() => {
              apiDataPromise(change_booking_status_url, {
                bookingId: Number(bookingId),
                requestStatusId: 6,
                rejectionReasonId,
                shortNote,
              }).then(() => {
                setShowReportedModal(true);
                setTimeout(() => {
                  navigate(-1);
                }, 2000);
              });
            }}
          />
        </ShowReasonModalStyle>
      </CustomModal>
      {isLoading ? (
        <Loader />
      ) : (
        <PrivacyReportStyle className="mt-3 mt-md-0">
          <TertiraryHeadingMini className="my-3">
            Why are you not accept visiting request?
          </TertiraryHeadingMini>
          <div className="inner-section">
            <div className="advanced-settings">
              {visitRejectionReasons.map(({ name, description, id }, index) => (
                <div
                  className="cursor-pointer"
                  key={index}
                  onClick={() => {
                    if (
                      selectedBooking.requestStatusId === 2 ||
                      selectedBooking.requestStatusId === 9
                    ) {
                      setRejectionReasonId(id);
                      setShowReasonModal(true);
                    } else if (selectedBooking.requestStatusId == 8) {
                      setRejectionReasonId(id);
                      setShowCancelWarning(true);
                    } else {
                      apiDataPromise(change_booking_status_url, {
                        bookingId: Number(bookingId),
                        requestStatusId: 3,
                        rejectionReasonId: id,
                      }).then(() => {
                        setShowReportedModal(true);
                        setTimeout(() => {
                          navigate("/upcoming-requests");
                        }, 2000);
                      });
                    }
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <p className=" mini-title mb-0">{name}</p>
                  </div>
                  <p className="sub-title">{description}</p>
                </div>
              ))}
            </div>
          </div>
        </PrivacyReportStyle>
      )}
    </>
  );
};

export default BookingReport;
