import axios from "axios";
import { useEffect, useState } from "react";
import { CSSProperties } from "react";
import { Container } from "react-bootstrap";
import { useAppSelector } from "../../../app/hooks";
import NoDataFound from "../../../components/NoData/NoDataFound";
import Properties from "../../../components/Properties/Properties";
import {
  authorizationToken,
  get_my_properties_url,
} from "../../../utils/api_urls";
import { getMyPropertiesTypes } from "../../../utils/types";
import PropertyListStyle from "./style";
import CustomModal from "../../../components/Modal/CustomModal";

import { useGlobalContext } from "../../../context/context";
import PropertyOptions from "../../../components/PostOptions/PropertyOptions";
import PropertyListingSkeleton from "../../../components/Skeletons/PropertyListing";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function PropertyListing() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [propertiesData, setPropertiesData] = useState(
    {} as getMyPropertiesTypes
  );
  const [getMyListing, setGetMyListing] = useState(false);

  const { showPropertyOptions, setShowPropertyOptions, counter } =
    useGlobalContext();

  // login data
  const { data: loginData } = useAppSelector((state) => state.loginData);
  // properties promise
  const getPropertiesPromise = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        get_my_properties_url,
        {
          userId: loginData?.userDetails.id,
        },
        {
          headers: {
            ...authorizationToken(loginData!),
          },
        }
      );
      setPropertiesData(data.results);
      console.log({ data });
      setLoading(false);
    } catch (error: any) {
      setError(error.response.data);
      setLoading(false);
      console.log(error.response.data, "error in properties data");
    }
  };

  useEffect(() => {
    getPropertiesPromise();
  }, [getMyListing, counter]);

  console.log({ counter });

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [loading]);

  return (
    <PropertyListStyle>
      <CustomModal
        isModalVisible={showPropertyOptions}
        setIsModalVisible={setShowPropertyOptions}
        showCloseBtn={false}
      >
        <PropertyOptions />
      </CustomModal>
      <Container>
        {loading ? (
          <PropertyListingSkeleton />
        ) : error ? (
          <NoDataFound />
        ) : (
          <Properties
            propertiesData={propertiesData}
            getMyListing={setGetMyListing}
          />
        )}
      </Container>
    </PropertyListStyle>
  );
}

export default PropertyListing;
