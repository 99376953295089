import { useEffect, useRef, useState } from "react";
import { CreatePostVideosStyle } from "./style";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { primaryColor } from "../../../components/GlobalStyle";
import { Col, Container, Row } from "react-bootstrap";
import FormControl from "../../../components/FormControl";
import { Formik } from "formik";
import * as Yup from "yup";
import Head from "../../../components/Head/Head";
import { Form } from "antd";
import galleryIcon from "../../../assets/icons/ic_post_image_select.svg";
import CustomizedFileInput from "../../../components/CustomFileInput/CustomizedFileInput";
import placeholder from "../../../assets/icons/ic_res_placeholder.svg";
import { authorizationToken, create_post_url } from "../../../utils/api_urls";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import AliceCarousel from "react-alice-carousel";
import { toast } from "react-toastify";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import CustomModal from "../../../components/Modal/CustomModal";
import EnnvisionModal from "../../../components/CustomModals/EnnvisionModal";
import { usePostContext } from "../../../context/PostContext";
import check from "../../../assets/icons/ic_post_enable.svg";
import unCheck from "../../../assets/icons/ic_post_disable.svg";
import closeIcon from "../../../assets/icons/ic_remove_selected.svg";

type createPostInitialTypes = {
  // tagPeoples: string;
  // media_item: any;
  description: string;
  address: string;
  contactRequestPermission: boolean;
  boostPermission: boolean;
  saveFavourite: boolean;
  sharingEnabled: boolean;
  turnOffCommenting: boolean;
  readComments: boolean;
  hideLikesAndViewsCounts: boolean;
};

const CreatePostVideos = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCreatingPost, setIsCreatingPost] = useState(false);
  const { data: loginData } = useAppSelector((state) => state.loginData);
  const carousel = useRef<AliceCarousel>(null);
  const navigate = useNavigate();
  const { postVideos, setPostVideos } = usePostContext();
  const { result: locationData } = useAppSelector(
    (state) => state.userLocation
  );

  let initialValues: createPostInitialTypes = {
    // tagPeoples: "",
    description: "",
    address: locationData?.address || "",
    contactRequestPermission: true,
    boostPermission: true,
    saveFavourite: true,
    sharingEnabled: true,
    turnOffCommenting: false,
    readComments: true,
    hideLikesAndViewsCounts: false,
  };

  const FILE_SIZE = 5 * 1024 * 1024; // 5 MB
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "video/mp4",
    "video/quicktime",
    "video/x-ms-wmv",
  ];

  const validationSchema = Yup.object({
    description: Yup.string().required("description is required"),
    address: Yup.string().required("address is required"),
    contactRequestPermission: Yup.boolean(),
    boostPermission: Yup.boolean(),
    saveFavourite: Yup.boolean(),
    sharingEnabled: Yup.boolean(),
    turnOffCommenting: Yup.boolean(),
    readComments: Yup.boolean(),
    hideLikesAndViewsCounts: Yup.boolean(),
  });

  const handleSubmit = async (values: createPostInitialTypes) => {
    const formData = new FormData();

    if (postVideos.length === 0) {
      return;
    }

    postVideos.forEach((file: any) => {
      formData.append("video", file);
    });
    const userLocation = {
      city: locationData?.city || "",
      state: locationData?.state || "",
      country: locationData?.country || "",
      latitude: locationData?.latitude || '',
      longitude: locationData?.longitude || '',
    };
    formData.append(
      "data",
      new Blob(
        [
          JSON.stringify({
            ...values,
            userId: loginData?.userDetails.id,
            postLayout: 1,
            ...userLocation,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );

    try {
      setIsCreatingPost(true);
      const { data } = await axios.post(create_post_url, formData, {
        headers: {
          ...authorizationToken(loginData!),
        },
      });
      setIsModalVisible(true);
      setTimeout(() => {
        setIsModalVisible(false);
        navigate("/explore-nearby");
      }, 2000);
      setIsCreatingPost(false);

      console.log({ data });
    } catch (error: any) {
      toast.error(error.response.data.responseMessage);
      setIsCreatingPost(false);
    }
  };

  const responsive = {
    0: { items: 1 },
  };

  console.log({ postVideos });
  const items =
    postVideos.length > 0
      ? postVideos.map((video, index: number) => {
          return (
            <div
              key={index}
              style={{
                height: "82vh",
                // if cropped is 1/1 then 2/1
                // if cropped is 16 /9 then 1/1
                // if cropped is 4/2 then 2/1
                // aspectRatio: "16/9",
              }}
            >
              {/* <video controls>
                <source src={URL.createObjectURL(video)} type="video/mp4" />
                <source src={URL.createObjectURL(video)} type="video/webm" />
                <source src={URL.createObjectURL(video)} type="video/ogg" />
                Your browser does not support the video tag.
              </video> */}
              <video
                src={URL.createObjectURL(video)}
                className="media-post-item rounded"
                controls
              />
            </div>
            // <img
            //   className="media-post-item"
            //   src={img}
            //   alt={"image-" + index}
            //   key={index}
            //   onClick={() => navigate("/create-post-grid")}
            // />
          );
          // if (item.type.startsWith("image/")) {
          //   return (
          //     <img
          //       className="media-post-item"
          //       src={URL.createObjectURL(item)}
          //       alt={"image-" + index}
          //       key={index}
          //       onClick={() => navigate("/create-post-grid")}
          //     />
          //   );
          // } else {
          //   return (
          //     <video
          //       src={URL.createObjectURL(item)}
          //       className="media-post-item rounded"
          //       controls
          //     />
          //   );
          // }
        })
      : [];

  console.log({ items });

  // do task
  const doTask = () => {
    navigate("/explore-nearby");
  };

  useEffect(() => {
    if (postVideos.length < 1) {
      navigate("/create-post-type");
    }
  }, [postVideos]);

  // remove post image
  const removePostImages = (postIndex: number) => {
    const oldPostVideos = [...postVideos];
    setPostVideos(oldPostVideos.filter((_, index) => index !== postIndex));
  };

  return (
    <>
      <CreatePostVideosStyle>
        <Head title="create-post" />
        <CustomModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          showCloseBtn={false}
        >
          <EnnvisionModal
            title="Post Created"
            description="Post Created Successfully"
            doTask={doTask}
          />
        </CustomModal>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            console.log(formik.values, "formik values", formik.errors);
            return (
              <Form
                name="basic"
                onFinish={formik.handleSubmit}
                autoComplete="off"
              >
                <Container>
                  <div className="inner-section d-flex justify-content-center align-items-center">
                    <Row>
                      <Col sm={8} className="ps-0 ">
                        <div className="position-relative">
                          {postVideos.length === 0 ? (
                            <div>
                              <img
                                src={placeholder}
                                alt="placeholder"
                                className="media-post-item rounded"
                              />
                              <p className="text-danger mt-2">
                                File is Required
                              </p>
                            </div>
                          ) : (
                            <div className="position-relative">
                              <AliceCarousel
                                mouseTracking
                                items={items}
                                disableButtonsControls
                                ref={carousel}
                                disableDotsControls
                                responsive={responsive}
                              />
                              <div
                                className="d-flex align-items-center gap-2 position-absolute mini-imgs-section"
                                style={{
                                  maxWidth: "700px",
                                  bottom: "12%",
                                  left: "10%",
                                  overflowX: "auto",
                                }}
                              >
                                {postVideos.map((item, index) => {
                                  return (
                                    <div
                                      className="mini-imgs-section-image"
                                      key={index}
                                    >
                                      <img
                                        src={URL.createObjectURL(item)}
                                        alt=""
                                        style={{
                                          width: "120px",
                                          height: "auto",
                                        }}
                                      />
                                      <img
                                        src={closeIcon}
                                        alt="close"
                                        className="close-icon cursor-pointer"
                                        onClick={() => removePostImages(index)}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                              <nav key="nav" className="b-refs-navs">
                                {items.map((item, i) => {
                                  return (
                                    <span
                                      key={i}
                                      onClick={() =>
                                        carousel?.current?.slideTo(i)
                                      }
                                    />
                                  );
                                })}
                              </nav>
                              {items.length > 1 && (
                                <div key="btns" className="b-refs-buttons">
                                  <button
                                    className="prev-btn"
                                    type="button"
                                    onClick={(e) =>
                                      carousel?.current?.slidePrev(e)
                                    }
                                  >
                                    <span>
                                      <LeftCircleOutlined />
                                    </span>
                                  </button>
                                  <button
                                    className="next-btn"
                                    type="button"
                                    onClick={(e) => {
                                      carousel?.current?.slideNext(e);
                                    }}
                                  >
                                    <span>
                                      <RightCircleOutlined />
                                    </span>
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col
                        sm={4}
                        className="mt-3 mt-md-0 right-section"
                        style={{
                          overflowY: "auto",
                          height: "82vh",
                        }}
                      >
                        <FormControl
                          control="textarea"
                          height="100px"
                          type="text"
                          name="description"
                          border="1px solid #EFEFF4"
                          placeholder="Write a Caption"
                          className={
                            formik.errors.description &&
                            formik.touched.description
                              ? "is-invalid"
                              : "customInput"
                          }
                        />
                        <div className="tag-section">
                          <div className="add-location mt-2">
                            <p className="mini-title mb-1 border-0">
                              Add Location
                            </p>
                            <FormControl
                              control="input"
                              type="text"
                              name="address"
                              placeholder="Enter Address"
                              className={
                                formik.errors.address && formik.touched.address
                                  ? "is-invalid"
                                  : "customInput"
                              }
                            />
                            {/* <PlacesAutoCompleteInput
                          handleChange={(location) =>
                            formik.setFieldValue("address", location)
                          }
                        /> */}
                            {/* <div className="locations d-flex justify-content-between mt-2 flex-wrap">
                          <button
                            type="button"
                            className="mt-2 ms-2 ms-sm-0 mt-sm-0"
                          >
                            Jauharabad
                          </button>
                          <button
                            type="button"
                            className="mt-2 ms-2 ms-sm-0 mt-sm-0"
                          >
                            Islamabad
                          </button>
                          <button
                            type="button"
                            className="mt-2 ms-2 ms-sm-0 mt-sm-0"
                          >
                            OshaToronto, Ontorio
                          </button>
                          <button
                            type="button"
                            className="mt-2 ms-2 ms-sm-0 mt-sm-0"
                          >
                            Osha
                          </button>
                        </div> */}
                          </div>
                        </div>
                        <div className="request-section mt-2 pb-2">
                          <div className="d-flex justify-content-between btm-border">
                            <p className="text mini-title mb-2">
                              Contact Request
                            </p>
                            <span>
                              <img
                                src={
                                  formik.values.contactRequestPermission
                                    ? check
                                    : unCheck
                                }
                                alt={`${
                                  formik.values.contactRequestPermission
                                    ? check
                                    : unCheck
                                }`}
                                onClick={() =>
                                  formik.setFieldValue(
                                    "contactRequestPermission",
                                    !formik.values.contactRequestPermission
                                  )
                                }
                                className="cursor-pointer"
                              />
                            </span>
                          </div>

                          <div className="d-flex justify-content-between post-request mt-2">
                            <p className="text mini-title mb-0">Post Boost</p>
                            <span>
                              <img
                                src={
                                  formik.values.boostPermission
                                    ? check
                                    : unCheck
                                }
                                alt={`${
                                  formik.values.boostPermission
                                    ? check
                                    : unCheck
                                }`}
                                onClick={() =>
                                  formik.setFieldValue(
                                    "boostPermission",
                                    !formik.values.boostPermission
                                  )
                                }
                                className="cursor-pointer"
                              />
                            </span>
                          </div>
                        </div>
                        <p className="advanced-title mt-2 mb-1">
                          Advanced settings
                        </p>
                        <div className="advanced-settings ">
                          <div className="d-flex justify-content-between">
                            <p className=" mini-title mb-0">
                              Hide like and view counts in this post
                            </p>

                            <span>
                              <img
                                src={
                                  formik.values.hideLikesAndViewsCounts
                                    ? check
                                    : unCheck
                                }
                                alt={`${
                                  formik.values.hideLikesAndViewsCounts
                                    ? check
                                    : unCheck
                                }`}
                                onClick={() =>
                                  formik.setFieldValue(
                                    "hideLikesAndViewsCounts",
                                    !formik.values.hideLikesAndViewsCounts
                                  )
                                }
                                className="cursor-pointer"
                              />
                            </span>
                          </div>
                          <p className="sub-title">
                            Only you will see the total number of likes and
                            views on this post. You can change this later by
                            going to the menu at the top of the post.
                          </p>
                          <div className="d-flex justify-content-between mt-2">
                            <p className=" mini-title mb-0">
                              Turn Off Commenting
                            </p>

                            <span>
                              <img
                                src={
                                  formik.values.turnOffCommenting
                                    ? check
                                    : unCheck
                                }
                                alt={`${
                                  formik.values.turnOffCommenting
                                    ? check
                                    : unCheck
                                }`}
                                onClick={() =>
                                  formik.setFieldValue(
                                    "turnOffCommenting",
                                    !formik.values.turnOffCommenting
                                  )
                                }
                                className="cursor-pointer"
                              />
                            </span>
                          </div>
                          <p className="sub-title">
                            You can change this later by going to the menu at
                            the top of your post.
                          </p>
                          <div className="d-flex justify-content-between mt-2">
                            <p className=" mini-title mb-0">Enable Sharing</p>

                            <span>
                              <img
                                src={
                                  formik.values.sharingEnabled ? check : unCheck
                                }
                                alt={`${
                                  formik.values.sharingEnabled ? check : unCheck
                                }`}
                                onClick={() =>
                                  formik.setFieldValue(
                                    "sharingEnabled",
                                    !formik.values.sharingEnabled
                                  )
                                }
                                className="cursor-pointer"
                              />
                            </span>
                          </div>
                          <p className="sub-title">
                            This post can be shared on other media and its link
                            can be copied for sharing on other social media
                            platforms. You can modify this later in the post's
                            menu.
                          </p>
                          <div className="d-flex justify-content-between mt-2">
                            <p className=" mini-title mb-0">Read Comments</p>

                            <span>
                              <img
                                src={
                                  formik.values.readComments ? check : unCheck
                                }
                                alt={`${
                                  formik.values.readComments ? check : unCheck
                                }`}
                                onClick={() =>
                                  formik.setFieldValue(
                                    "readComments",
                                    !formik.values.readComments
                                  )
                                }
                                className="cursor-pointer"
                              />
                            </span>
                          </div>
                          <p className="sub-title">
                            This post's comment and view count is only visible
                            to you. You can change this later in the post's
                            menu.
                          </p>
                          <div className="d-flex justify-content-between mt-2">
                            <p className=" mini-title mb-0">Save Favourite</p>
                            <span>
                              <img
                                src={
                                  formik.values.saveFavourite ? check : unCheck
                                }
                                alt={`${
                                  formik.values.saveFavourite ? check : unCheck
                                }`}
                                onClick={() =>
                                  formik.setFieldValue(
                                    "saveFavourite",
                                    !formik.values.saveFavourite
                                  )
                                }
                                className="cursor-pointer"
                              />
                            </span>
                          </div>
                          <p>
                            You can change this later by going to the menu of
                            top of your post
                          </p>
                          <div className="d-flex justify-content-between">
                            <p className=" mini-title mb-0">
                              Allow Permissions
                            </p>
                          </div>
                          <p>
                            You can change this later by going to the menu of
                            top of your post
                          </p>
                        </div>
                        <div className="my-2">
                          <CustomButton
                            bgcolor={primaryColor}
                            color="white"
                            padding="6px"
                            width="100%"
                            type="submit"
                            title="Post Now"
                            loading={isCreatingPost}
                            margin="auto"
                            fontSize="16px"
                            fontFamily="EnnVisionsMedium"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Container>
              </Form>
            );
          }}
        </Formik>
      </CreatePostVideosStyle>
    </>
  );
};

export default CreatePostVideos;
