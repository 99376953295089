import { Link } from "react-router-dom";
import { FooterHomeStyle } from "./style";

function FooterHome() {
  const year = new Date().getFullYear();
  return (
    <FooterHomeStyle className="footer w-100">
      <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-sm-center">
        <p className="mb-0 text-center text-md-start">
          Copyright {year} All Right Reserved By HouseUp
        </p>
        <div
          className={`inner-container d-flex justify-content-center justify-content-md-end
          } align-items-center`}
           style={{paddingTop:"0px"}}
        >
          <Link to="/terms" target="_blank">
            Terms And Conditions
          </Link>
          <Link to="/privacy" target="_blank" className="ms-4">
            Privacy Policy
          </Link>
        </div>
      </div>
    </FooterHomeStyle>
  );
}

export default FooterHome;
