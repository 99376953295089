import AliceCarousel from "react-alice-carousel";
import {
  SecondaryHeading,
  TertiraryHeading,
  lightColor1,
  primaryColor,
  whiteColor,
} from "../../components/GlobalStyle";
import select from "../../assets/icons/ic_add_property_sel_payment.svg";
import unselectIcon from "../../assets/icons/ic_add_property_payment.svg";
import { Container } from "react-bootstrap";
import RedBlackUnderline from "../../components/RedBlackUnderline/RedBlackUnderline";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { useGlobalContext } from "../../context/context";
import Loader from "../../components/Loader/Loader";
import NoDataAvailable from "../../components/NoData/NoDataAvailable";
import { ProfessionalPlansStyle } from "./style";

const responsive = {
  0: { items: 1.15 },
  568: { items: 2.15 },
  1024: { items: 3 },
  
};

const ProfessionalPlans = () => {
  const navigate = useNavigate();

  const { setProfessionalPlan } = useGlobalContext();
  const professionalPlans = useAppSelector(
    (state) => state.appData.data.professionalPlans
  );

  const items = [
    ...professionalPlans.map((professionalPlan, index) => {
      const {
        description: plan_description,
        getProfessionalPlansSlapsResponse,
        getProfessionalPlansPriceResponse,
        isActive,
        planId,
        planName,
        validityDays,
      } = professionalPlan;
      return (
        <div className="card" key={planId}>
          <div className="d-flex justify-content-between head-bar">
            <div className="d-flex ">
              <h6 className="heading text-center">{planName}</h6>
              {/* <p className="sub-heading text-center mb-0 ms-2">
                {validityDays}
              </p> */}
            </div>
            {planName === "3 Months Free Trial" ? (
              <h5>Free</h5>
            ) : (
              <h5 className="price mb-0 text-center">
                {getProfessionalPlansPriceResponse[0].currencySymbol}{" "}
                {getProfessionalPlansPriceResponse[0].subscriptionAmount}{" "}
                {getProfessionalPlansPriceResponse[0].currency}
              </h5>
            )}
          </div>
          <div className="offers">
            {getProfessionalPlansSlapsResponse.map(
              ({ slabId, slabName, description, isActive }) => (
                <div key={slabId} className="offer ">
                  <img
                    src={isActive ? select : unselectIcon}
                    alt={`${select ? "select" : "unselect"}`}
                  />
                  <p className="mb-0 offer-type">{slabName}</p>
                </div>
              )
            )}
          </div>
          <button
            className={`purchase-btn rounded w-100`}
            onClick={() => {
              navigate("/login");
            }}
          >
            Start With {planName}
          </button>
        </div>
      );
    }),
  ];

  return (
    <ProfessionalPlansStyle>
      <Container>
        <SecondaryHeading className="text-center">
          Professional Package
        </SecondaryHeading>
        <div className="d-flex mt-2 justify-content-center">
          <RedBlackUnderline />
        </div>
        <div className="cards">
          <AliceCarousel
            mouseTracking
            items={items}
            responsive={responsive}
            disableDotsControls
            disableButtonsControls
            controlsStrategy="alternate"
          />
        </div>
      </Container>
    </ProfessionalPlansStyle>
  );
};

export default ProfessionalPlans;
