import React from "react";
import { Input } from "antd";
import { useField, ErrorMessage } from "formik";
import ErrorMsg from "../ErrorMessage";
import { CustomTextAreaStyle } from "./style";
import { fontFamilyRegular } from "../GlobalStyle";

const Index = ({
  placeholder,
  label,
  labelFamily = fontFamilyRegular,
  labelFontSize = "14px",
  height = "16vh",
  defaultValue,
  ...props
}: any) => {
  const { TextArea } = Input;

  const [field] = useField(props);

  return (
    <CustomTextAreaStyle
      labelFamily={labelFamily}
      labelFontSize={labelFontSize}
      height={height}
    >
      <label htmlFor={props.id || props.name}>{label}</label>
      <TextArea
        defaultValue={defaultValue}
        className="customInput"
        placeholder={placeholder}
        {...field}
        {...props}
      />
      <ErrorMessage name={props.name} component={ErrorMsg} />
    </CustomTextAreaStyle>
  );
};

export default Index;
