import { Routes, Route } from "react-router-dom";
import ConfirmedLight from "../components/Confirmed/ConfirmedLight";
import ConfirmedDark from "../components/Confirmed/ConfirmedDark";
import {
  Home,
  FindHome,
  PropertyDetails,
  FindProfessional,
  Resources,
  ErrorPage,
  CreateProperty,
  PaymentInfo,
  PropertyInfo,
  EditPhone,
  BecomeProfessional,
  ProfessionalProfile,
  PropertyParent,
  Register,
  Login,
  GenerateOtp,
  VerifyOtp,
  UserProfile,
  Homepage,
  ExploreNearby,
  MyProfile,
  ProfessionalPlans,
  CreatePropertyPlans,
  CreatePropertyReports,
  PostDetails,
} from "../screens/Pages";
import ForgetGenerateOtp from "../screens/FogetPassword/ForgetGenerateOtp/ForgetGenerateOtp";
import ForgetVerifyOtp from "../screens/FogetPassword/ForgetVerifyOtp/ForgetVerifyOtp";
import ResetPassword from "../screens/ResetPassword/ResetPassword";
import CreateCreditCard from "../screens/CreditCards/CreateCreditCard";

import PropertiesByStatus from "../screens/PropertiesByStatus/PropertiesByStatus";
import { UPDATE_PROFFESIONAL_ROUTE } from "./routes";
import UpdateProfessional from "../screens/professional/BecomeProfessional/UpdateProfessional";
import UpdateUser from "../screens/User/UpdateUser/UpdateUser";
import GenerateProperty from "../screens/CreateProperty/CreateProperty";
import PostList from "../screens/Post/Index";
import PropertyCheckout from "../screens/BillSummary/PropertyCheckout";
import CreatePostGrid from "../screens/Post/CreatePost/CreatePostGrid";
import InPerson from "../test_components/InPerson/InPerson";
import Virtual from "../test_components/chooseDateTime/Virtual";
import ViewUserProfile from "../screens/User/ViewProfile/ViewUserProfile";
import ViewProfessionalProfile from "../screens/User/ViewProfile/ProfessionalProfile";
import UserProperties from "../screens/User/UserProperties";
import UserStories from "../screens/User/UserStories";
import UserPosts from "../screens/User/UserPosts";
import ReportAndFeedback from "../screens/ReportAndFeedback/ReportAndFeedback";
import SettingAndPrivacy from "../screens/SettingAndPrivacy/SettingAndPrivacy";
import PropertyListing from "../screens/User/ViewProfile/PropertyListing";
import PaymentAndWallet from "../screens/PaymentAndWallet/PaymentAndWallet";
import EditProperty from "../screens/EditProperty/EditProperty";
import PostPrivacy from "../components/Privacy/Post";
import StoryPrivacy from "../components/Privacy/Story";
import PropertyPrivacy from "../components/Privacy/Property";
import PropertyReport from "../components/Report/PropertyReport";
import StoryReport from "../components/Report/StoryReport";
import PostReport from "../components/Report/PostReport";
import AddBankAccoun from "../screens/BankAccount/AddAccount";
import SentRequests from "../components/Requests/Sent";
import PreviousRequests from "../components/Requests/History";
import UpcomingRequests from "../components/Requests/Receive";
import BookingReport from "../components/Report/BookingReport";
import EditInPerson from "../test_components/InPerson/EditInPerson";
import EditReschedule from "../test_components/InPerson/EditReschedule";
import CreatePost4 from "../screens/Post/CreatePost/CreatePost4";
import StoryImagesGrid from "../screens/Story/StoryImagesGrid";
import StoryVideosGrid from "../screens/Story/StoryVideosGrid";
import EditPost from "../screens/Post/EditPost/EditPost";
import PostType from "../screens/Post/CreatePost/CreatePostType";
import CreateStoryType from "../screens/Story/CreateStoryType";
import CreateStoryText from "../screens/Story/CreateStoryText";
import CreateStoryImages from "../screens/Story/CreateStoryImages";
import CreateStoryVideo from "../screens/Story/CreateStoryVideo";
import CreateStoryPreview from "../screens/Story/CreateStoryPreview";
import { ReactImagesCrop } from "../ReactEasyCrop/ReactImagesCrop";
import CreatePostImages from "../screens/Post/CreatePost/CreatePostImages";
import CreatePostVideos from "../screens/Post/CreatePost/CreatePostVideo";
import Terms from "../components/Terms/Terms";
import OnlineSupport from "../screens/HelpAndSupport/OnlineSupport";
import FAQ from "../screens/HelpAndSupport/FAQ";
import ProfileDetails from "../screens/User/ViewProfile/ProfileDetails";
import Privacy from "../components/Privacy/Privacy";
import ComingSoon from "../components/Custom/ComingSoon";
import CreditCardInfo from "../components/CreditCardInfo/CreditCardInfo";
import SingleResource from "../screens/Resources/SingleResource";
import RetriveUsername from "../screens/Login/RetriveUsername";
import ContactUs from "../screens/ContactUs/ContactUs";

function AppRoutes() {
  return (
    <Routes>
      {/* auth routes */}

      {/* <Route path="/" element={<Home />} /> */}
      <Route
        path="/"
        element={
          <Homepage>
            <Home />
          </Homepage>
        }
      />

      <Route path="/login" element={<Login />} />
      <Route
        path="editPhone"
        element={
          <Homepage>
            <EditPhone />
          </Homepage>
        }
      />
      <Route path="generate-otp" element={<GenerateOtp />} />
      <Route
        path="/contact-us"
        element={
          <Homepage>
            <ContactUs />
          </Homepage>
        }
      />
      <Route path="forget-password" element={<ForgetGenerateOtp />} />
      <Route path="register/verify-otp" element={<VerifyOtp />} />
      <Route
        path="retrieve/username"
        element={
          <Homepage>
            <RetriveUsername />
          </Homepage>
        }
      />
      <Route path="register" element={<Register />} />
      <Route path="forget-password/verify-otp" element={<ForgetVerifyOtp />} />
      <Route path="reset-password" element={<ResetPassword />} />

      {/* credit card routes */}

      <Route
        path="credit-cards"
        element={
          <Homepage>
            <CreditCardInfo />
          </Homepage>
        }
      />
      <Route
        path="add-credit-card"
        element={
          <Homepage>
            <CreateCreditCard />
          </Homepage>
        }
      />
      <Route
        path="add-bank-account"
        element={
          <Homepage>
            <AddBankAccoun />
          </Homepage>
        }
      />
      <Route
        path="find-home"
        element={
          <Homepage>
            <FindHome />
          </Homepage>
        }
      />

      {/* property routes */}

      <Route
        path="property-details/:id"
        element={
          <Homepage>
            <PropertyDetails />
          </Homepage>
        }
      />
      <Route
        path="properties-by-status/:id"
        element={
          <Homepage>
            <PropertiesByStatus />
          </Homepage>
        }
      />

      {/* professional routes */}

      <Route
        path="become-professional"
        element={
          <Homepage>
            <BecomeProfessional />
          </Homepage>
        }
      />
      <Route
        path={UPDATE_PROFFESIONAL_ROUTE}
        element={
          <Homepage>
            <UpdateProfessional />
          </Homepage>
        }
      />
      <Route
        path="professional-profile/:id"
        element={
          <Homepage>
            <ProfessionalProfile />
          </Homepage>
        }
      />
      <Route
        path="professional-plans"
        element={
          <Homepage>
            <ProfessionalPlans />
          </Homepage>
        }
      />
      <Route
        path="find-professional"
        element={
          <Homepage>
            <FindProfessional />
          </Homepage>
        }
      />
      <Route
        path="professional-profile/:id"
        element={
          <Homepage>
            <ProfessionalProfile />
          </Homepage>
        }
      />

      {/* user routes */}

      <Route
        path="property-listing"
        element={
          <Homepage>
            <PropertyListing />
          </Homepage>
        }
      />
      <Route
        path="property-listing/properties/:id"
        element={
          <Homepage>
            <UserProfile />
          </Homepage>
        }
      />
      <Route
        path="edit-user"
        element={
          <Homepage>
            <UpdateUser />
          </Homepage>
        }
      />

      <Route
        path="my-profile"
        element={
          <Homepage>
            <MyProfile />
          </Homepage>
        }
      />

      {/* newsfeed routes */}

      <Route
        path="explore-nearby"
        element={
          <Homepage>
            <ExploreNearby />
          </Homepage>
        }
      />

      {/* user routes */}

      <Route
        path="user-profile"
        element={
          <Homepage>
            <ViewUserProfile />
          </Homepage>
        }
      />
      <Route
        path="profile-details/:id"
        element={
          <Homepage>
            <ProfileDetails />
          </Homepage>
        }
      />
      <Route
        path="property/getMyProperties"
        element={
          <Homepage>
            <UserProperties />
          </Homepage>
        }
      />
      <Route
        path="user-stories/:id"
        element={
          <Homepage>
            <UserStories />
          </Homepage>
        }
      />
      <Route
        path="user-posts/:id"
        element={
          <Homepage>
            <UserPosts />
          </Homepage>
        }
      />

      {/* privacy routes */}

      <Route
        path="post-privacy"
        element={
          <Homepage>
            <PostPrivacy />
          </Homepage>
        }
      />
      <Route
        path="story-privacy"
        element={
          <Homepage>
            <StoryPrivacy />
          </Homepage>
        }
      />
      <Route
        path="property-privacy"
        element={
          <Homepage>
            <PropertyPrivacy />
          </Homepage>
        }
      />

      {/* report routes */}

      <Route
        path="property-report"
        element={
          <Homepage>
            <PropertyReport />
          </Homepage>
        }
      />
      <Route
        path="story-report"
        element={
          <Homepage>
            <StoryReport />
          </Homepage>
        }
      />
      <Route
        path="post-report"
        element={
          <Homepage>
            <PostReport />
          </Homepage>
        }
      />

      {/* professional routes */}

      <Route
        path="professional/:id"
        element={
          <Homepage>
            <ViewProfessionalProfile />
          </Homepage>
        }
      />

      {/* create post routes */}

      <Route
        path="create-post-type"
        element={
          <Homepage>
            <PostType />
          </Homepage>
        }
      />
      <Route
        path="crop-post-images"
        element={
          <Homepage>
            <ReactImagesCrop />
          </Homepage>
        }
      />

      <Route
        path="create-post-images"
        element={
          <Homepage>
            <CreatePostImages />
          </Homepage>
        }
      />
      <Route
        path="create-post-videos"
        element={
          <Homepage>
            <CreatePostVideos />
          </Homepage>
        }
      />
      {/* <Route
        path="post-crop"
        element={
          <Homepage>
            <ReactEasyCropped />
          </Homepage>
        }
      /> */}
      <Route
        path="edit-post/:id"
        element={
          <Homepage>
            <EditPost />
          </Homepage>
        }
      />
      <Route path="create-post-grid" element={<CreatePostGrid />} />

      {/* in person routes */}

      <Route
        path="in-person"
        element={
          <Homepage>
            <InPerson />
          </Homepage>
        }
      />
      <Route
        path="edit-in-person"
        element={
          <Homepage>
            <EditInPerson />
          </Homepage>
        }
      />
      <Route
        path="edit-reschedule-person"
        element={
          <Homepage>
            <EditReschedule />
          </Homepage>
        }
      />
      <Route
        path="virtual"
        element={
          <Homepage>
            <ComingSoon />
            {/* <Virtual /> */}
          </Homepage>
        }
      />

      <Route
        path="payment-wallet"
        element={
          <Homepage>
            <PaymentAndWallet />
          </Homepage>
        }
      />

      {/* booking request urls */}

      <Route
        path="sent-requests"
        element={
          <Homepage>
            <SentRequests />
          </Homepage>
        }
      />
      <Route
        path="previous-requests"
        element={
          <Homepage>
            <PreviousRequests />
          </Homepage>
        }
      />
      <Route
        path="upcoming-requests"
        element={
          <Homepage>
            <UpcomingRequests />
          </Homepage>
        }
      />

      {/* story routes */}

      <Route
        path="create-story-type"
        element={
          <Homepage>
            <CreateStoryType />
          </Homepage>
        }
      />
      <Route
        path="create-story-text"
        element={
          <Homepage>
            <CreateStoryText />
          </Homepage>
        }
      />
      <Route
        path="create-story-images"
        element={
          <Homepage>
            <CreateStoryImages />
          </Homepage>
        }
      />
      <Route
        path="create-story-text-preview"
        element={
          <Homepage>
            <CreateStoryPreview />
          </Homepage>
        }
      />
      <Route
        path="create-story-video"
        element={
          <Homepage>
            <CreateStoryVideo />
          </Homepage>
        }
      />
      <Route path="story-images-grid" element={<StoryImagesGrid />} />
      <Route path="story-videos-grid" element={<StoryVideosGrid />} />
      <Route
        path="create-post"
        element={
          <Homepage>
            <CreatePost4 />
          </Homepage>
        }
      />
      <Route
        path="post/:id"
        element={
          <Homepage>
            <PostDetails />
          </Homepage>
        }
      />

      {/* <Route path="parent-element" element={<ParentComponent />}>
        <Route path="create-post-1" element={<CreatePost1 />} />
        <Route path="create-post-2" element={<CreatePost2 />} />
        <Route path="create-post-3" element={<CreatePost3 />} />
        <Route path="create-post-4" element={<CreatePost4 />} />
      </Route>

      <Route
        path="create-post-1"
        element={
          <ParentComponent>
            <CreatePost1 />
          </ParentComponent>
        }
      />
      <Route
        path="create-post-2"
        element={
          <ParentComponent>
            <CreatePost2 />
          </ParentComponent>
        }
      />
      <Route
        path="create-post-3"
        element={
          <ParentComponent>
            <CreatePost3 />
          </ParentComponent>
        }
      />
      <Route
        path="create-post-4"
        element={
          <ParentComponent>
            <CreatePost4 />
          </ParentComponent>
        }
      /> */}

      <Route path="confirmed-light" element={<ConfirmedLight />} />
      <Route path="confirmed-dark" element={<ConfirmedDark />} />

      <Route path="post-list" element={<PostList />} />

      <Route
        path="bill-info"
        element={
          <PropertyParent>
            <Homepage>
              <PaymentInfo />
            </Homepage>
          </PropertyParent>
        }
      />

      {/* property routes */}

      <Route
        path="create-property"
        element={
          <Homepage>
            <CreateProperty />
          </Homepage>
        }
      />
      <Route
        path="edit-property/:id"
        element={
          <Homepage>
            <EditProperty />
          </Homepage>
        }
      />
      <Route
        path="property"
        element={
          <Homepage>
            <PropertyParent>
              <CreateProperty />
            </PropertyParent>
          </Homepage>
        }
      />

      <Route
        path="property/:id"
        element={
          <Homepage>
            <PropertyDetails />
          </Homepage>
        }
      />
      <Route
        path="property-info"
        element={
          <Homepage>
            <GenerateProperty />
          </Homepage>
        }
      />
      <Route
        path="create-property-plans"
        element={
          <Homepage>
            <CreatePropertyPlans />
          </Homepage>
        }
      />
      <Route
        path="create-property-reports"
        element={
          <Homepage>
            <CreatePropertyReports />
          </Homepage>
        }
      />
      <Route
        path="booking-reports/:bookingId"
        element={
          <Homepage>
            <BookingReport />
          </Homepage>
        }
      />

      <Route
        path="property-info"
        element={
          <PropertyParent>
            <PropertyInfo />
          </PropertyParent>
        }
      />
      <Route
        path="property-checkout"
        element={
          <Homepage>
            <PropertyParent>
              <PropertyCheckout />
            </PropertyParent>
          </Homepage>
        }
      />

      {/* privacy routes */}

      <Route
        path="online-support"
        element={
          <Homepage>
            <OnlineSupport />
          </Homepage>
        }
      />
      <Route
        path="faq"
        element={
          <Homepage>
            <FAQ />
          </Homepage>
        }
      />
      <Route
        path="terms"
        element={
          <Homepage>
            <Terms title="Terms & Conditions" />
          </Homepage>
        }
      />
      <Route
        path="report-feedback"
        element={
          <Homepage>
            <ReportAndFeedback />
          </Homepage>
        }
      />
      <Route
        path="privacy"
        element={
          <Homepage>
            <Privacy title="PRIVACY POLICY" />
          </Homepage>
        }
      />
      <Route
        path="setting-privacy"
        element={
          <Homepage>
            <SettingAndPrivacy />
          </Homepage>
        }
      />
      <Route path="/resources" element={<Resources />} />

      <Route
        path="/resources/:id"
        element={
          <Homepage>
            <SingleResource />
          </Homepage>
        }
      />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default AppRoutes;
