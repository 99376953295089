import styled from "styled-components";
import { lightGrey2 } from "../GlobalStyle";

const CustomInputStyle = styled.div`
  width: 100%;
  margin-bottom: 10px;

  label {
    font-size: 13px;
    text-transform: capitalize;
    display: block;
    margin-bottom: 10px;
  }
`;

export default CustomInputStyle;

type customPhoneProps = {
  labelFamily: string;
  labelFontSize: string;
};
export const CustomPhoneInputStyle = styled.div<customPhoneProps>`
  label {
    font-family: ${(props) => props.labelFamily};
    font-size: ${(props) => props.labelFontSize};
  }
  .ant-input-number-group .ant-input-number {
    padding: 5px !important;
    border: 1px solid ${lightGrey2} !important;
    border-radius: 0 4px 4px 0 !important;
  }
  .ant-input-number-group-addon {
    width: 80px;
  }
`;
