import FindHomeStyle from "./style";
import Properties from "../Home/Properties";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { AxiosError } from "axios";
import { getAllProperties, singlePropertyTypes } from "../../utils/types";
import { useEffect, useState } from "react";
import {
  get_all_properties_url,
  get_locations_url,
  property_details_url,
} from "../../utils/api_urls";
import axios from "axios";
import NoDataAvailable from "../../components/NoData/NoDataAvailable";
import Loader from "../../components/Loader/Loader";
import MyMultipleComponent from "../Property/PropertyDetails/MultipleMarkers";
import PropertyDrawer from "./PropertyDrawer";
import FindHomeFilters from "./FindHomeFilters";
import { useSearchContext } from "../../context/SearchContext";

import usePost from "../../hooks/usePost";
import useLocationData from "../../maps/GoogleLocation";
import Skeleton from "react-loading-skeleton";
import usePostEvent from "../../hooks/usePostEvent";
import { PropertyDetailTypes } from "../Property/types";
import { objectNotEmpty } from "../../utils/utilities";
import PropertiesSkeleton from "../../components/Skeletons/Properties";
import usePostNoAuthEvent from "../../hooks/usePostEventNoAuth";
import usePostNoAuth from "../../hooks/usePostNoAuth";
interface MyApiResponse {
  responseMessage: string;
  // Other properties you expect in the response
}

export type mapData = {
  id: number;
  newsFeedId: number;
  latitude: number;
  longitude: number;
};
type mapDataTypes = {
  loading: boolean;
  data: mapData[];
  error: string;
};

const FindHome = () => {
  const {
    loading: getPropertyLoading,
    data,
    error: getPropertyError,
    apiDataPromise: getPropertyDetailsPromise,
  }: {
    loading: boolean;
    error: string;
    data: unknown;
    apiDataPromise: (url: string, postData: unknown) => Promise<void>;
  } = usePostNoAuthEvent();
  // get locations for map
  const {
    loading: latLngLoading,
    data: mapData,
    error: latLngError,
  }: mapDataTypes = usePostNoAuth(get_locations_url, {
    useCase: "PROPERTY",
    state: "Ontario",
  });

  const {
    loading,
    setLoading,
    error,
    setError,
    propertiesData,
    setPropertiesData,
    propertiesPage,
    setPropertiesPage,
  } = useSearchContext();
  const [isShowProperty, setIsShowProperty] = useState(false);

  // properties promise
  const getPropertiesPromise = async () => {
    try {
      console.log(">> properties data", { propertiesData, loading });
      setLoading(true);
      const { data } = await axios(get_all_properties_url + propertiesPage);
      if (propertiesPage < 1) {
        setPropertiesData(data.results);
      } else {
        setPropertiesData({
          ...propertiesData,
          properties: [
            ...propertiesData.properties,
            ...data.results.properties,
          ],
        });
      }
      console.log({ data });
      setLoading(false);
    } catch (error: any) {
      setError(error.response.data.responseMessage);
      setLoading(false);
      console.log(
        error.response.data.responseMessage,
        "error in properties data"
      );
    }
  };

  // useEffect(() => {
  //   console.log(">>helloHello")
  //   // if (!propertiesData.hasOwnProperty("properties")) {
  //   getPropertiesPromise();
  //   // }
  //   // return () => {
  //   //   setPropertiesData({} as getAllProperties);
  //   // };
  // }, [propertiesPage]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await axios(get_all_properties_url + propertiesPage);

        if (propertiesPage < 1) {
          setPropertiesData(data.results);
        } else {
          // Check if data.results.properties is an array before spreading it
          const newProperties = Array.isArray(data.results.properties)
            ? data.results.properties
            : [];

          setPropertiesData((prevData) => ({
            ...prevData,
            properties: [...(prevData?.properties || []), ...newProperties],
          }));
        }

        setLoading(false);
      } catch (error) {
        // Explicitly type error as AxiosError
        const axiosError = error as AxiosError<MyApiResponse>; // Provide the expected type

        setError(
          axiosError?.response?.data?.responseMessage ||
            "Error in properties data"
        );
        setLoading(false);
        console.log(
          axiosError?.response?.data?.responseMessage ||
            "Error in properties data"
        );
      }
    };

    fetchData();
  }, [propertiesPage, setPropertiesData, setLoading, setError]);

  // if (loading && propertiesPage < 1) return <Loader />;
  // if (error) return <NoDataAvailable title={error} />;

  const propertiesMarker =
    loading || error
      ? []
      : propertiesData?.properties?.map(
          ({ longitude, latitude, price, propertyId }) => ({
            latitude,
            longitude,
            price,
            propertyId,
          })
        );
  // selected location handler
  const selectedLocationHandler = async (id: number) => {
    setIsShowProperty(true);
    await getPropertyDetailsPromise(property_details_url, {
      propertyId: id,
    });
  };

  console.log({ loading, error, propertiesMarker });
  return (
    <>
      <Helmet>
        <title>Find Home</title>
      </Helmet>
      <FindHomeStyle>
        <FindHomeFilters />
        <div className="mt-4 position-relative multiple-marker-parent">
          <div className="position-absolute w-100 property-drawer">
            <PropertyDrawer
              isShowProperty={isShowProperty}
              setIsShowProperty={setIsShowProperty}
              propertyData={data as PropertyDetailTypes}
              loading={getPropertyLoading}
              error={getPropertyError}
            />
          </div>
          {latLngLoading ? (
            <Skeleton height={440} />
          ) : latLngError ? (
            <NoDataAvailable />
          ) : (
            <MyMultipleComponent
              markers={
                latLngLoading
                  ? []
                  : propertiesMarker?.map(
                      ({ propertyId, latitude, longitude }) => ({
                        propertyId,
                        latitude,
                        longitude,
                      })
                    )
              }
              selectedLocationHandler={selectedLocationHandler}
            />
          )}
        </div>
        <Container>
          {loading ? (
            <div className="properties">
              <PropertiesSkeleton />
            </div>
          ) : error ? (
            <NoDataAvailable title={error} />
          ) : (
            <Properties {...propertiesData} />
          )}
        </Container>
      </FindHomeStyle>
    </>
  );
};

export default FindHome;
