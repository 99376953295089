import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

const StoriesSkeleton = () => {
  return (
    <Wrapper>
      {Array.from({ length: 20 }).map((_, index) => (
        <div className="story" key={index}>
          <Skeleton height={144} width={100} />
        </div>
      ))}
    </Wrapper>
  );
};

export default StoriesSkeleton;

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  overflow-x: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  .story {
    border-radius: 7px;
  }
`;
