import {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";
import { home_data_url } from "../utils/api_urls";
import useFetch from "../hooks/useFetch";
import { homeDataTypes } from "../screens/Home/types";

export interface HomeDataResponse {
  data: homeDataTypes;
  error: string;
  loading: boolean;
  triggerRefetch: () => void;
}

type NavbarContext = {
  isShowProOptions: boolean;
  isShowUpgradeOptions: boolean;
  setIsShowProOptions: Dispatch<SetStateAction<boolean>>;
  setIsShowUpgradeOptions: Dispatch<SetStateAction<boolean>>;
  showCreateOptions: boolean;
  setShowCreateOptions: Dispatch<SetStateAction<boolean>>;
  homeData: homeDataTypes;
  loading: boolean;
  error: string;
  triggerRefetch: () => void;
};

const NavbarContext = createContext({} as NavbarContext);

const NavbarProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    data: homeData,
    error,
    loading,
    triggerRefetch,
  }: HomeDataResponse = useFetch(home_data_url);

  const [isShowProOptions, setIsShowProOptions] = useState(false);
  const [isShowUpgradeOptions, setIsShowUpgradeOptions] = useState(false);
  const [showCreateOptions, setShowCreateOptions] = useState(false);

  return (
    <NavbarContext.Provider
      value={{
        isShowProOptions,
        isShowUpgradeOptions,
        setIsShowProOptions,
        setIsShowUpgradeOptions,
        showCreateOptions,
        setShowCreateOptions,
        loading,
        homeData,
        error,
        triggerRefetch,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
};

// make sure use
const useNavbarContext = () => {
  return useContext(NavbarContext);
};

export { useNavbarContext, NavbarProvider };
