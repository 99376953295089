import styled from "styled-components";
import {
  fontFamilyMedium,
  greenishColor,
  lightColor1,
  lightGrey,
  lightGrey2,
  lightGrey4,
  lightGrey5,
  lightGrey6,
  mediaDeviceMax,
  mediaDeviceMin,
  primaryColor,
  pureDark,
  secondaryDark4,
  tertiaryGrey5,
  tertiaryGrey7,
  tertiaryGrey8,
  tertiaryGrey9,
  whiteColor,
} from "../../../components/GlobalStyle";

const PropertyDetailsStyle = styled.div`
  padding-bottom: 10px;

  .alice-carousel__dots-item {
    width: 6px;
    height: 6px;
    &:not(.__custom):not(:last-child) {
      margin-right: 8px;
    }
  }

  .alice-carousel__dots {
    position: absolute;
    bottom: 4%;
    width: 100%;
    z-index: 10;
  }

  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 60px !important;
  }
  .container {
    position: relative;
    .request-tour-section {
      display: flex;
      justify-content: end;
      position: sticky;
      top: 10%;
      right: 0px;
      @media screen and (max-width: 1390px) {
        position: static;
        display: none;
        margin-left: 12px;
      }
    }
    .static-request-tour {
      display: none;

      @media screen and (max-width: 1390px) {
        display: block;
      }
    }
  }

  .detail-heading {
    font-family: ${fontFamilyMedium};
  }
  .for-sale {
    font-size: 19px;
    color: ${tertiaryGrey7};
  }
  .property-price {
    font-size: 35px;
    color: ${primaryColor};
    font-family: ${fontFamilyMedium};
    @media ${mediaDeviceMax.desktop} {
      font-size: 25px;
    }
    @media ${mediaDeviceMax.mobileL} {
      font-size: 18px;
    }
  }
  .imgs-gallery {
    margin-top: 10px;

    /* .view-more-img {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    } */

    .row {
      #overlay {
        position: absolute;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
      }
      .see-all {
        bottom: 16%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        color: ${whiteColor};
        font-family: ${fontFamilyMedium};
        cursor: pointer;
      }
      img {
        margin-top: 8px;
        height: 245px;
      }
      .img-large {
        height: 550px;
        object-fit: cover;
      }
      @media screen and (min-width: 576px) {
        img {
          height: 245px;
        }
        .img-large {
          height: 500px;
        }
      }
      @media screen and (max-width: 576px) {
        img {
          height: 245px;
          width: 100%;
        }
      }
    }
  }
  .carousel-property {
    margin-top: 24px;
    background: ${whiteColor};
    border: 1px solid ${lightGrey5};
    max-width: 850px;
    border-radius: 5px;
  }
  .property {
    margin-top: 24px;
    background: ${whiteColor};
    border: 1px solid ${lightGrey5};
    max-width: 850px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    img {
      height: 19px;
    }
    &-box {
      &:not(:last-child) {
        border-right: 1px solid ${tertiaryGrey5};
      }
      &-inner {
        padding: 20px;
        @media ${mediaDeviceMin.mobileL} {
          padding: 10px;
        }
        .name {
          color: ${tertiaryGrey7};
          font-size: 14px;
        }
        .quantity {
          font-family: ${fontFamilyMedium};
          font-size: 16px;
        }
      }
    }
  }
  .owner-details {
    margin-top: 22px;
    margin-left:10px;
    .inner-section {
      border: 1px solid ${lightGrey5};
      border-radius: 5px;
      max-width: 850px;
      background-color: white;
    }

    .profile-img {
      height: 70px;
      border-radius: 6px;
      @media ${mediaDeviceMin.tablet} {
        height: 80px;
      }
    }
    .listed-by {
      margin-left:-10px;
      color: ${tertiaryGrey7};
      font-size: 16px;
      font-family: ${fontFamilyMedium};
    }

    .bio {
      h6 {
        font-size: 18px;
        font-family: ${fontFamilyMedium};
        color: ${pureDark};
      }
      .branch {
        color: ${tertiaryGrey7};
        font-size: 16px;
      }
      .location {
        color: ${secondaryDark4};
        font-size: 14px;
      }
    }
    .call-btn {
      background-color: ${primaryColor};
      color: ${whiteColor};
      padding: 6px;
      border-radius: 5px;
      font-family: ${fontFamilyMedium};
      img {
        height: 18px;
      }
      &.chat {
        background-color: ${primaryColor};
      }
    }
    .sold-properties {
      border: 1px solid ${lightColor1};
      border-radius: 5px;
      margin-top: 25px;
      @media ${mediaDeviceMax.mobileL} {
        padding: 8px;
      }
      button {
        background-color: ${greenishColor};
        color: ${whiteColor};
        padding: 6px;
        border-radius: 5px;
        font-family: ${fontFamilyMedium};
        &.chat {
        }
      }

      .properties {
        background: ${tertiaryGrey9};
        padding: 4px;
        border-radius: 4px;
      }
      .title {
        color: ${tertiaryGrey7};
        font-size: 14px;
        margin-bottom: 4px;
        margin-left: 10px;
      }
      .property-icon {
        vertical-align: baseline;
        width: 20px;
        height: 20px;
      }
      .chat-btn {
        background: ${greenishColor};
        border-radius: 5px;
        font-size: 16px;
        padding: 6px;
        color: ${whiteColor};
      }
      .call-btn {
        background: ${primaryColor};
        border-radius: 5px;
        font-size: 16px;
        padding: 6px;
        color: ${whiteColor};
      }
      button {
        width: 100%;
      }
    }
  }
  .about-property-title {
    font-size: 16px;
    margin-top: 22px;
    font-family: ${fontFamilyMedium};
  }
  .about-property {
    max-width: 850px;
    border: 1px solid ${lightGrey5};
    border-radius: 5px;
    padding: 16px;
    background-color: white;
    &-main-details {
      .posted-at {
        &-text {
          font-size: 16px;
          color: ${tertiaryGrey7};
          font-family: ${fontFamilyMedium};
        }
        &-date {
          font-size: 16px;
          color: ${tertiaryGrey7};
        }
        &-area {
          font-size: 16px;
          color: ${tertiaryGrey7};
          font-family: ${fontFamilyMedium};
        }
        &-detail-text {
          color: ${pureDark};
          font-size: 14px;
        }
      }
    }
    .key-features {
      border-bottom: 1px solid ${lightGrey4};
      padding-bottom: 12px;
      &-title {
        color: ${tertiaryGrey7};
        font-family: ${fontFamilyMedium};
      }
      .features {
        .details {
          &-text {
            color: ${lightGrey6};
            font-size: 16px;
            background-color: red;
          }
        }
      }
    }

    .features-container {
      &:not(:last-child) {
        border-bottom: 1px solid ${lightGrey5};
      }

      &-title {
        color: ${tertiaryGrey7};
        font-size: 19px;
        margin-top: 12px;
      }

      .feature {
        margin-top: 14px;
        &-heading {
          font-size: 14px;
        }
        &-text {
          color: ${tertiaryGrey7};
          font-size: 14px !important;
        }
      }
    }
    .rooms-container {
      &-heading {
        font-size: 16px;
        font-family: ${fontFamilyMedium};
      }
      &-subheading {
        font-size: 17px;
        font-family: ${fontFamilyMedium};
      }
    }
  }

  .location-map {
    height: 500px;
    background: lightpink;
    margin-top: 32px;
  }
`;

export default PropertyDetailsStyle;

export const DetailBoxStyle = styled.div`
  img {
    height: 19px;
  }
  .property-box {
    &-inner {
      padding: 10px;
      border-right: 1px solid ${tertiaryGrey5};
      &:not(:last-child) {
        border-right: 1px solid ${tertiaryGrey5};
      }

      .name {
        color: ${tertiaryGrey7};
        font-size: 14px;
      }
      .quantity {
        font-family: ${fontFamilyMedium};
        font-size: 16px;
      }
    }
  }
`;

// phone modal style

export const PhoneModalStyle = styled.div`
  display: flex;
  justify-content: center;
  h6 {
    font-family: ${fontFamilyMedium};
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }
  .inner-container {
    width: 380px;
    border-radius: 7px;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    @media ${mediaDeviceMax.mobileL} {
      width: 310px;
    }
  }
  .dismiss-btn {
    border-top: 1px solid ${lightGrey2};
  }
`;
