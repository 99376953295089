import Wrapper from "./style";
import { useNewsfeedContext } from "../../context/NewsfeedContext";
import React, { useState } from "react";
import { primaryColor, primaryRed } from "../GlobalStyle";
import { useNavigate } from "react-router-dom";
import { authorizationToken, delete_story_url } from "../../utils/api_urls";
import axios from "axios";
import { useAppSelector } from "../../app/hooks";
import { useGlobalContext } from "../../context/context";
import { toast } from "react-toastify";

const MyStoryOptions = () => {
  const { setShowStoryOptions, selectedStories, setIsShowStories } =
    useNewsfeedContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const loginData = useAppSelector((state) => state.loginData.data);
  const navigate = useNavigate();
  const { setCounter, slideIndex } = useGlobalContext();

  // delete story promise
  const deleteStoryPromise = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.delete(delete_story_url, {
        headers: {
          ...authorizationToken(loginData!),
        },
        data: {
          storyId: selectedStories.storyDTOList[slideIndex].storyId,
          newsFeedId: selectedStories.storyDTOList[slideIndex].newsFeedId,
        },
      });
      setIsLoading(false);
      setIsModalVisible(true);
      setShowStoryOptions(false);
      setIsShowStories(false);
      setCounter((prev) => prev + 1);
      navigate("/explore-nearby");
      // setTimeout(() => {
      //   setIsModalVisible(false);
      // }, 2000);
      toast(data.responseMessage, {
        type: "success",
      });
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
      toast(error.response.data.responseMessage, {
        type: "error",
      });
    }
  };

  const StoryOptions = [
    // {
    //   title: "Turn comment off for this Story",
    //   doTask: () => {
    //     setShowStoryOptions(false);
    //   },
    // },
    {
      title: "Edit Story Privacy",
      doTask: () => {
        navigate("/story-privacy");
        setShowStoryOptions(false);
      },
    },
  ];
  return (
    <Wrapper>
      <ul>
        <li
          className="title"
          style={{ color: primaryColor, fontWeight: "500" }}
          onClick={isLoading ? () => {} : deleteStoryPromise}
        >
          {isLoading ? "Deleting Story..." : " Delete Story"}
        </li>
        {StoryOptions.map(({ title, doTask }, index) => {
          return (
            <React.Fragment key={index}>
              <li onClick={doTask}>{title}</li>
            </React.Fragment>
          );
        })}
      </ul>
    </Wrapper>
  );
};

export default MyStoryOptions;
