import React, { useState, useContext } from "react";
import { professionalPlanTypes } from "../redux/features/types";
import { StoriesRecord } from "../screens/ExploreNearby/types";
import { professionType } from "./ProfessionalContext";
import { contextApiDataTypes, tourGuide } from "./types";
import { useAppSelector } from "../app/hooks";

const AppContext = React.createContext({} as contextApiDataTypes);

type appProviderProps = {
  children: React.ReactNode;
};
const AppProvider: React.FC<appProviderProps> = ({ children }) => {
  // trash data
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [isShowProfessions, setIsShowProfessions] = useState(false);
  const { data } = useAppSelector((state) => state.loginData);

  // random context
  const [typeSelect, setTypeSelect] = useState<null | string>("All");
  const [isShowProperty, setIsShowProperty] = useState(false);
  const [showAuthSidebar, setShowAuthSidebar] = useState(false);
  const [showGuidelineSidebar, setShowGuidelineSidebar] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const [changeNavColor, setChangeNavColor] = useState(false);
  const [homeUnAuthAddProperty, setHomeUnAuthAddProperty] = useState(false);
  const [selectedTourGuide, setSelectedTourGuide] = useState(0);
  // professional context
  const [professionalPlan, setProfessionalPlan] = useState(
    {} as professionalPlanTypes
  );
  const [isProfessional, setIsProfessional] = useState(false);
  const [professionType, setProfessionType] = useState({} as professionType);

  // auth context
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [resetPasswordToken, setResetPasswordToken] = useState("");
  // user context
  const [userPhoneNumber, setUserPhoneNumber] = useState<string | number>("");
  const [totalUserStoriesLength, setTotalUserStoriesLength] = useState<
    number | string
  >("");
  // story context
  const [slideIndex, setSlideIndex] = useState(0);
  const [userStoriesData, setUserStoriesData] = useState({} as StoriesRecord);

  // my listing property options
  const [selectedPropertyId, setSelectedPropertyId] = useState<number | string>(
    ""
  );

  const [showPropertyOptions, setShowPropertyOptions] = useState(false);
  const [counter, setCounter] = useState(0);

  // tour guide data
  const [userTourGuideData, setUserTourGuideData] = useState([] as tourGuide[]);
  const [professionalTourGuideData, setProfessionalTourGuideData] = useState(
    [] as tourGuide[]
  );

  // configuration
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <AppContext.Provider
      value={{
        showSidebar,
        setShowSidebar,
        showAuthSidebar,
        setShowAuthSidebar,
        showGuidelineSidebar,
        setShowGuidelineSidebar,
        changeNavColor,
        setChangeNavColor,
        scrollToTop,
        typeSelect,
        setTypeSelect,
        isShowProperty,
        setIsShowProperty,
        isLoggedIn,
        setIsLoggedIn,
        isProfessional,
        setIsProfessional,
        isUser,
        setIsUser,
        userPhoneNumber,
        setUserPhoneNumber,
        resetPasswordToken,
        setResetPasswordToken,
        professionalPlan,
        setProfessionalPlan,
        professionType,
        setProfessionType,
        isShowMenu,
        setIsShowMenu,
        isShowProfessions,
        setIsShowProfessions,
        userStoriesData,
        setUserStoriesData,
        totalUserStoriesLength,
        setTotalUserStoriesLength,
        slideIndex,
        setSlideIndex,
        selectedPropertyId,
        setSelectedPropertyId,
        showPropertyOptions,
        setShowPropertyOptions,
        counter,
        setCounter,
        homeUnAuthAddProperty,
        setHomeUnAuthAddProperty,
        selectedTourGuide,
        setSelectedTourGuide,
        userTourGuideData,
        setUserTourGuideData,
        professionalTourGuideData,
        setProfessionalTourGuideData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
// make sure use
export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
