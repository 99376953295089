import { Row, Col } from "react-bootstrap";
import { useFormikContext } from "formik";
import { AdditionalDetailGalleryStyle } from "./style";
import closeIcon from "../../assets/icons/ic_add_property_delete_photo.svg";
import addIcon from "../../assets/icons/ic_add_property_add_photo.svg";
import Headbar from "../../components/Headbar/Headbar";
import { TertiraryHeading } from "../../components/GlobalStyle";
import { generatePropertyInitTypes } from "./types";

const Gallery = () => {
  const { values, setFieldValue, errors } =
    useFormikContext<generatePropertyInitTypes>();

  // handle images handler
  // function handleChange(e: any) {
  //   setFieldValue("images", Array.from(e.target.files));
  // }
  function handleChange(e: any) {
    setFieldValue("images", [...values.images, ...Array.from(e.target.files)]);
  }

  // remove image handler
  const galleryImagesHanlder = (index: number) => {
    const data = [...values.images];
    data.splice(index, 1);
    setFieldValue("images", data);
  };
  console.log(
    Object.values(errors).map((error) => error),
    "error in create property"
  );
  return (
    <AdditionalDetailGalleryStyle>
      <div>
        <Headbar title="Property Images" />
        <Row className="gallery">
          {values.images.map((img: any, index: number) => (
            <Col key={index} sm={6} md={4} className="picture">
              <img
                className="lg-img w-100"
                src={URL.createObjectURL(img)}
                alt="property"
              />
              <img
                onClick={() => galleryImagesHanlder(index)}
                src={closeIcon}
                className="close-icon cursor-pointer"
                alt="close"
              />
            </Col>
          ))}
          <div
            className="add-media-container"
            style={{
              width: values.images.length !== 0 ? "33%" : "100%",
            }}
          >
            <Col
              sm={6}
              md={4}
              className="add-img d-flex flex-column align-items-center justify-content-center cursor-pointer"
            >
              <div className="upload-btn-wrapper">
                <button className="btn">
                  <span>
                    <img
                      className="lg-img w-100"
                      src={addIcon}
                      alt="property"
                    />
                  </span>
                  <p className="mt-2 mb-0">Add More</p>
                  <p className="size-text">(max size: 5mb)</p>
                </button>
                <input
                  type="file"
                  name="myfile"
                  accept="image/*"
                  multiple
                  onChange={handleChange}
                />
              </div>
            </Col>
          </div>
        </Row>
        {errors.images && errors.images?.length > 1 && (
          <p className="text-danger ms-3">Minimum 3 Images are required !</p>
        )}
        {/* <TertiraryHeading className="mt-3 ms-3">
          Property Video (Optional)
        </TertiraryHeading>
        <div className="video-box mx-2">
          {values.video ? (
            <div className="w-100">
              <img
                onClick={() => setFieldValue("video", null)}
                src={closeIcon}
                className="close-icon cursor-pointer"
                alt="close"
              />
              <video
                src={URL.createObjectURL(values.video)}
                className="media-post-item rounded "
              />
            </div>
          ) : (
            <div className="upload-btn-wrapper">
              <div className="upload-btn position-absolute text-center">
                <span>
                  <img className="lg-img w-100" src={addIcon} alt="property" />
                </span>
                <p className="mt-2 mb-0">Add Video</p>
                <p className="size-text">(max size: 20mb)</p>
              </div>
              <div className="upload-btn-file position-absolute">
                <input
                  type="file"
                  name="video"
                  accept="video/*"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    let files = event.target.files;
                    if (files) {
                      setFieldValue("video", files[0]);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div> */}
      </div>
    </AdditionalDetailGalleryStyle>
  );
};

export default Gallery;
