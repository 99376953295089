import { Card, Col, Row } from "react-bootstrap";
import { PostsStyle } from "./style";
import { PostList2 } from "../../User/types";
import { media_base_url } from "../../../utils/api_urls";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import SinglePost from "../../User/SinglePost";
import { objectNotEmpty, truncateString } from "../../../utils/utilities";

type PostsProps = {
  posts: PostList2[];
  isOpenModal?: boolean;
  setIsLoginModal?: Dispatch<SetStateAction<boolean>>;
};

const Posts: React.FC<PostsProps> = ({
  posts,
  isOpenModal = true,
  setIsLoginModal,
}) => {
  const [isShowPost, setIsShowPost] = useState(false);
  const [singlePostData, setSinglePostData] = useState({} as PostList2);

  console.log({ isOpenModal });
  useEffect(() => {
    setSinglePostData(singlePostData);
  }, [isShowPost]);

  const showStoryModal = (postData: PostList2) => {
    if (isOpenModal) {
      setSinglePostData(postData);
      setIsShowPost(true);
    } else {
      setIsLoginModal!(true);
    }
  };

  console.log("post page",singlePostData);
  return (
    <PostsStyle>
      {objectNotEmpty(singlePostData) && (
        <SinglePost
          postData={singlePostData}
          isShowPost={isShowPost}
          setIsShowPost={setIsShowPost}
        />
      )}
      <Row>
        {posts.map((postData, index) => {
          const { imagesURLS, postId, description, videosURLS } = postData;
          return (
            <Col
              key={index}
              sm={6}
              md={4}
              lg={3}
              onClick={() => {
                showStoryModal(postData);
              }}
            >
              <Card>
                {imagesURLS.length > 0 ? (
                  <Card.Img
                    variant="top"
                    src={media_base_url + imagesURLS[0]}
                    className="position-relative"
                    alt={`img+${postId}`}
                  />
                ) : (
                  <video src={media_base_url + videosURLS} />
                )}

                {description && (
                  <Card.Body className="position-absolute bottom-0">
                    <Card.Text>{truncateString(description, 110)}</Card.Text>
                  </Card.Body>
                )}
              </Card>
            </Col>
          );
        })}
      </Row>
    </PostsStyle>
  );
};

export default Posts;
