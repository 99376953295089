import HeroSection from "./HeroSection";
import ExploreProperty from "./ExploreProperty";
import FeatureProperties from "./FeatureProperties";
import { Container } from "react-bootstrap";
import WhyChooseUs from "./WhyChooseUs";
import FindProperties from "./FindProperties";
import WhoWeAre from "./WhoWeAre";
import Testomonials from "./Testomonials";
import MembershipPlans from "./MembershipPlans";
import { Helmet } from "react-helmet";
import NoDataAvailable from "../../components/NoData/NoDataAvailable";
import Properties from "./Properties";
import { HomeStyle } from "./style";
import HeadingViewMore from "../../components/HeadingViewMore/HeadingViewMore";
import { useNavigate } from "react-router-dom";
import { useNavbarContext } from "../../context/NavbarContext";
import FooterHome from "../../components/Footer/FooterHome";
import Modal from "antd/lib/modal/Modal";
import { useEffect, useState } from "react";
import { TertiaryHeading } from "../../components/GlobalStyle";

const Home = () => {
  const { homeData, error, loading, triggerRefetch } = useNavbarContext();
  const [isModalVisibled, setIsModalVisibled] = useState(true);
  const navigate = useNavigate();

  if (error) {
    return <NoDataAvailable title={error} />;
  }
  const propertyData = loading
    ? {
        currentPage: 0,
        properties: [],
        totalItems: 0,
        totalPages: 0,
      }
    : {
        currentPage: homeData?.propertiesList?.currentPage,
        properties: homeData?.propertiesList?.propertyList?.slice(0, 8) || [],
        totalItems: homeData?.propertiesList?.totalItems,
        totalPages: homeData?.propertiesList?.totalItems,
      };

  const handleModalCancel = () => {
    setIsModalVisibled(false);
  };

  return (
    <HomeStyle>
      <Modal
        open={isModalVisibled}
        footer={null}
        onCancel={handleModalCancel}
        centered={true}
      >
        <div
          style={{
            background: "white",
            padding: "30px",
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          <TertiaryHeading> Welcome to HouseUp</TertiaryHeading>
          Save money and take control with Canada’s first social real estate platform. List your home directly, connect with industry professionals, and cut out unnecessary fees. Discover how HouseUp makes buying, selling, and renting smarter and more affordable.

“Own the way you sell your home”
        </div>
      </Modal>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <HeroSection />
      <Container>
        <ExploreProperty />
      </Container>

      <WhyChooseUs />
      <Container className="properties-section">
        {propertyData.properties.length > 0 && (
          <>
            <FeatureProperties />
            <Properties
              {...propertyData}
              loadMore={false}
              propertiesLoading={loading}
            />
            <div className="load-more d-flex justify-content-start mt-3">
              <HeadingViewMore
                title=""
                bottomLines={false}
                listing="VIEW MORE PROPERTIES"
                viewMoreTask={() => navigate("/find-home")}
              />
            </div>
          </>
        )}
      </Container>
      <FindProperties />
      {/* <Container>
          <div className="load-more d-flex justify-content-start mt-3">
            <HeadingViewMore
              title=""
              bottomLines={false}
              listing="View More Cities"
              viewMoreTask={() => navigate("/find-home")}
            />
          </div>
        </Container> */}
      {/* <Professionals professionals={homeData.professionalList} /> */}
      <WhoWeAre />
      {/* <Testomonials /> */}
      <MembershipPlans />
      <FooterHome />
    </HomeStyle>
  );
};

export default Home;
