import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

const ProfessionalInfoSkeleton = () => {
  return (
    <Wrapper>
      <Row>
        {Array.from({ length: 8 }).map((_, index) => (
          <Col sm={6} key={index} className="property">
            <div className="inner-container">
              <Skeleton width="86%" className="mt-2" />
              <Skeleton width="60%" />
              <Skeleton height={6} width="60%" />
            </div>
          </Col>
        ))}
      </Row>
    </Wrapper>
  );
};

export default ProfessionalInfoSkeleton;

const Wrapper = styled.div`
  .property {
    .inner-container {
      padding: 4px 10px;
      border-radius: 7px;
    }
  }
`;
